import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RolesListPage } from './list';

export const RoleComponent = () => {
  return (
    <Routes>
      <Route path="/*" element={<RolesListPage />} />
    </Routes>
  );
};
