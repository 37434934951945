import React, { useState } from 'react';
import styles from './header.module.css';

const DataTableHeader = (props) => {
  const { actions, data, onChange, sort_callback, is_bulk_mode } = props;

  const [sort_field, setSortField] = useState('');
  const [sort_direction, setSortDirection] = useState(false);

  const handleBulkSelect = () => {
    if (!actions) return;
    onChange();
  };

  const handleSorting = (field) => {
    if (sort_field === field) {
      setSortDirection(!sort_direction);
    } else {
      setSortField(field);
      setSortDirection(true);
    }

    sort_callback(field, sort_direction);
  };

  const checkboxClasses = `${styles.gmCheckbox} ${
    is_bulk_mode ? styles.checkboxOn : !actions ? styles.disabledCheckbox : ''
  }`;

  return (
    <tr>
      <th key="bulk-check">
        <div className={checkboxClasses} onClick={handleBulkSelect}></div>
      </th>

      {data.map((field, index) => (
        <th key={`header-${field.key}-${index}`} onClick={(e) => handleSorting(field.key)}>
          {field.title}
        </th>
      ))}

      <th key="bulk-action">
        <span>Action</span>
      </th>
    </tr>
  );
};

export default DataTableHeader;
