import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useAutomationConfigService } from 'hooks/automation/config';
import { useContactFileService } from 'hooks/file-config';
import React, { useEffect, useState } from 'react';

const default_options = [
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'domain', label: 'Email domain' }
];

const fields_to_exclude = ['email', 'tenant_id', 'user_id', 'id', 'tags'];

export const ChartCreationForm = ({ closeModal = () => {}, onSave = () => {} }) => {
  const { fetchMapping } = useContactFileService();
  const { fetchAutomationConfigs, updateAutomationConfig } = useAutomationConfigService();

  const [config, setConfig] = useState({});
  const [custom_fields, setCustomFields] = useState([]);
  const [is_saving, setIsSaving] = useState(false);
  const [selected_field, setSelectedField] = useState({});

  const submit = async () => {
    if (!selected_field.value) return;
    setIsSaving(true);
    const chart_map = [...(config?.charts || []), selected_field.value].reduce(
      (sac, field) => ({ ...sac, [field]: field }),
      {}
    );

    const data = {
      options: {},
      data: { charts: Object.keys(chart_map) }
    };

    const { config: payload } = await updateAutomationConfig({ data });
    setIsSaving(false);

    if (!payload) return;
    setConfig(payload);
    setSelectedField({});
    onSave(payload.charts || []);
    closeModal();
  };

  useEffect(() => {
    fetchMapping({ query_string: 'return_only=custom_fields' }).then(({ mapping }) => {
      const fields = (mapping.custom_fields || []).reduce((sac, field) => {
        if (fields_to_exclude.includes(field)) return sac;
        return [...sac, { value: field, label: `${field} - custom` }];
      }, []);
      setCustomFields([...default_options, ...fields]);
    });

    fetchAutomationConfigs().then(({ configs: [config] }) => {
      setConfig(config || {});
    });
  }, [closeModal]);

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Select property">
            <MultiSelect
              options={custom_fields}
              value={selected_field}
              onChange={setSelectedField}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Button text="Save" loading={is_saving} onClick={submit} />
        </GridColumn>
      </GridRow>
    </>
  );
};
