import cx from 'classnames';
import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { Checkbox } from 'app/shared/input/checkbox';
import { capitalizeText } from 'app/shared/utils/general';
import styles from './permissions.module.css';
import React from 'react';

const possible_actions = ['create', 'view', 'update', 'delete'];

export const PermissionsConfiguration = ({
  name = 'Default',
  data = [],
  selections = {},
  onConfigChange = () => {}
}) => {
  return (
    <>
      <BasicInfoSection title={`${capitalizeText(name)} permissions`}>
        <GridRow num_of_columns={2}>
          <GridColumn />
          <GridColumn>
            <GridRow>
              <GridColumn>
                <span className={styles.actionHeader}>Create</span>
              </GridColumn>
              <GridColumn>
                <span className={styles.actionHeader}>Read</span>
              </GridColumn>
              <GridColumn>
                <span className={styles.actionHeader}>Update</span>
              </GridColumn>
              <GridColumn>
                <span className={styles.actionHeader}>Delete</span>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <Spacer multiple={3} />
        {data.map((permission) => (
          <div key={permission.code} className={styles.permissionItem}>
            <GridRow num_of_columns={2}>
              <GridColumn>{permission.name}</GridColumn>
              <GridColumn>
                <GridRow>
                  {possible_actions.map((action) => (
                    <GridColumn key={`${permission.code}:${action}`}>
                      <Checkbox
                        checkboxClass={cx(!permission.actions.includes(action) && styles.disabled)}
                        checked={
                          selections[permission.code] &&
                          selections[permission.code][action] &&
                          selections[permission.code][action].is_permitted
                        }
                        onClick={() => {
                          if (!permission.actions.includes(action)) return;
                          onConfigChange(permission, action);
                        }}
                      />
                    </GridColumn>
                  ))}
                </GridRow>
              </GridColumn>
            </GridRow>
          </div>
        ))}
      </BasicInfoSection>
      <Spacer multiple={4} />
    </>
  );
};
