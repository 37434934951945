import React from 'react';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { Icon } from 'assets/icons';

export const ContactBasicInformation = ({ contact_data, onEditClick = () => {} }) => {
  const date_of_birth = contact_data.date_of_birth
    ? new Date(contact_data.date_of_birth).toDateString()
    : 'N/A';

  return (
    <BasicInfoSection
      title="Basic Details"
      icon={<Icon name="edit" onClick={() => onEditClick(() => 'edit')} />}
    >
      <DetailItem title="First name">{contact_data.firstname || 'N/A'}</DetailItem>
      <DetailItem title="Last name">{contact_data.lastname || 'N/A'}</DetailItem>
      <DetailItem title="Email address">{contact_data.email || 'N/A'}</DetailItem>
      <DetailItem title="Phone number">{contact_data.phone_number || 'N/A'}</DetailItem>
      <DetailItem title="Gender">{contact_data.gender || 'N/A'}</DetailItem>
      <DetailItem title="Date of birth">{date_of_birth}</DetailItem>
      <DetailItem title="Street address">{contact_data?.address?.street || 'N/A'}</DetailItem>
      <DetailItem title="Country">{contact_data?.address?.country || 'N/A'}</DetailItem>
    </BasicInfoSection>
  );
};

export const ContactCustomInformation = ({ data }) => {
  return (
    <BasicInfoSection title="Custom Information" icon={<Icon name="edit" />}>
      {Object.keys(data).map((key) => (
        <DetailItem title={key} key={key}>
          {data[key] || 'N/A'}
        </DetailItem>
      ))}
    </BasicInfoSection>
  );
};
