import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ContactFileService from 'services/contact_field';

export const useContactFileService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createMapping = async (options = {}) => {
    const { error, payload } = await ContactFileService.create({ ...options, token });
    if (error) {
      toast.error('Unable to create mapping at this time.');
      return null;
    }

    toast.success('Mapping created successfully');
    return payload;
  };

  const fetchMapping = async (options = {}) => {
    const { error, payload } = await ContactFileService.read({ ...options, token });
    if (error) {
      return { error: 'Unable to fetch mappings at this time.', mapping: payload };
    }

    return { mapping: payload.data[0], error: null };
  };

  const updateMapping = async (id, options = {}) => {
    const { error, payload } = await ContactFileService.updateById(id, { ...options, token });
    if (error) {
      toast.error('Unable to update mapping at this time.');
      return null;
    }

    toast.success(`Contact Field Mappings Updated.`);
    return payload;
  };

  return {
    createMapping,
    fetchMapping,
    updateMapping
  };
};
