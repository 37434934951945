import { Button } from 'app/shared/button';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import styles from './root.module.css';

export const RootEntryButton = ({ data }) => {
  const { id } = useParams();
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);

  const handleAddEntryPoint = () => {
    setWidgetToCreate(() => 'trigger');
    toggleStepModal({
      template_id: data.template_id,
      parents: [],
      show: true
    });
  };

  return (
    <>
      <Button
        className={styles.addEntryBtn}
        text="Add starting point"
        type="secondary"
        onClick={handleAddEntryPoint}
        disabled={isNaN(id)}
      />
    </>
  );
};

export const RootNodesConnectorButton = () => {
  const { id } = useParams();
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);

  return (
    <div className={styles.addBtn}>
      <Handle position={Position.Top} id={id} type="target" />
      <span className={styles.icon}>
        <Icon
          name="add"
          onClick={() => {
            setWidgetToCreate(() => '');
            toggleStepModal({
              template_id: id,
              parents: [1],
              source: 'root',
              show: true,
              rules: [],
              source_widget: 'trigger'
            });
          }}
        />
      </span>
      <Handle position={Position.Bottom} id={id} type="source" />
    </div>
  );
};
