import { Spacer } from 'app/layouts/generic';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { WebPushModule } from './push/web';
import { WebPushMessageViewPage } from './push/web/view';
import { SMSModule } from './sms';
import { SMSViewPage } from './sms/view';

const nav_items = (permissions = {}) => [
  {
    title: 'SMS',
    path: `/messaging/sms`
  },
  {
    title: 'Web Push',
    path: `/messaging/push/web`
  }
];

const ListPages = () => {
  return (
    <>
      <SlidingNav nav_items={nav_items()} />
      <Spacer multiple={4} />
      <Routes>
        <Route path="/push/web/*" element={<WebPushModule />} />
        <Route path="/*" element={<SMSModule />} />
      </Routes>
    </>
  );
};

export const MessagingModule = () => {
  return (
    <PageContentWrapper>
      <Routes>
        <Route path="/sms/:id" element={<SMSViewPage />} />
        <Route path="/push/web/:id" element={<WebPushMessageViewPage />} />
        <Route path="/*" element={<ListPages />} />
      </Routes>
    </PageContentWrapper>
  );
};
