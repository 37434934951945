import React from 'react';
import cx from 'classnames';
import styles from './input.module.css';

export const Checkbox = ({
  label = '',
  input_id = '',
  checked = false,
  onClick = '',
  checkboxClass,
  labelClass
}) => {
  const defaultCheckboxClass = `${styles.gmCheckbox} ${checked ? styles.checkboxOn : ''} `;

  const handleClick = () => {
    onClick();
  };

  return (
    <div className={styles.checkboxWrapper}>
      <div className={cx(defaultCheckboxClass, checkboxClass)} onClick={handleClick}></div>
      <div className={labelClass} onClick={handleClick}>
        {label}
      </div>
    </div>
  );
};
