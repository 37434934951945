import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { truncateText } from 'app/shared/utils/general';
import { useTenantService } from 'hooks/iam/tenant';
import styles from './list.module.css';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { InvitationForm } from './actions/invite';
import { useMembershipService } from 'hooks/iam/membership';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { MembershipRoleConfig } from './actions/update/role';
import { setPageTitle } from 'store/actions/header';
import { PermissionsContext } from 'contexts/permissions';
import { toast } from 'react-toastify';

export const TeamsListPage = () => {
  const dispatch = useDispatch();
  const { fetchMemberships } = useTenantService();
  const { inviteUser, removeMembership, revokeInvitation } = useMembershipService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { permissions } = useContext(PermissionsContext);

  const [dialog_title, setDialogTitle] = useState('');
  const [is_search_mode, setSearchMode] = useState(false);
  const [is_updating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberships, setMemberships] = useState({});
  const [membership_to_update, setMembershipToUpdate] = useState({});
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [show_invitation_form, setShowInvitationForm] = useState(false);
  const [show_role_updation_form, setShowRoleUpdationForm] = useState(false);
  const [total_memberships, setTotalMemberships] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Users', path: '/users/invitations' },
        { title: 'Team', path: '/users/team' }
      ])
    );
  }, []);

  const config = {
    actions: {
      single: (membership) => [
        {
          label: 'Remove',
          value: 'remove',
          hidden: membership.status !== 'active' || !permissions['user_management:update']
        },
        {
          label: 'Revoke',
          value: 'revoke',
          hidden: membership.status !== 'invited' || !permissions['user_management:update']
        },
        {
          label: 'Reinvite',
          value: 'reinvite',
          hidden: membership.status === 'active' || !permissions['user_management:update']
        },
        {
          label: 'Change Role',
          value: 'update_role',
          hidden: !permissions['user_management:update']
        }
      ]
    },
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'user',
        isTitle: true,
        formatter: (value) =>
          value && (value.firstname || value.lastname)
            ? `${value.firstname || ''} ${value.lastname || ''}`
            : 'N/A'
      },
      {
        title: 'Email address',
        key: 'user',
        isTagline: true,
        formatter: (value) => truncateText(value?.email || value?.membership_email, 40) || 'N/A'
      },
      {
        title: 'Role',
        key: 'role',
        isTagline: true,
        formatter: (value) => truncateText(value?.name, 40) || 'N/A'
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          let badge_class = styles.grey;
          switch (value) {
            case 'active':
              badge_class = styles.green;
              break;
            case 'revoked':
              badge_class = styles.orange;
              break;
            case 'removed':
              badge_class = styles.red;
              break;
          }
          return <span className={cx(styles.badge, badge_class)}>{value}</span>;
        }
      },
      {
        title: 'Last Modified',
        key: 'updated_on',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      }
    ],
    is_search_mode,
    items: Object.values(memberships).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    let title = '';
    if (type === 'single') {
      switch (name) {
        case 'revoke':
          title = 'Revoke Invitation';
          break;
        case 'remove':
          title = 'Remove Membership';
          break;
        case 'reinvite':
          title = 'Re-invite User';
          break;
        case 'update_role':
          setMembershipToUpdate({ ...data, action: name });
          setShowRoleUpdationForm(() => true);
          return;
      }
      setDialogTitle(title);
      setMembershipToUpdate({ ...data, action: name });
      setShowConfirmation(() => true);
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    const { memberships, size, error } = await fetchMemberships({
      query_string: `page=${page}&population=${population}`
    });
    if (error) return toast.error(error);

    setMemberships((curr_memberships) => ({
      ...curr_memberships,
      ...memberships.reduce((s, membership) => ({ ...s, [membership.id]: membership }), {})
    }));
    setTotalMemberships(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  const manageMembership = async (permitted) => {
    if (permitted) {
      const { action, user_email, tenant_id, role_id } = membership_to_update;
      const data = { email: user_email, role_id, tenant_id };
      setIsUpdating(() => true);
      switch (action) {
        case 'revoke':
          await revokeInvitation({ data });
          break;
        case 'remove':
          await removeMembership({ data });
          break;
        case 'reinvite':
          await inviteUser({ data });
          break;
      }

      setIsUpdating(() => false);
      handleDataRequest(0, 100);
    }

    setMembershipToUpdate(() => ({}));
    setShowConfirmation(() => false);
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="User" onClick={() => setShowInvitationForm(() => true)} />
    </>
  );

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            action={handleDatatableAction}
            config={config}
            table_actions={table_actions}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            action={handleDatatableAction}
            config={{
              ...config,
              total_count: total_memberships
            }}
            checkbox
            table_actions={table_actions}
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
      <GmModal
        show_modal={show_invitation_form}
        onClose={() => setShowInvitationForm(() => false)}
        title="Invite user"
        show_title
      >
        <InvitationForm
          onSave={() => handleDataRequest(0, 100)}
          onClose={() => setShowInvitationForm(false)}
        />
      </GmModal>
      <GmModal
        show_modal={show_role_updation_form}
        onClose={() => setShowRoleUpdationForm(() => false)}
        title="Update member role"
        show_title
      >
        <MembershipRoleConfig
          onSave={() => handleDataRequest(0, 100)}
          membership_data={membership_to_update}
        />
      </GmModal>

      <ConfirmationDialog
        title={dialog_title}
        message={`Are you sure you want to ${dialog_title.toLowerCase()}?`}
        callback={manageMembership}
        is_open={show_confirmation}
        is_loading={is_updating}
      />
    </GridRow>
  );
};
