import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as CampaignService from 'services/campaign';
import { useNavigate } from 'react-router-dom';
import { urls } from 'services/_url';
import { processDuplicateName } from 'app/shared/utils/mailing';

export const useCampaignService = () => {
  const navigate = useNavigate();
  const { token } = useSelector((store) => store.user_data);

  const creationOptions = [
    { icon_name: 'note', text: 'Plain text', onClick: () => navigate('/editor/campaign') },
    {
      icon_name: 'colors',
      text: 'Template builder',
      onClick: () => (window.location.href = `${urls.editor}/campaigns?t=${token}`)
    }
  ];

  const createCampaign = async (options = {}) => {
    const { error, payload } = await CampaignService.create({ ...options, token });
    if (error) {
      return null;
    }

    return payload;
  };

  const fetchCampaign = async (id, options = {}) => {
    const { error, payload } = await CampaignService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch campaign at this time.');
      return { campaign: {} };
    }

    return { campaign: payload };
  };

  const fetchCampaigns = async (options = {}) => {
    const { error, payload } = await CampaignService.read({ ...options, token });
    if (error) {
      return { campaigns: null, error, size: 0 };
    }

    const {
      data: campaigns,
      meta: { size }
    } = payload;
    return { campaigns, size, error: null };
  };

  const prepareTestCampaignData = (campaign) => {
    if (!campaign) return;

    const data = {
      ...campaign,
      name: processDuplicateName(campaign.name, 'test'),
      status: 'queued',
      is_test_campaign: true,
      schedule: { date: new Date() }
    };

    delete data.id;
    delete data._id;
    delete data.__v;
    delete data.time_stamp;
    delete data.is_active;
    delete data.is_deleted;
    delete data.is_enqueued;
    delete data.created_on;
    delete data.updated_on;
    delete data.failed_sends_list;
    delete data.metadata;

    return { data };
  };

  const resendCampaign = async (campaign, options = {}) => {
    if (!campaign || !campaign.id) return toast.error('No Campaign passed.');
    if (campaign.status !== 'processed') {
      return toast.error('Only processed campaigns can be resent.');
    }

    toast.info('Resending Campaign...');
    const { error } = await CampaignService.updateById(campaign.id, {
      ...options,
      token
    });

    if (error) {
      return toast.error(error);
    }

    toast.success('Campaign requeued successfully');
    return { ...campaign, status: 'queued' };
  };

  const searchCampaigns = async (keys, keyword, options = {}) => {
    const { error, payload } = await CampaignService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch campaigns at this time.');
      return { campaigns: [], size: 0 };
    }

    const {
      data: campaigns,
      meta: { size }
    } = payload;
    return { campaigns, size };
  };

  const updateCampaign = async (id, options = {}) => {
    const { error, payload } = await CampaignService.updateById(id, { ...options, token });
    if (error) {
      toast.error('Unable to update campaign at this time.');
      return null;
    }

    toast.success('Campaign cancelled successfully.');
    return payload;
  };

  return {
    creationOptions,
    createCampaign,
    fetchCampaign,
    fetchCampaigns,
    prepareTestCampaignData,
    resendCampaign,
    searchCampaigns,
    updateCampaign
  };
};
