/** */
import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CreateCampaign } from './campaign';
import { CreateTransactional } from './transactionals';
import styles from './editor.module.css';
import CreateAutomation from './automation/create';
import { PermissionsContext } from 'contexts/permissions';

export const EditorLayout = () => {
  const { permissions } = useContext(PermissionsContext);
  return (
    <section className={styles.layoutWrapper}>
      <Routes>
        {permissions['automation:view'] && (
          <Route path="automations/:id" element={<CreateAutomation />} />
        )}
        {permissions['transactional:create'] && (
          <>
            <Route path="transactional/:id" element={<CreateTransactional />} />
            <Route path="transactional" element={<CreateTransactional />} />
          </>
        )}
        {permissions['campaign:create'] && (
          <>
            <Route path="campaign/:id" element={<CreateCampaign />} />
            <Route path="/*" element={<CreateCampaign />} />{' '}
          </>
        )}
      </Routes>
    </section>
  );
};
