import { isEmailValid } from '../../input';

const generalContactValidationConfig = {
  firstname: {
    pattern: /^[a-zA-Z]*$/,
    customErrorMessage: {
      pattern: 'Only letters are allowed'
    }
  },
  lastname: {
    pattern: /^[a-zA-Z]*$/,
    customErrorMessage: {
      pattern: 'Only letters are allowed'
    }
  },
  email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Email Address';
      } else result.is_valid = true;

      return result;
    }
  },
  gender: {
    customValidator: (value) => {
      const result = { is_valid: true, message: '' };
      const allowedGenders = { male: 'm', female: 'f', other: 'o' };
      if (value?.value && !allowedGenders[value.value.toLowerCase()]) {
        result.message = 'Invalid gender';
        result.is_valid = false;
      }
      return result;
    }
  },
  date_of_birth: {
    customValidator: (value) => {
      const result = { is_valid: true, message: '' };
      const isValidDate = !isNaN(Date.parse(value));
      if (value && !isValidDate) {
        result.message = 'Invalid date';
        result.is_valid = false;
      }
      return result;
    }
  }
};

export const createContactValidationConfig = {
  ...generalContactValidationConfig,
  mailing_lists: {
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: '' };
      if (options.required && (!value || value.length === 0)) {
        result.message = 'Select audience.';
      } else result.is_valid = true;

      return result;
    }
  }
};

export const editContactValidationConfig = {
  ...generalContactValidationConfig,
  subscriptions: {
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: '' };
      if (options.required && (!value || value.length === 0)) {
        result.message = 'Select audience.';
      } else result.is_valid = true;

      return result;
    }
  }
};
