import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { Button } from '../button';

export const OnboardingVideo = ({
  title,
  url,
  show_actions = true,
  closeModal = () => {},
  onSkip = () => {}
}) => {
  return (
    <>
      <iframe
        id="video-frame"
        width="100%"
        height="500px"
        src={url}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullscreen
      ></iframe>
      <Spacer multiple={3} />
      {show_actions && (
        <GridRow num_of_columns={4}>
          <GridColumn span={2} />
          <GridColumn>
            <Button text="Skip" type="secondary" onClick={onSkip} />
          </GridColumn>
          <GridColumn>
            <Button text="Watch Later" onClick={closeModal} />
          </GridColumn>
        </GridRow>
      )}
    </>
  );
};
