/** */
export const READ_MANY_CONTACT_FIELD = 'read multiple Contact Fields.';
export const READ_ONE_CONTACT_FIELD = 'read one Contact Field.';

export const ADD_ONE_CONTACT_FIELD_TO_STORE = 'add one Contact Field to store.';
export const ADD_MANY_CONTACT_FIELD_TO_STORE = 'add multiple Contact Field to atore.';

/** */
export const readManyContactField = (payload) => ({
  type: READ_MANY_CONTACT_FIELD,
  payload
});

export const readOneContactField = (payload) => ({
  type: READ_ONE_CONTACT_FIELD,
  payload
});

export const addManyContactFieldToStore = (payload) => ({
  type: ADD_MANY_CONTACT_FIELD_TO_STORE,
  payload
});

export const addOneContactFieldToStore = (payload) => ({
  type: ADD_ONE_CONTACT_FIELD_TO_STORE,
  payload
});
