import { useSelector } from 'react-redux';
import * as MailingReportService from 'services/mailing/report';

export const useMailingReportService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchReports = async (options = {}) => {
    const { error, payload } = await MailingReportService.read({
      ...options,
      token
    });

    if (error) {
      return { logs: [] };
    }

    return { logs: payload.data };
  };

  const fetchComparativeReport = async (options = {}) => {
    return { data: {}, error: 'Unable to fetch comparative data at this time.' };
  };

  const fetchPerformanceReport = async (options = {}) => {
    return { data: {}, error: 'Unable to fetch performance data at this time.' };
  };

  return {
    fetchReports,
    fetchComparativeReport,
    fetchPerformanceReport
  };
};
