export const RoleValidationSchema = {
  name: {
    required: true,
    pattern: /^[\w\s]+$/,
    customErrorMessage: {
      pattern: 'Invalid name format'
    }
  },
  description: {
    pattern: /^[\w\s\-:.]*$/,
    customErrorMessage: {
      pattern: 'Invalid format'
    }
  }
};
