/** */
export const CREATE_NEURON = 'create neuron';
export const DELETE_NEURON = 'delete neuron';
export const READ_NEURONS = 'read neurons';
export const UPDATE_NEURON = 'update neuron';

/** */
export const ADD_ONE_NEURON_TO_STORE = 'add one neuron to store';
export const ADD_MANY_NEURONS_TO_STORE = 'add many neurons to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add neuron search results to store';
export const LOAD_NEURONS_TO_STORE = 'load neurons to store from API call';
export const REMOVE_MANY_NEURONS_FROM_STORE = 'remove many neurons from the store.';
export const REMOVE_ONE_NEURON_FROM_STORE = 'remove a neuron from the store.';

/** */
export const createNeuron = (payload) => ({
  type: CREATE_NEURON,
  payload
});

export const deleteNeuron = (payload) => ({
  type: DELETE_NEURON,
  payload
});

export const deleteManyNeurons = (payload) => ({
  type: REMOVE_MANY_NEURONS_FROM_STORE,
  payload
});

export const readNeurons = (payload) => ({
  type: READ_NEURONS,
  payload
});

export const updateNeuronInStore = (payload) => ({
  type: UPDATE_NEURON,
  payload
});

/** */

export const addManyNeuronsToStore = (payload) => ({
  type: ADD_MANY_NEURONS_TO_STORE,
  payload
});

export const addOneNeuronToStore = (payload) => ({
  type: ADD_ONE_NEURON_TO_STORE,
  payload
});

export const addNeuronSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadNeuronsToStore = (payload) => ({
  type: LOAD_NEURONS_TO_STORE,
  payload
});

export const removeManyNeuronsFromStore = (payload) => ({
  type: REMOVE_MANY_NEURONS_FROM_STORE,
  payload
});

export const removeOneNeuronFromStore = (payload) => ({
  type: REMOVE_ONE_NEURON_FROM_STORE,
  payload
});
