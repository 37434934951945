import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { Spacer } from 'app/layouts/generic';
import { RoleComponent } from './roles';
import { Route, Routes } from 'react-router-dom';
import { TeamsModule } from './teams';
import { InvitationsModule } from './invitations';
import { PermissionsContext } from 'contexts/permissions';

export const UsersModuleDisplay = () => {
  const dispatch = useDispatch();
  const { permissions } = useContext(PermissionsContext);

  const NAVITEMS = {
    invitations: {
      index: 1,
      title: 'Invitations',
      path: '/users/invitations'
    },
    teams: {
      index: 4,
      title: 'Team',
      path: '/users/team',
      hidden: !permissions['user_management:view']
    },
    roles: {
      index: 5,
      title: 'Roles',
      path: '/users/roles',
      hidden: !permissions['user_management:view']
    }
  };

  const [nav_items] = useState(NAVITEMS);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Users', path: '.' }]));
  }, [dispatch]);

  return (
    <>
      <header>
        <SlidingNav nav_items={Object.values(nav_items).sort((a, b) => a.index - b.index)} />
      </header>
      <Spacer multiple={4} />
      <Routes>
        <Route path="invitations/*" element={<InvitationsModule />} />
        <Route path="team/*" element={<TeamsModule />} />
        <Route path="roles/*" element={<RoleComponent />} />
        <Route path="/*" element={<InvitationsModule />} />
      </Routes>
    </>
  );
};
