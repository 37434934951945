import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addManyRolesToStore, addRoleSearchResultsToStore } from 'store/actions/role';
import { setPageTitle } from 'store/actions/header';
import { useRoleService } from 'hooks/iam/role';
import { truncateText } from 'app/shared/utils/general';
import { toReadableNumber } from 'app/shared/utils/number';
import { Button } from 'app/shared/button';
import { PermissionsContext } from 'contexts/permissions';
import { toast } from 'react-toastify';

export const RolesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchRoles, searchRoles } = useRoleService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const roles_in_store = useSelector((state) => state.roles);
  const { permissions } = useContext(PermissionsContext);

  const [roles, setRoles] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_roles, setTotalRoles] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Users', path: '/users/invitations' },
        { title: 'Roles', path: '/users/roles' }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRoles(Object.values(roles_in_store));
  }, [roles_in_store]);

  const config = {
    actions: {
      single: (role) => [
        {
          value: 'edit',
          label: 'Edit',
          hidden: role.type === 'system' || !permissions['user_management:update']
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        searchable: true,
        formatter: (value) => truncateText(value, 40)
      },
      {
        title: 'Description',
        key: 'description',
        isTagline: true,
        searchable: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Permissions',
        key: 'permissions',
        searchable: false,
        formatter: (value) => {
          let result = 0;
          for (const permission of value) {
            const { actions } = permission;
            for (const action of actions) {
              if (action.is_permitted) {
                result += 1;
                break;
              }
            }
          }

          if (Number(result) === 1 && value[0].resource === '*') result = '*';
          return toReadableNumber(result) || '0';
        }
      },
      {
        title: 'Date created',
        key: 'created_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: roles.sort((a, b) => b.id - a.id),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          navigate(`/users/roles/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    navigate(`/users/roles/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { roles, size, error } = await fetchRoles({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });
      if (error) return toast.error(error);

      dispatch(addManyRolesToStore(roles));
      setTotalRoles(size);
    } catch (e) {
      dispatch(addManyRolesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { roles, size } = await searchRoles(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });
      setTotalRoles(size);
      if (page === 0) return dispatch(addRoleSearchResultsToStore(roles));
      dispatch(addManyRolesToStore(roles));
    } catch (e) {
      dispatch(addManyRolesToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Role" onClick={() => navigate('/users/roles/new')} />
    </>
  );

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_roles
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
