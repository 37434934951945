import styles from './widget.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { Icon } from 'assets/icons';
import { formatResourceTitle } from './helper';

export const NeuronResponseWidget = ({ data, onEdit = () => {}, onDelete = () => {} }) => {
  return (
    <GridRow num_of_columns={1}>
      <GridColumn>
        <div className={styles.wrapper}>
          <span>{formatResourceTitle(data)}</span>
          <span className={styles.actions}>
            <Icon name="edit" onClick={() => onEdit(data)} />
            <span className={styles.trashIcon}>
              <Icon name="trash" onClick={() => onDelete(data)} />
            </span>
          </span>
        </div>
      </GridColumn>
    </GridRow>
  );
};
