/** */
import React, { useEffect } from 'react';
import cx from 'classnames';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

/** */
import AccountActivation from './activation/activation';
import LoginForm from './login/login';
import PasswordRecovery from './password/recovery';
import PasswordReset from './password/reset';
import SignupForm from './signup/signup';

/** */
import styles from './guest.module.css';
import { ReactComponent as Icon } from 'assets/icons/logo-sm.svg';
import PasswordResetSuccessful from './password/success';

const GuestArea = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params['*']) return;
    const [, path_two, token] = params['*'].split('/');
    if (path_two === 'acceptance') {
      window.localStorage.setItem('acceptance', token);
      navigate('register');
    }
  }, [params]);

  return (
    <div className={styles.wrapper}>
      <div className="gmContainer">
        <div className={cx(styles.header)}>
          <Icon onClick={() => navigate('/')} className={styles.siteIcon} />
          <div
            className={styles.siteName}
            aria-labelledby="Site Name"
            onClick={() => navigate('/')}
          >
            Go-Mailer<span className={styles.stub}>.</span>
          </div>
        </div>
        <div className={styles.formWrapper}>
          <Routes>
            <Route path="login" element={<LoginForm />} />
            <Route path="register" element={<SignupForm />} />
            <Route path="activation/:id" element={<AccountActivation />} />
            <Route path="password/recovery/" element={<PasswordRecovery />} />
            <Route path="password/reset/:id" element={<PasswordReset />} />
            <Route path="password/success" element={<PasswordResetSuccessful />} />
            <Route path="/*" element={<LoginForm />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default GuestArea;
