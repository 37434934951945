import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as CSS3Icon } from 'assets/icons/user-icons/css.svg';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { WebsiteConfiguration } from './form';
import styles from '../item.module.css';
import useScreenSize from 'hooks/size';
import { toast } from 'react-toastify';
import { PermissionsContext } from 'contexts/permissions';

export const WebsiteIntegration = () => {
  const [show_web_form, setShowWebForm] = useState(false);
  const { isMobile, isTablet } = useScreenSize();
  const { permissions } = useContext(PermissionsContext);

  useEffect(() => {
    if (isMobile || isTablet) {
      toast.info('Integrations can only be configured on desktop.');
    }
  }, []);

  const handleIntegration = () => {
    setShowWebForm((prev) => !prev);
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <CSS3Icon />
          <span className={styles.bold}>Website Forms</span>
        </div>
        <div className={styles.cardBody}>
          <p>Seamlessly integrate Go-Mailer into your existing web-forms and landing pages.</p>
          <div className={styles.amountDetails}>
            <div className={styles.amount}>
              <span>$</span> <span>0.00</span>
            </div>
            <div className={styles.duration}>&nbsp;/ forever</div>
          </div>
          <Button
            text="Configure forms"
            className={styles.actionButton}
            onClick={handleIntegration}
            disabled={isMobile || isTablet || !permissions['integration:create']}
          />
        </div>
      </div>
      <GmModal
        bodyClassName={styles.webForm}
        title="Configure Web Form"
        show_title={true}
        show_modal={show_web_form}
        onClose={() => setShowWebForm(false)}
      >
        <WebsiteConfiguration showModal={show_web_form} />
      </GmModal>
    </>
  );
};
