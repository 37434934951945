import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { useMailActionService } from 'hooks/mailaction';
import { useContactService } from 'hooks/contact';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';
import { truncateText } from 'app/shared/utils/general';
import { toast } from 'react-toastify';

export const OpenLogs = () => {
  const { id: transactional_id } = useParams();
  const { fetchMailActions } = useMailActionService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchContacts, searchContact } = useContactService();
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [open_log, setOpenLog] = useState({});
  const [total_opens, setTotalOpens] = useState(0);

  const config = {
    actions: {
      single: () => []
    },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'recipient',
        isTitle: true,
        formatter: (value) => (value ? value.firstname : 'N/A')
      },
      {
        title: 'Last name',
        key: 'recipient',
        isTitle: true,
        formatter: (value) => (value ? value.lastname : 'N/A')
      },
      {
        title: 'Email address',
        key: 'recipient',
        isTagline: true,
        formatter: (value) => truncateText(value?.email, 25) || 'N/A'
      },
      {
        title: 'Gender',
        key: 'recipient',
        formatter: (value) => (value ? value.gender : 'N/A')
      },
      {
        title: 'Time opened',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Number of opens',
        key: 'occurences',
        formatter: (value) => toReadableNumber(value.length) || 'N/A'
      }
    ],
    is_search_mode,
    items: Object.values(open_log).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    const {
      mailactions,
      size,
      error: e
    } = await fetchMailActions({
      query_string: `page=${page}&population=${population}&resource_type=transactional&resource_id=${transactional_id}&action=opened`
    });
    if (e) return toast.error(e);

    const contact_ids = mailactions.map((action) => action.recipient_email);
    const { contacts, error } = await fetchContacts({
      query_string: `email=${contact_ids.join()}`
    });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return;
    }
    const contact_info = contacts.reduce((s, c) => ({ ...s, [c.email]: c }), {});
    const actions = mailactions.map((action) => ({
      ...action,
      recipient: contact_info[action.recipient_email]
    }));

    setOpenLog((curr_action) => ({
      ...curr_action,
      ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
    }));
    setTotalOpens(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    try {
      setLoading(true);
      const { contacts } = await searchContact(keys, keyword, {
        query_string: `page=0&population=${500}&return_only=id,firstname, lastname, email,gender`
      });
      if (contacts.length) {
        const contacts_info = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
        const contact_ids = contacts.map((c) => c.id);
        const { mailactions, size, error } = await fetchMailActions({
          query_string: `page=${page}&population=${population}&resource_type=transactional&recipient_id=${contact_ids.join()}&resource_id=${transactional_id}&action=opened`
        });
        if (error) return toast.error(error);

        const actions = mailactions.map((action) => ({
          ...action,
          recipient: contacts_info[action.recipient_id]
        }));

        if (is_search_mode) {
          setOpenLog((curr_action) => ({
            ...curr_action,
            ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
        } else {
          setOpenLog(() => ({
            ...actions.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
          setSearchMode(true);
        }
        setTotalOpens(() => size);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              total_count: total_opens
            }}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
