/** */
export const READ_MANY_TRANSACTIONALS = 'read multiple TRANSACTIONALs.';
export const READ_ONE_TRANSACTIONAL = 'read one TRANSACTIONAL.';

export const ADD_MANY_TRANSACTIONALS_TO_STORE = 'add multiple TRANSACTIONALs to atore.';
export const ADD_ONE_TRANSACTIONAL_TO_STORE = 'add one TRANSACTIONAL to store.';
export const ADD_SEARCH_RESULTS = 'add search results TRANSACTIONAL to store.';
export const LOAD_TRANSACTIONALS_TO_STORE = 'load up TRANSACTIONALs to store.';
export const REM_MANY_TRANSACTIONALS_FROM_STORE = 'remove multiple TRANSACTIONALS from the store.';

/** */
export const readManyTransactionals = (payload) => ({
  type: READ_MANY_TRANSACTIONALS,
  payload
});

export const readOneTransactional = (payload) => ({
  type: READ_ONE_TRANSACTIONAL,
  payload
});

export const addManyTransactionalsToStore = (payload) => ({
  type: ADD_MANY_TRANSACTIONALS_TO_STORE,
  payload
});

export const addOneTransactionalToStore = (payload) => ({
  type: ADD_ONE_TRANSACTIONAL_TO_STORE,
  payload
});

export const addTransactionalSearchResults = (payload) => ({
  type: ADD_SEARCH_RESULTS,
  payload
});

export const loadTransactionalsToStore = (payload) => ({
  type: LOAD_TRANSACTIONALS_TO_STORE,
  payload
});

export const removeManyTransactionalFromStore = (payload) => ({
  type: READ_MANY_TRANSACTIONALS,
  payload
});
