import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AudienceService from 'services/audience';
import { addOneAudienceToStore, updateAudienceInStore } from 'store/actions/audience';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { TextArea } from 'app/shared/input/textarea';
import { Checkbox } from 'app/shared/input/checkbox';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { audienceValidationConfig } from 'app/shared/utils/form/validation/audience';
import styles from './create.module.css';

const CreateMailingList = ({ closeModal, current_test_audience_id }) => {
  const dispatch = useDispatch();
  const { token, id } = useSelector((state) => state.user_data);
  const { errors, validateField, form_is_valid } = useValidator(audienceValidationConfig);
  const [loading, setLoading] = useState(false);
  const [form_data, setFormData] = useState({});

  const clearForm = () => {
    setFormData({});
  };

  const handleInputChange = (key, value = '') => {
    validateField(key, value);
    setFormData((curr_data) => ({
      ...curr_data,
      [key]: value
    }));
  };

  const submit = async () => {
    const data = {
      ...form_data,
      tenant_id: id
    };

    setLoading(true);
    const { error, payload } = await AudienceService.create({ data, token });
    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success('Audience created.');
    dispatch(addOneAudienceToStore(payload));
    if (
      current_test_audience_id &&
      current_test_audience_id !== payload.id &&
      data.is_test_audience
    ) {
      dispatch(updateAudienceInStore({ is_test_audience: false, id: current_test_audience_id }));
    }
    clearForm();
    closeModal();
  };

  return (
    <div className={styles.wrapper}>
      <DetailItem title="Audience name">
        <SimpleInput
          required
          value={form_data.name}
          onInput={(v) => handleInputChange('name', v)}
          error={errors.name}
        />
      </DetailItem>
      <DetailItem title="Description">
        <TextArea
          className="gm-input"
          value={form_data.description}
          onChange={(e) => e}
          onInput={(v) => handleInputChange('description', v)}
        />
      </DetailItem>
      <Checkbox
        checked={form_data.is_test_audience}
        input_id="is_test_audience"
        label="Use as test audience"
        onClick={() => handleInputChange('is_test_audience', !form_data?.is_test_audience)}
      />

      <Button
        text="Save"
        onClick={submit}
        className="float-right w-50 mt-4 mb-4"
        disabled={!form_is_valid || loading}
        loading={loading}
      />
    </div>
  );
};

export default CreateMailingList;
