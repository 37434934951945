import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { PrimaryStatCard } from 'app/shared/cards';
import { DistributionChart } from 'app/shared/charts/distribution';
import { TimeSeriesChart } from 'app/shared/charts/timeseries';
import { convertToPercent } from 'app/shared/utils/general';
import { useContactService } from 'hooks/contact';
import { useMailActionService } from 'hooks/mailaction';
import { useMailLogService } from 'hooks/maillog';
import { useWalletLogService } from 'hooks/walletlogs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';
import { useMailingReportService } from 'hooks/mailing/report';

export const CampaignOverview = () => {
  const chart_key_filters = [
    {
      label: 'Sends',
      value: 'sends',
      default: true,
      color: 'var(--blue-primary)'
    },
    {
      label: 'Bounces',
      value: 'bounces',
      color: 'var(--orange-primary)'
    },
    {
      label: 'Opens',
      value: 'opens',
      default: true,
      color: 'var(--neutral-dark-5)'
    }
  ];

  const { id } = useParams();
  const { fetchDistributionReports } = useContactService();
  const { fetchMailActions } = useMailActionService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchReports } = useMailingReportService();
  const { fetchWalletLogs } = useWalletLogService();
  const { isMobile } = useScreenSize();

  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);
  const [is_loading_open_distribution, setIsLoadingOpenDistribution] = useState(true);
  const [is_loading_click_distribution, setIsLoadingClickDistribution] = useState(true);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [basic_stats, setBasicStats] = useState({});
  const [open_rate_distribution, setOpenDistribution] = useState([]);
  const [click_rate_distribution, setClickDistribution] = useState([]);

  useEffect(() => {
    fetchWalletLogs({ query_string: `resource_type=campaign&resource_id=${id}` }).then(
      ({ walletlogs, error }) => {
        if (error) toast.error(error);

        const cost = walletlogs.reduce((total, log) => {
          if (log.kind === 'charge') return (total += Number(log.amount));
          return (total -= Number(log.amount));
        }, 0);

        setBasicStats((curr_stats) => ({ ...curr_stats, cost }));
      }
    );

    fetchReports({ query_string: `resource_type=campaign&resource_id=${id}` }).then(({ logs }) => {
      const stats = { bounces: 0, sends: 0, opens: 0, link_clicks: 0, unsubscriptions: 0 };
      for (const log of logs) {
        const { bounced, opened, sent, clicked_link, unsubscribed } = log.stats || {};
        stats.bounces += bounced || 0;
        stats.opens += opened || 0;
        stats.sends += sent || 0;
        stats.link_clicks += clicked_link || 0;
        stats.unsubscriptions += unsubscribed || 0;
      }
      setBasicStats((curr_stats) => ({
        ...curr_stats,
        ...stats
      }));

      fetchMailLogs({
        query_string: `count=1&class=campaign&resource_id=${id}&retry_id=0`
      }).then(({ size: unique_sends, error }) => {
        if (error) return;

        setBasicStats((curr_stats) => ({
          ...curr_stats,
          click_rate: convertToPercent(stats.link_clicks, unique_sends),
          open_rate: convertToPercent(stats.opens, unique_sends),
          bounce_rate: convertToPercent(stats.bounces, unique_sends),
          unsubscription_rate: convertToPercent(stats.unsubscriptions, unique_sends)
        }));
      });
    });
  }, [id]);

  const handleEmailPerformanceDateRangeChange = async (ranges = []) => {
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setEmailPerformanceData(() => day_map);

    setIsLoadingEmailPerformanceData(true);
    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      fetchReports({
        query_string: `day=${start}~${end}&resource_type=campaign&resource_id=${id}`
      }).then(({ logs }) => {
        const stats = { bounces: 0, sends: 0, opens: 0 };
        for (const log of logs) {
          const { bounced, opened, sent } = log.stats || {};
          stats.bounces += bounced || 0;
          stats.opens += opened || 0;
          stats.sends += sent || 0;
        }
        setEmailPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: { ...curr_data[label], ...stats }
        }));
      });
    }
    setIsLoadingEmailPerformanceData(false);
  };

  const handleOpenRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingOpenDistribution(() => true);
    const { mailactions, error } = await fetchMailActions({
      query_string: `resource_type=campaign&action=opened&resource_id=${id}&return_only=recipient_id`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions.map((action) => action.recipient_id);

    if (!contact_ids.length) {
      setIsLoadingOpenDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join() }
    });

    setIsLoadingOpenDistribution(() => false);
    setOpenDistribution(() => distribution);
  };

  const handleClickRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingClickDistribution(() => true);
    const { mailactions, error } = await fetchMailActions({
      query_string: `resource_type=campaign&action=clicked_link&resource_id=${id}&return_only=recipient_id`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions
      .map((action) => action.recipient_id)
      .filter((c_id) => !c_id.toString().includes('@'));

    if (!contact_ids.length) {
      setIsLoadingClickDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join() }
    });

    setIsLoadingClickDistribution(() => false);
    setClickDistribution(() => distribution);
  };

  const statDetails = [
    {
      main_stat: {
        label: 'Delivered',
        color: 'var(--blue-primary)',
        value: basic_stats.sends
      },
      bottom_stat: {
        label: 'Cost (₦)',
        value: basic_stats.cost
      }
    },
    {
      main_stat: {
        label: 'Unique opens',
        color: 'var(--blue-primary)',
        value: basic_stats.opens
      },
      bottom_stat: {
        label: 'Open rate (%)',
        value: basic_stats.open_rate
      }
    },

    {
      main_stat: {
        label: 'Unique link clicks',
        color: 'var(--blue-primary)',
        value: basic_stats.link_clicks
      },
      bottom_stat: {
        label: 'Click rate (%)',
        value: basic_stats.click_rate
      }
    },

    {
      main_stat: {
        label: 'Bounced',
        color: 'var(--danger)',
        value: basic_stats.bounces
      },
      bottom_stat: {
        label: 'Bounce rate (%)',
        value: basic_stats.bounce_rate
      }
    },
    {
      main_stat: {
        label: 'Unsubscriptions',
        color: 'var(--danger)',
        value: basic_stats.unsubscriptions
      },
      bottom_stat: {
        label: 'Unsubscription rate (%)',
        value: basic_stats.unsubscription_rate
      }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />
      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                data={Object.values(email_performance_data)}
                key_filters={chart_key_filters}
                graph_title="Email Performance"
                is_loading_data={is_loading_email_performance_data}
                onDateRangeChange={handleEmailPerformanceDateRangeChange}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}
      <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
        <GridColumn span={2}>
          <DistributionChart
            title="Open rate distribution"
            data={open_rate_distribution}
            is_loading={is_loading_open_distribution}
            onChange={handleOpenRateDistribution}
          />
        </GridColumn>
        <GridColumn span={2}>
          <DistributionChart
            data={click_rate_distribution}
            is_loading={is_loading_click_distribution}
            title="Click rate distribution"
            onChange={handleClickRateDistribution}
          />
        </GridColumn>
      </GridRow>
    </>
  );
};
