import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useContactService } from 'hooks/contact';
import { truncateText } from 'app/shared/utils/general';
import { useMailLogService } from 'hooks/maillog';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { toast } from 'react-toastify';

export const BounceLogs = () => {
  const { id: transactional_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchContacts, searchContact } = useContactService();
  const { fetchMailLogs } = useMailLogService();

  const [bounce_log, setBounceLog] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_bounces, setTotalBounces] = useState(0);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'recipient',
        formatter: (value) => (value ? value.firstname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'recipient',
        formatter: (value) => (value ? value.lastname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Email address',
        key: 'recipient',
        isTagline: true,
        formatter: (value) => truncateText(value, 25) || 'N/A'
      },
      {
        title: 'Gender',
        key: 'recipient',
        formatter: (value) => (value ? value.gender : 'N/A')
      },
      {
        title: 'Bounced at',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Reason',
        key: 'reason',
        formatter: (value) => truncateText(value, 35) || 'N/A'
      }
    ],
    is_search_mode,
    items: Object.values(bounce_log).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const {
      maillogs,
      size,
      error: e
    } = await fetchMailLogs({
      query_string: `resource_type=transactional&status=bounced&resource_id=${transactional_id}&page=${page}&population=${population}`
    });
    if (e) {
      toast.error(e);
      return;
    }
    const contact_ids = maillogs.map((action) => action.recipient);
    const { contacts, error } = await fetchContacts({
      query_string: `email=${contact_ids.join()}`
    });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return;
    }
    const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.email]: c }), {});

    const logs = maillogs.map((log) => ({
      ...log,
      recipient: contacts_by_id[log.recipient]
    }));

    setBounceLog((curr_log) => ({
      ...curr_log,
      ...logs.reduce((s, a) => ({ ...s, [a.id]: a }), {})
    }));
    setTotalBounces(() => size);

    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    setLoading(true);
    try {
      const { contacts } = await searchContact(keys, keyword, {
        query_string: `page=0&population=${500}&return_only=id,firstname, lastname, email,gender`
      });

      if (contacts.length) {
        const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
        const contact_ids = contacts.map((c) => c.id);
        const { maillogs, size, error } = await fetchMailLogs({
          query_string: `resource_type=transactional&status=bounced&resource_id=${transactional_id}&recipient_id=${contact_ids.join()}&page=${page}&population=${population}`
        });
        if (error) {
          toast.error(error);
          return;
        }
        const logs = maillogs.map((log) => ({
          ...log,
          recipient: contacts_by_id[log.recipient_id]
        }));

        if (is_search_mode) {
          setBounceLog((curr_log) => ({
            ...curr_log,
            ...logs.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
        } else {
          setBounceLog(() => ({
            ...logs.reduce((s, a) => ({ ...s, [a.id]: a }), {})
          }));
          setSearchMode(true);
        }
        setTotalBounces(() => size);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            action={() => {}}
            onClick={() => {}}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              total_count: total_bounces
            }}
            action={() => {}}
            onClick={() => {}}
            checkbox
            loading_data={loading}
            table_actions={[]}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
