import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { formatDateForDisplay, getMonthTimeRange } from 'app/shared/utils/date';
import { useContactService } from 'hooks/contact';
import { useMailActionService } from 'hooks/mailaction';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const ContactStatBar = () => {
  const { start, end } = getMonthTimeRange();
  const { fetchContacts } = useContactService();
  const { fetchMailActions } = useMailActionService();
  const [last_created_at, setLastCreatedAt] = useState('');
  const [total_contacts, setTotalContacts] = useState(0);
  const [total_active, setTotalActive] = useState({
    all_time: 0,
    month: 0
  });
  const [total_invalid, setTotalInvalid] = useState({
    all_time: 0,
    month: 0
  });
  const [total_unsubscriptions, setTotalUnsubscriptions] = useState({
    all_time: 0,
    month: 0
  });

  useEffect(() => {
    Promise.all([
      fetchContacts({ query_string: 'count=1' }),
      fetchContacts({ query_string: 'bool=-is_valid&count=1' }),
      fetchContacts({ query_string: `bool=-is_valid&count=1&time_stamp=${start}~${end}` }),
      fetchContacts({
        query_string: 'sort_by=-created_on&page=0&population=1&return_only=id,created_on'
      }),
      fetchContacts({ query_string: `bool=is_unsubscribed&count=1` }),
      fetchContacts({
        query_string: `bool=is_unsubscribed&count=1&unsubscribed_on=${start}~${end}`
      }),
      fetchMailActions({
        query_string: 'action=opened,clicked_link&group_by=recipient_id&count=1'
      }),
      fetchMailActions({
        query_string: `action=opened,clicked_link&group_by=recipient_id&time_stamp=${start}~${end}&count=1`
      })
    ])
      .then((responses) => {
        const [
          { size: contact_count },
          { size: invalid_contact_count },
          { size: month_invalid_countacts },
          {
            contacts: [contact]
          },
          { size: unsubscriptions_all_time },
          { size: unsubscriptions_month },
          { mailactions: all_active },
          { mailactions: month_active }
        ] = responses;
        setTotalContacts(() => contact_count || 0);
        setTotalInvalid(() => ({
          all_time: invalid_contact_count || 0,
          month: month_invalid_countacts || 0
        }));
        setTotalUnsubscriptions(() => ({
          all_time: unsubscriptions_all_time || 0,
          month: unsubscriptions_month || 0
        }));

        if (contact) {
          setLastCreatedAt(contact.created_on);
        }

        setTotalActive(() => ({
          all_time: all_active[0] ? all_active[0].size : 0,
          month: month_active[0] ? month_active[0].size : 0
        }));
      })
      .catch((e) => {
        toast.error('Unable to fetch logs at this time.');
      });
  }, []);

  const statDetails = [
    {
      main_stat: {
        label: 'Total contacts',
        value: total_contacts,
        color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'Last created at', value: formatDateForDisplay(last_created_at) }
    },
    {
      main_stat: {
        label: 'Total active',
        value: total_active.all_time,
        color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: total_active.month }
    },
    {
      main_stat: {
        label: 'Total invalid',
        value: total_invalid.all_time,
        color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: total_invalid.month }
    },
    {
      main_stat: {
        label: 'Unsubscriptions',
        value: total_unsubscriptions.all_time,
        color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: total_unsubscriptions.month }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
    </>
  );
};
