export const passwordValidationConfig = {
  old_password: {
    required: true,
    minLength: 6,
    customErrorMessage: {
      minLength: 'Old password must be at least 6 characters'
    }
  },
  password: {
    required: true,
    minLength: 8,
    customErrorMessage: {
      minLength: 'New password must be at least 6 characters'
    }
  },
  confirm_password: {
    required: true,
    customValidator: ({ newPassword, confirmPassword }) => {
      const result = { is_valid: false, message: '' };
      if (newPassword !== confirmPassword) {
        result.message = 'Confirm password must match new password';
      } else {
        result.is_valid = true;
      }
      return result;
    }
  }
};
