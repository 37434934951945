import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useSMSLogService } from 'hooks/messaging/logs/sms';
import React, { useEffect, useState } from 'react';

export const SMSStatBar = ({ id }) => {
  const { fetchSMSLogs } = useSMSLogService();

  const [logs, setLogs] = useState({});

  useEffect(() => {
    if (!id) return;
    Promise.all([
      fetchSMSLogs({ query_string: `resource_id=${id}&status=sent&count=1` }),
      fetchSMSLogs({ query_string: `resource_id=${id}&status=failed&count=1` })
    ]).then(([{ size: sent }, { logs: failed }]) => {
      setLogs((curr_logs) => ({
        ...curr_logs,
        sent,
        failed
      }));
    });
  }, [id]);

  return (
    <>
      <SlidingStatBar>
        <PrimaryStatCard
          main_stat={{
            label: 'Sent',
            value: logs.sent,
            color: 'var(--blue-primary)'
          }}
          bottom_stat={{ label: '-', value: '-' }}
        />
        <PrimaryStatCard
          main_stat={{
            label: 'Undelivered',
            value: logs.failed,
            color: 'var(--blue-primary)'
          }}
          bottom_stat={{ label: '-', value: '-' }}
        />
      </SlidingStatBar>
    </>
  );
};
