import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { IconInput } from 'app/shared/input';
import GmModal from 'app/shared/modal/modal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { NeuronForm } from './form';
import { NeuronListPage } from './list';
import styles from './neurons.module.css';

export const NeuronModule = () => {
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState('');
  const [show_creation_form, setShowCreationForm] = useState(false);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Automations', path: '/automations/events' },
        { title: 'Neurons', path: '/automations/neurons' }
      ])
    );
  }, []);

  return (
    <>
      <GridRow num_of_columns={5}>
        <GridColumn>
          <IconInput
            placeholder="Search neurons"
            value={keyword}
            icon_name="search"
            onInput={setKeyword}
            is_rounded
          />
        </GridColumn>
        <GridColumn span={3} />
        <GridColumn>
          <Button text="Create Neuron" icon_name="add" onClick={() => setShowCreationForm(true)} />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <GridColumn>
          <NeuronListPage keyword={keyword} />
        </GridColumn>
      </GridRow>
      <GmModal
        bodyClassName={styles.formWrapper}
        position="right"
        show_title
        show_modal={show_creation_form}
        title="Create Neuron"
        onClose={() => setShowCreationForm(false)}
      >
        <NeuronForm />
      </GmModal>
    </>
  );
};
