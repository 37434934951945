import {
  TOGGLE_CONTACT_FIELD_VISIBILITY,
  TOGGLE_FOOTNOTE_VISIBILITY,
  TOGGLE_AUTOMATION_VISIBILITY
} from 'store/actions/settings';

export default (state = false, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_CONTACT_FIELD_VISIBILITY:
      return { ...state, ...payload };
    case TOGGLE_FOOTNOTE_VISIBILITY:
      return { ...state, ...payload };
    case TOGGLE_AUTOMATION_VISIBILITY:
      return { ...state, ...payload };
    default:
      return state;
  }
};
