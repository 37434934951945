import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import { useDagreLayout } from './dagre';
import { useAutomationStepService } from './steps';

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({ width, height });
      setTranslate({ x: 130, y: height / 2 });
    }
  }, []);

  const pathFunction = (linkData) => {
    const { source, target } = linkData;
    const sx = source.x + 45;
    const sy = source.y + 450;
    const tx = target.x + 45;
    const ty = target.y + 20;
    let depth = 0;
    if (tx < sx) {
      depth = tx + 8;
    } else if (tx > sx) {
      depth = tx - 8;
    }

    return tx === sx
      ? `M${sy},${sx}L${ty},${tx}`
      : `
      M${sy},${sx}
      L${sy + 100},${sx}
      L${sy + 100},${depth}
      C${sy + 100},${tx},${sy + 100},${tx},${sy + 105},${tx}
      L${ty + 100},${tx}
    `;
  };
  return [dimensions, translate, containerRef, pathFunction];
};

export const useReactFlowLayout = (template_id) => {
  const { getLaidOutElements } = useDagreLayout();
  const { updateStep } = useAutomationStepService();
  const steps_in_store = useSelector((store) => store.steps || []);
  const [edges, setEdges] = useState([]);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const parsed_edges = buildEdges(Object.values(steps_in_store), template_id);
    const parsed_nodes = buildNodeList(Object.values(steps_in_store), template_id);
    const { nodes: laidOutNodes, edges: laidOutEdges } = getLaidOutElements({
      nodes: parsed_nodes,
      edges: parsed_edges,
      direction: 'TB'
    });
    setEdges(() => laidOutEdges);
    setNodes(() => laidOutNodes);
  }, [steps_in_store]);

  const buildEdges = (steps = [], template_id = 0) => {
    const edges = [];
    const valid_steps = steps.filter((step) => {
      return step.template_id.toString() === template_id.toString();
    });

    valid_steps.forEach((step) => {
      const children = step.children || [];
      const { id } = step;

      if (step.parents[0] === 1) {
        edges.push({
          id: `rootconnector-${id}`,
          type: 'smoothstep',
          source: 'rootconnector',
          target: id.toString()
        });
      }

      if (step.branch_value) {
        for (const parent_id of step.parents) {
          if (Number(parent_id)) continue;
          edges.push({
            id: `${parent_id}-${id}`,
            type: 'smoothstep',
            source: `${parent_id}`,
            target: id.toString()
          });
        }
      }

      if (step.is_conditional) {
        edges.push(
          {
            id: `${id}-${id}_yes`,
            type: 'smoothstep',
            source: id.toString(),
            target: `${id}_yes`,
            label: 'Yes'
          },
          {
            id: `${id}-${id}_no`,
            type: 'smoothstep',
            source: id.toString(),
            target: `${id}_no`,
            label: 'No'
          }
        );
      } else {
        for (const child of children) {
          edges.push({
            id: `${id}-${child}`,
            type: 'smoothstep',
            source: id.toString(),
            target: child.toString()
          });
        }
      }
    });

    return edges;
  };

  const buildNodeList = (steps = [], template_id = 0) => {
    const parent_by_child = {};
    steps.forEach((step) => {
      const { children, id, is_root } = step;
      if (is_root) {
        parent_by_child[id] = { position: { x: 20, y: 100 } };
      }
      (children || []).forEach((child) => {
        parent_by_child[child] = {
          id
        };
      });
    });

    const valid_steps = steps.filter((step) => {
      let has_parent = false;
      for (const parent_id of step.parents) {
        if (!steps_in_store[parent_id]) continue;
        has_parent = true;
        break;
      }

      const is_valid =
        step.type !== 'exit' &&
        step.template_id.toString() === template_id.toString() &&
        (step.is_root || (!step.is_root && has_parent) || step.parents[0] === 1);
      return is_valid;
    });

    const parsed_steps = [];
    for (const step of valid_steps) {
      parsed_steps.push({
        id: step.id.toString(),
        data: { ...step, template_id },
        type: step.type,
        deletable: true
      });

      if (!step.is_conditional) continue;
      parsed_steps.push(
        {
          id: `${step.id}_yes`,
          data: { ...step, template_id },
          type: 'triggerBranch',
          deletable: true
        },
        {
          id: `${step.id}_no`,
          data: { ...step, template_id },
          type: 'triggerBranch',
          deletable: true
        }
      );
    }

    const root_steps = valid_steps.filter((step) => step.is_root);
    parsed_steps.unshift({
      id: 'entrybtn',
      type: 'entryPointBtn',
      data: { template_id }
    });

    if (root_steps && root_steps.length > 0) {
      parsed_steps.push({
        id: 'rootconnector',
        type: 'rootNodeConnector',
        data: { template_id }
      });
    }

    return [...parsed_steps];
  };

  const validateStepsConnection = (source, target) => {
    if (source.type !== 'trigger' || (source.type === 'action' && target.type === 'delay')) {
      return false;
    }

    return true;
  };

  const onConnect = useCallback(
    (connection) => {
      const { source: source_id, target: target_id } = connection;

      if (!source_id || !target_id) return;
      const [cleaned_source_id, branch_value] = source_id.split('_');
      const [cleaned_target_id] = target_id.split('_');

      const source = steps_in_store[cleaned_source_id];
      const target = steps_in_store[cleaned_target_id];

      if (!validateStepsConnection(source, target)) return;

      Promise.all([
        updateStep(source.id, { children: [...source.children, target.id] }),
        updateStep(target.id, {
          branch_value: branch_value ? 'any' : '',
          parents: [...target.parents, source_id, source.id]
        })
      ]).then(([source_result, target_result]) => {
        if (!source_result || !target_result) return false;
        return true;
      });

      setEdges((curr_edges) => addEdge(connection, curr_edges));
    },
    [setEdges, steps_in_store]
  );
  const onEdgeChange = useCallback(
    (changes) => {
      setEdges((curr_edges) => applyEdgeChanges(changes, curr_edges));
    },
    [setEdges]
  );

  const onNodeChange = useCallback(
    (changes) => {
      setNodes((curr_nodes) => applyNodeChanges(changes, curr_nodes));
    },
    [setNodes]
  );

  return { edges, nodes, onConnect, onEdgeChange, onNodeChange };
};
