import { GridColumn, GridRow } from 'app/layouts/grid';
import Spinner from 'app/shared/spinners/spinner-25/spinner-25';
import { useMembershipService } from 'hooks/iam/membership';
import { useUserService } from 'hooks/iam/user';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDataToStore } from 'store/actions/user-data';
import styles from './switch.module.css';
import { toast } from 'react-toastify';
import { Icon } from 'assets/icons';
import cx from 'classnames';

const OrganizationOption = ({ current, data, onClick = () => {} }) => {
  return (
    <GridColumn>
      <div
        className={cx(styles.itemWrapper, current ? styles.itemWrapperStyled : '')}
        onClick={() => onClick(data)}
      >
        {data.name}
        {current && (
          <span>
            <Icon name="check" />
          </span>
        )}
      </div>
    </GridColumn>
  );
};

export const SwitchOrganizationModal = ({ closeModal = () => {} }) => {
  const dispatch = useDispatch();
  const { switchTenant } = useMembershipService();
  const { fetchOrganizations } = useUserService();
  const { email, id: user_id, tenant_id } = useSelector((store) => store.user_data);

  const [is_switching, setIsSwitching] = useState(false);
  const [my_orgs, setMyOrgs] = useState([]);

  useEffect(() => {
    fetchOrganizations({ query_string: 'status=active' }).then(({ organizations, error }) => {
      if (error) return toast.error(error);
      setMyOrgs(() => organizations);
    });

    return () => {
      setIsSwitching(() => false);
    };
  }, []);

  const handleSwitch = async (org_data) => {
    setIsSwitching(true);
    const data = {
      email,
      user_id,
      role_id: 'generic',
      tenant_id: org_data.id
    };
    const payload = await switchTenant({ data });
    if (payload && payload.id) {
      dispatch(addDataToStore({ ...payload, logged_in_time: Date.now() }));
      closeModal();
    }
    setIsSwitching(false);
  };

  return (
    <div className={styles.list}>
      {is_switching ? (
        <div className={styles.switchWrapper}>
          <Spinner />
          <span>Switching Organization</span>
        </div>
      ) : (
        <GridRow num_of_columns={2}>
          {my_orgs.map(({ tenant }) => (
            <OrganizationOption
              current={tenant.id === tenant_id}
              key={tenant.id}
              data={tenant}
              onClick={handleSwitch}
            />
          ))}
        </GridRow>
      )}
    </div>
  );
};
