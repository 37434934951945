import { TimePicker } from '@arco-design/web-react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { TreeContext } from 'contexts/automation';
import { useAutomationStepService } from 'hooks/automation/steps';
import useValidator from 'hooks/validator';
import React, { useContext, useEffect, useState } from 'react';
import styles from './config.module.css';
import { DelaySchema } from './validation';

const INTERVAL_TYPES = [
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Day of week', value: 'weekday' }
];

const WEEKDAYS = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 }
];

const CONFIG_CODE = 'delay';

export const AutomationDelayConfig = () => {
  const { createStep, updateStep } = useAutomationStepService();
  const { step_modal, setWidgetToCreate, toggleStepModal } = useContext(TreeContext);
  const { errors, validateField, form_is_valid } = useValidator(DelaySchema);

  const [creating, setCreating] = useState(false);
  const [interval, setInterval] = useState(0);
  const [interval_type, setIntervalType] = useState(INTERVAL_TYPES[0]);
  const [selected_weekday, setSelectedWeekday] = useState(WEEKDAYS[0]);
  const [time_range, setTimeRange] = useState(['00:00:00', '00:00:00']);

  //
  const is_edit_mode = step_modal.mode === 'edit';
  useEffect(() => {
    if (!is_edit_mode) return;
    const {
      rules: [rule]
    } = step_modal.data;
    setInterval(rule.interval);
    setIntervalType(
      () => INTERVAL_TYPES.find((i_type) => i_type.value === rule.interval_type) || {}
    );
    setSelectedWeekday(() => WEEKDAYS.find((weekday) => weekday.value === rule.weekday) || {});
    setTimeRange(() => rule.time_range);
  }, [is_edit_mode]);

  useEffect(() => {
    validateField('interval', interval.toString());
  }, [interval]);

  const computeDelay = (interval_type, interval) => {
    return interval_type === 'hour' ? interval : 24 * interval;
  };

  const submit = async () => {
    try {
      const rule = {
        type: CONFIG_CODE,
        delay: computeDelay(interval_type.value, interval),
        interval,
        interval_type: interval_type.value,
        time_range,
        timezone: new Date().getTimezoneOffset(),
        weekday: selected_weekday.value
      };

      const data = {
        branch_value: is_edit_mode ? step_modal.data.branch_value : step_modal.branch_value,
        source: is_edit_mode ? step_modal.data.source : step_modal.source,
        template_id: step_modal.template_id,
        type: CONFIG_CODE,
        parents: step_modal.parents,
        rules: [rule]
      };

      const step_id = is_edit_mode ? step_modal.data.id : null;
      const [child_step_id] = step_modal.data?.children || [];
      setCreating(() => true);
      let result = null;

      if (step_id) {
        result = await updateStep(step_id, data);
        if (result && child_step_id) await updateStep(child_step_id, { rules: [rule] });
      } else {
        result = await createStep(data);
      }

      if (result) {
        setInterval(0);
        setIntervalType(INTERVAL_TYPES[0]);
        setWidgetToCreate(() => '');
        toggleStepModal({ show: false });
      }
    } finally {
      setCreating(() => false);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Interval type">
            <MultiSelect
              options={INTERVAL_TYPES}
              onChange={setIntervalType}
              value={interval_type}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {interval_type.value === 'weekday' ? (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Day of the week">
              <MultiSelect
                options={WEEKDAYS}
                onChange={setSelectedWeekday}
                value={selected_weekday}
                isSorted={false}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      ) : (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Interval">
              <SimpleInput onInput={setInterval} value={interval} error={errors.interval} />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
      <GridRow num_of_columns={2}>
        <GridColumn span={2}>
          <DetailItem title="Preferred Time Range">
            <TimePicker.RangePicker
              className={styles.timepicker}
              disabledSeconds
              onChange={setTimeRange}
              value={time_range}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Save" disabled={creating || !form_is_valid} onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
