import React from 'react';
import cx from 'classnames';
import styles from './switch.module.css';

export const Switch = ({
  checked = false,
  onChange = () => {},
  checkedText = 'On',
  uncheckedText = 'Off',
  disabled = false,
  className = '',
  wrapperClassName = '',
  switchId = '',
  style = {}
}) => {
  const handleToggle = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  return (
    <div className={cx(styles.switchWrapper, wrapperClassName)} onClick={handleToggle}>
      <label htmlFor={switchId} className={cx(styles.switchLabel, { [styles.disabled]: disabled })}>
        <input
          type="checkbox"
          id={switchId}
          className={cx(styles.switchInput, className)}
          checked={checked}
          onChange={() => {}}
          disabled={disabled}
          style={style}
        />
        <span
          className={cx(styles.slider, { [styles.checked]: checked })}
          style={{ padding: '5px', justifyContent: checked ? 'flex-start' : 'flex-end' }}
        >
          {checked ? checkedText : uncheckedText}
        </span>
      </label>
    </div>
  );
};
