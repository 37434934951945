import cx from 'classnames';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import React, { useContext, useEffect, useState } from 'react';
import styles from './config.module.css';

const WidgetOption = ({
  disabled = false,
  title = '',
  icon_name,
  icon_classname = '',
  onSelect = () => {}
}) => {
  const handleSelect = () => {
    if (disabled) return;
    onSelect();
  };

  return (
    <div
      className={cx(disabled && styles.disabled, styles.selectionWrapper)}
      onClick={handleSelect}
    >
      <span className={icon_classname}>
        <Icon name={icon_name} />
      </span>
      <span>{title}</span>
    </div>
  );
};

export const WidgetSelector = () => {
  const { step_modal, setWidgetToCreate } = useContext(TreeContext);

  const [source_widget, setSourceWidget] = useState('');
  useEffect(() => {
    if (step_modal && !step_modal.source_widget) {
      setSourceWidget(() => '');
      return;
    }

    setSourceWidget(step_modal.source_widget);
  }, [step_modal]);

  const is_delay_disabled = source_widget === 'action' || source_widget === 'delay';
  const is_trigger_disabled = source_widget === 'delay' || source_widget === 'trigger';
  const is_action_disabled = source_widget === 'action';

  return (
    <div id={styles.selectorWrapper}>
      <WidgetOption
        disabled={is_delay_disabled}
        title="Delay"
        icon_name="timer"
        icon_classname={styles.delay}
        onSelect={() => setWidgetToCreate('delay')}
      />
      <WidgetOption
        disabled={is_trigger_disabled}
        title="Trigger"
        icon_name="bolt"
        icon_classname={styles.trigger}
        onSelect={() => setWidgetToCreate('trigger')}
      />
      <WidgetOption
        disabled={is_action_disabled}
        title="Action"
        icon_name="manufacturing"
        icon_classname={styles.action}
        onSelect={() => setWidgetToCreate('action')}
      />
    </div>
  );
};
