import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as ConfigService from 'services/automation/config';

export const useAutomationConfigService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchAutomationConfigs = async (options = {}) => {
    const { error, payload } = await ConfigService.read({ ...options, token });
    if (error) {
      return { error, configs: [], size: 0 };
    }

    const {
      data: configs,
      meta: { size }
    } = payload;
    return { configs, size, error: null };
  };

  const searchAutomationConfigs = async (keys, keyword, options = {}) => {
    const { error, payload } = await ConfigService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, configs: [], size: 0 };
    }

    const {
      data: configs,
      meta: { size }
    } = payload;
    return { error, configs, size };
  };

  const updateAutomationConfig = async (options = {}) => {
    const { error, payload } = await ConfigService.updateBulk({ ...options, token });
    if (error) {
      toast.error(error);
      return { config: null };
    }

    toast.success('Configuration saved.');
    return { config: payload };
  };

  return {
    fetchAutomationConfigs,
    searchAutomationConfigs,
    updateAutomationConfig
  };
};
