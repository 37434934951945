import { useSelector } from 'react-redux';
import * as PlanService from 'services/plan';

export const usePlanService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchPlans = async (options = {}) => {
    const { error, payload } = await PlanService.read({ ...options, token });
    if (error) {
      return { plans: [], size: 0, error: 'Unable to fetch plans at this time.' };
    }

    const {
      data: plans,
      meta: { size }
    } = payload;
    return { plans, size, error: null };
  };

  return {
    fetchPlans
  };
};
