import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { TutorialListPage } from './list';

export const TutorialModule = () => {
  return (
    <PageContentWrapper>
      <Routes>
        <Route path="/*" element={<TutorialListPage />} />
      </Routes>
    </PageContentWrapper>
  );
};
