import React from 'react';
import { Button } from 'app/shared/button';
import styles from './dialog.module.css';

const ConfirmationDialog = ({
  title,
  message,
  callback: onAction,
  is_open,
  is_loading = false
}) => {
  const cancel = () => onAction(false);
  const proceed = () => onAction(true);

  if (!is_open) return null;
  return (
    <div className={styles['dialog-veil']}>
      <div className={styles['dialog-content-wrapper']}>
        <h5 className={styles['dialog-header']}>{title}</h5>
        {title && <hr />}
        <div className={styles['dialog-message']}>{message}</div>
        <div className={styles['dialog-actions']}>
          <Button className="w-50" onClick={cancel} type="secondary" text="Cancel" />
          <Button className="w-50" onClick={proceed} text="Proceed" disabled={is_loading} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
