import React, { useState, useEffect } from 'react';

import DataTableHeader from './header/header';
import DataTableItem from './item/item';
import Spinner from 'app/shared/spinners/spinner-15/spinner-15';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';

import '../datatable.css';
import { IconInput } from 'app/shared/input';
import { MoreButton } from 'app/shared/button/more';
import useScreenSize from 'hooks/size';
import { Icon } from 'assets/icons';

const DataTable = (props) => {
  const {
    showHeader = true,
    action,
    checkbox,
    config,
    onClick,
    loading_data,
    table_actions = [],
    onDataRequest = (f) => f,
    onSearchRequest = (f) => f,
    resetBulkSelection
  } = props;
  const { fields, items, num_of_rows = 20, search_text, total_count } = config;

  const [number_of_rows_to_display] = useState(num_of_rows);
  const [page_number, setPageNumber] = useState(0);

  const [selected_items, setSelectedItems] = useState({});
  const [search_keyword, setSearchKeyword] = useState('');
  const [search_keys, setSearchKeys] = useState('');
  const [debounce_timer, setDebounceTimer] = useState('');
  const [is_selected, setIsSelected] = useState(false);

  const [table_items, setTableItems] = useState([]);
  const [items_to_display, setItemsToDisplay] = useState([]);
  const { isDesktop } = useScreenSize();

  useEffect(() => {
    buildSearchKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetBulkSelection) setSelectedItems({});
  }, [resetBulkSelection]);

  useEffect(() => {
    if (search_keyword) onSearchRequest(search_keys, search_keyword, page_number, 100);
    else onDataRequest(page_number, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page_number]);

  useEffect(() => {
    if (debounce_timer) clearTimeout(debounce_timer);
    // debounce search at every 1s
    const timer = setTimeout(() => onSearchRequest(search_keys, search_keyword, 0), 250);
    setDebounceTimer(timer);
    setPageNumber(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_keyword]);

  useEffect(() => {
    const processed_items = items.map((item) => ({
      ...item,
      search_metadata: {
        haystack: Object.values(item).join().toLowerCase(),
        checked: false
      }
    }));

    setTableItems(() => processed_items.slice());
  }, [items]);

  useEffect(() => {
    handleNumberOfItemsToDisplay();
    formatDataTableFooter(page_number, number_of_rows_to_display, table_items);
  }, [table_items]);

  const buildSearchKeys = () => {
    const keys = [];
    fields.forEach((field) => {
      if (!field.isTitle && !field.isTagline) return;
      keys.push(field.key);
    });
    setSearchKeys(keys.join());
  };

  /** */
  const closeActionMode = () => {
    setSelectedItems(() => ({}));
  };

  const formatDataTableFooter = (page_number, number_of_rows_to_display, items) => {
    const start = page_number ? page_number * number_of_rows_to_display + 1 : 1;
    const end = number_of_rows_to_display + start - 1;
    const content_text_for_empty_data = 'No item(s) to display';
    const content_text_for_nonempty_data = `${start} - ${
      end > total_count ? total_count : end
    } of ${total_count}`;

    return items.length ? content_text_for_nonempty_data : content_text_for_empty_data;
  };

  const formatPagination = () => {
    const high = Math.ceil(total_count / number_of_rows_to_display);
    return (
      <div className="gm-datatable-pagination">
        {loading_data ? (
          <div className="gm-datatable-loader-indicator">
            <Spinner /> <span>Loading</span>
          </div>
        ) : (
          <></>
        )}
        {page_number > 0 ? (
          <button onClick={() => handlePageChange(-1)}> {'Prev'} </button>
        ) : (
          <button disabled> {'Prev'} </button>
        )}
        {page_number < high - 1 ? (
          <button onClick={() => handlePageChange(1)}> {'Next'} </button>
        ) : (
          <button disabled> {'Next'} </button>
        )}
      </div>
    );
  };

  const handleItemClick = (item_data) => {
    onClick(item_data);
  };

  const handleNumberOfItemsToDisplay = () => {
    const start = page_number * number_of_rows_to_display;
    const end = start + number_of_rows_to_display;

    setItemsToDisplay(() => [...table_items].slice(start, end));
  };

  const handlePageChange = (value) => {
    setPageNumber((page_number) => page_number + value);
  };

  const handleSearch = async (keyword) => {
    setSearchKeyword(keyword);
  };

  const handleSelection = (selected_item) => {
    setSelectedItems((selected_items) => ({
      ...selected_items,
      [selected_item.id]: selected_item
    }));
  };

  const handleUnselection = (id) => {
    const selections = { ...selected_items };
    delete selections[id];
    setSelectedItems(() => selections);
  };

  const handleSort = (field, is_ascending) => {
    const sorted_items = [...table_items];
    if (!is_ascending) {
      sorted_items.sort((a, b) => {
        if (a[field] > b[field]) return 1;
        else return -1;
      });
    } else {
      sorted_items.sort((a, b) => {
        if (a[field] > b[field]) return -1;
        else return 1;
      });
    }

    setTableItems(() => [...sorted_items]);
  };

  const processAction = async (name, data) => {
    if (data && data.id) {
      // it is a single action
      action({
        name,
        type: 'single',
        data
      });
      return;
    }

    if (!Object.keys(selected_items).length) return;

    action({
      name,
      type: 'bulk',
      data: Object.values(selected_items)
    });
  };

  const toggleBulkSelection = async () => {
    if (checkBulkMode()) {
      closeActionMode();
      return;
    }

    await new Promise((resolve, reject) => setTimeout(resolve, 500));

    const source = items_to_display;
    const selections = source.reduce((sack, result) => {
      if (!result.is_active) return sack;
      return {
        ...sack,
        [result.id]: result
      };
    }, {});
    setSelectedItems(() => selections);
  };

  const checkBulkMode = () => {
    return Object.keys(selected_items).length === items_to_display.length;
  };

  return (
    <div className="gm-datatable">
      {showHeader && (
        <section className="gm-datatable-header">
          <div className="gm-datatable-search">
            <IconInput
              icon_name="search"
              placeholder={search_text || 'Search'}
              onInput={(value) => handleSearch(value)}
              value={search_keyword}
              wrapperClassName="search-input"
            />
          </div>
          <div className="gm-datatable-actions">
            {table_actions}
            <ContextMenu
              actions={config.actions.bulk}
              callback={processAction}
              text={
                isDesktop ? (
                  <MoreButton text="Actions" disabled={!config.actions.bulk} />
                ) : (
                  <Icon name="more_vert" />
                )
              }
            />
          </div>
        </section>
      )}
      <section className="gm-datatable-table">
        <table>
          <thead>
            <DataTableHeader
              actions={config.actions.bulk}
              checkbox={checkbox}
              data={fields}
              sort_callback={handleSort}
              onChange={toggleBulkSelection}
              unselect={resetBulkSelection}
              is_selected={is_selected}
              setIsSelected={setIsSelected}
              is_bulk_mode={checkBulkMode()}
            />
          </thead>
          <tbody>
            {items_to_display.map((item) => (
              <DataTableItem
                actions={config.actions.single}
                action_callback={processAction}
                checkbox={config.actions.bulk}
                data={item}
                fields={fields}
                index={item.id}
                item_click_callback={handleItemClick}
                key={item.id}
                bulk_selection={selected_items}
                selection_callback={handleSelection}
                unselection_callback={handleUnselection}
                deselect={!Object.keys(selected_items).length}
              />
            ))}
          </tbody>
        </table>
      </section>
      <section className="gm-datatable-footer">
        <div>{formatDataTableFooter(page_number, number_of_rows_to_display, table_items)}</div>
        {formatPagination()}
      </section>
    </div>
  );
};

export default DataTable;
