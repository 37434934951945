/** */
import { urls } from './_url';
import { apiGet, apiPost } from './_http';

/** */
const base_url = `${urls.iam}/keys`;
export const create = async (options = {}) => {
  return await apiPost(`${base_url}`, { ...options });
};

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};

export const checkFeatureFlagPermission = async (options = {}) => {
  const { error, payload } = await apiPost(`${urls.iam}/flags`, { ...options });
  if (error) return { is_permitted: false };
  return payload;
};

export const tokenVerification = async (options = {}) => {
  return await apiGet(`${urls.iam}/verification/token`, { ...options });
};
