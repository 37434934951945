import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as EventService from 'services/event';

export const useEventsService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createEvent = async (options = {}) => {
    const { error, payload } = await EventService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return { event: null };
    }

    return { event: payload };
  };

  const fetchEvent = async (id, options = {}) => {
    const { error, payload } = await EventService.readById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchEvents = async (options = {}) => {
    const { error, payload } = await EventService.read({ ...options, token });
    if (error) {
      return { error, events: [], size: 0 };
    }

    const {
      data: events,
      meta: { size }
    } = payload;
    return { events, size, error: null };
  };

  const isCustomEvent = (event) => {
    return event && event.type === 'user';
  };

  const searchEvents = async (keys, keyword, options = {}) => {
    const { error, payload } = await EventService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, events: [], size: 0 };
    }

    const {
      data: events,
      meta: { size }
    } = payload;
    return { error, events, size };
  };

  const updateEvent = async (id, options = {}) => {
    const { error, payload } = await EventService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { event: null };
    }

    return { event: payload };
  };

  const removeEvent = async (id) => {
    const { error } = await EventService.deleteOne(id, { token });
    if (error) {
      toast.error('could not delete contact.');
      return false;
    }

    return true;
  };

  return {
    createEvent,
    fetchEvent,
    fetchEvents,
    isCustomEvent,
    removeEvent,
    searchEvents,
    updateEvent
  };
};
