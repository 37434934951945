/** */
export const CREATE_MESSAGE = 'create push message';
export const DELETE_MESSAGE = 'delete push message';
export const READ_MESSAGE = 'read push messages';
export const UPDATE_MESSAGE = 'update push message';

/** */
export const ADD_ONE_MESSAGE_TO_STORE = 'add one push message to store';
export const ADD_MANY_MESSAGES_TO_STORE = 'add many push messages to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add push message search results to store';
export const LOAD_MESSAGE_TO_STORE = 'load push messages to store from API call';
export const REMOVE_MANY_MESSAGES_FROM_STORE = 'remove many push messages from the store.';
export const REMOVE_ONE_MESSAGE_FROM_STORE = 'remove a push message from the store.';

/** */
export const createPushMessage = (payload) => ({
  type: CREATE_MESSAGE,
  payload
});

export const deletePushMessage = (payload) => ({
  type: DELETE_MESSAGE,
  payload
});

export const deleteManyPushMessages = (payload) => ({
  type: REMOVE_MANY_MESSAGES_FROM_STORE,
  payload
});

export const readPushMessage = (payload) => ({
  type: READ_MESSAGE,
  payload
});

export const updatePushMessageInStore = (payload) => ({
  type: UPDATE_MESSAGE,
  payload
});

/** */

export const addManyPushMessagesToStore = (payload) => ({
  type: ADD_MANY_MESSAGES_TO_STORE,
  payload
});

export const addOnePushMessageToStore = (payload) => ({
  type: ADD_ONE_MESSAGE_TO_STORE,
  payload
});

export const addPushMessageSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadPushMessagesToStore = (payload) => ({
  type: LOAD_MESSAGE_TO_STORE,
  payload
});

export const removeManyPushMessagesFromStore = (payload) => ({
  type: REMOVE_MANY_MESSAGES_FROM_STORE,
  payload
});

export const removeOnePushMessageFromStore = (payload) => ({
  type: REMOVE_ONE_MESSAGE_FROM_STORE,
  payload
});
