/** */
export const ADD_ONE_TUTORIAL_TO_STORE = 'add one tutorial to store';
export const ADD_MANY_TUTORIALS_TO_STORE = 'add many tutorials to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add tutorial search results to store';
export const LOAD_TUTORIALS_TO_STORE = 'load tutorials to store from API call';
export const REMOVE_MANY_TUTORIALS_FROM_STORE = 'remove many tutorials from the store.';
export const REMOVE_ONE_TUTORIAL_FROM_STORE = 'remove a tutorial from the store.';

/** */

export const addManyTutorialsToStore = (payload) => ({
  type: ADD_MANY_TUTORIALS_TO_STORE,
  payload
});

export const addOneTutorialToStore = (payload) => ({
  type: ADD_ONE_TUTORIAL_TO_STORE,
  payload
});

export const addTutorialSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadTutorialsToStore = (payload) => ({
  type: LOAD_TUTORIALS_TO_STORE,
  payload
});

export const removeManyTutorialsFromStore = (payload) => ({
  type: REMOVE_MANY_TUTORIALS_FROM_STORE,
  payload
});

export const removeOneTutorialFromStore = (payload) => ({
  type: REMOVE_ONE_TUTORIAL_FROM_STORE,
  payload
});
