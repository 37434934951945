import { useSelector } from 'react-redux';
import * as MailLogService from 'services/mail-log';

export const useMailLogService = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchMailLogs = async (options = {}) => {
    const { error, payload } = await MailLogService.read({ ...options, token });
    if (error) {
      return { maillogs: [], size: 0, error: 'Unable to fetch maillogs at this time.' };
    }

    const {
      data: maillogs,
      meta: { size }
    } = payload;
    return { maillogs, size, error: null };
  };

  return { fetchMailLogs };
};
