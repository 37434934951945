import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as KeyService from 'services/iam';

export const useKeyService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchKey = async (tenant_id, options = {}) => {
    const { error, payload } = await KeyService.readById(tenant_id, { ...options, token });
    if (error) {
      return null;
    }

    return payload;
  };

  const createKey = async (options = {}) => {
    const { error, payload } = await KeyService.create({ ...options, token });
    if (error) {
      toast.error('Unable to generate key at this time.');
      return null;
    }

    toast.success('API Key generated successfully.');
    return payload;
  };

  return { createKey, fetchKey };
};
