import { toast } from 'react-toastify';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { urls } from 'services/_url';
import CreateWebContactMapper from './selector-field/index';
import { GridColumn, GridRow } from 'app/layouts/grid';
import styles from './website.module.css';
import { useKeyService } from 'hooks/key';

export const WebsiteConfiguration = ({ showModal }) => {
  const { id: tenant_id } = useSelector((store) => store.user_data);
  const { fetchKey } = useKeyService();

  const [api_key, setAPIKey] = useState(null);
  const [selected_audiences, setSelectedAudiences] = useState([]);
  const [snippet, setSnippet] = useState('');
  const [selector, setSelector] = useState('');

  const copySnippet = (snippet) => {
    navigator.clipboard
      .writeText(snippet)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };
  const generateCode = (mapped_data) => {
    if (!api_key) {
      toast.error('No API Key found. Please generate one or reload the page if you have.');
      return;
    }

    const audience_ids = selected_audiences.map((audience) => audience.value).join();
    let snippet = `fetch(\`${urls.users}/api/contacts/${api_key || ''}/capture/${audience_ids}?`;
    // eslint-disable-next-line no-template-curly-in-string
    let selector = '';
    const open = '${';
    const close = '}';
    mapped_data.forEach((obj) => {
      for (const key in obj) {
        selector += `let ${obj[key]} = document.querySelector("#${obj[key]}"); `;
        snippet += `${key}=${open}${obj[key]}.value${close}&`;
      }
    });
    snippet += `source='website-api'`;
    snippet =
      snippet.charAt(snippet.length - 1) === '&'
        ? (snippet = snippet.slice(0, snippet.length - 1))
        : snippet;

    snippet += '`)';
    setSnippet(snippet);
    setSelector(selector);
  };

  useEffect(() => {
    if (!showModal) {
      setSnippet('');
      setSelector('');
      setSelectedAudiences([]);
    }
    fetchKey(tenant_id).then((payload) => {
      if (!payload) return;
      setAPIKey(payload.key);
    });
  }, [showModal]);

  const full_snippet = `
        <script type="text/javascript">
            window.addEventListener('load', function() {
              let btn = document.querySelector('button[type*="submit"]') || document.querySelector('input[type*="submit"]');
              ${selector}
              btn.addEventListener('click', function () {
                ${snippet}
              });
            });
      </script>
  `;

  return (
    <div className={styles.outerWrapper}>
      <GridRow num_of_columns={5} auto_flow={false}>
        <GridColumn span={3} auto_columns={false}>
          <div className={styles['website-integration']}>
            <div className={styles['info-wrap']}>
              <p>
                The website integration allows you to create contacts, and, optionally, add them to
                one or more audiences directly from your webforms.
              </p>
            </div>

            <div>
              {snippet && (
                <small className={cx('pb-2', styles['text-info'])}>
                  **click on snippet to copy.
                </small>
              )}
            </div>
            {snippet && (
              <div className={cx(styles['code-wrapper'], 'is-clickable')}>
                <code onClick={(e) => copySnippet(full_snippet)}>{full_snippet}</code>
              </div>
            )}
            {!snippet && (
              <CreateWebContactMapper
                selected_audiences={selected_audiences}
                setSelectedAudiences={setSelectedAudiences}
                generateCode={generateCode}
              />
            )}
          </div>
        </GridColumn>
        <GridColumn span={2} auto_columns={false}>
          <section className={styles['integration-nav']}>
            <h6>
              <b>Setup Guide:</b>
            </h6>
            <ol className="m-0 pl-3">
              <li>
                To get started, <NavLink to="/settings/api">generate</NavLink> your API key.
              </li>
              <li>[optional]</li> Select one or more audiences that you want to add contacts to.
              <li>[optional]</li> If you'd like to capture more information, click on 'Add field'
              button, enter the target element's HTML ID and map it to the appropriate property.
              <li>Click "Generate Snippet" button.</li>
              <li>Copy the generated snippet. You can click on the snippet to copy.</li>
              <li>
                Paste the snippet in your existing HTML document (on the same page as the target
                form).
              </li>
              <li>That's it. Congratulations!</li>
            </ol>
          </section>
        </GridColumn>
      </GridRow>
    </div>
  );
};
