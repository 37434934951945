/** */
import { urls } from './_url';
import { apiPost } from './_http';

/** */
const base_url = `${urls.sales}/payments`;

export const create = async (options = {}) => {
  return await apiPost(`${base_url}`, { ...options });
};

export const verify = async (options = {}) => {
  return await apiPost(`${base_url}/rave/verification`, { ...options });
};
