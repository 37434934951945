import React from 'react';
import { tiny_config } from './config';
import { Editor } from 'vendors/@tinymce/tinymce-react/lib/es2015/main/ts/index';
import './editor.css';

export const TextEditor = ({
  default_value,
  onTextChange,
  editor_id = '',
  auto_complete_options = [],
  placeholder = 'Start Typing...',
  onImageUpload = () => {},
  onFilePick = () => {}
}) => {
  const config = tiny_config(onFilePick, onImageUpload, placeholder, auto_complete_options);

  return (
    <Editor
      id={editor_id}
      init={config}
      value={default_value}
      onEditorChange={(e) => onTextChange(e)}
    />
  );
};
