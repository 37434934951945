import cx from 'classnames';
import { Icon } from 'assets/icons';
import { useFeatureFlag } from 'hooks/go-flags';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './item.module.css';

const NavBadge = ({ value, type = 'default' }) => {
  return <span className={cx(styles.navBadge, styles[type])}>{value}</span>;
};

const SideNavItem = ({ title, name, path, onClick, onItemClick }) => {
  const [is_new, setIsNew] = useState(false);
  const [is_coming_soon, setIsComingSoon] = useState(false);
  const { isFeatureAllowed } = useFeatureFlag();

  useEffect(() => {
    Promise.all([
      isFeatureAllowed('Flag.ComingSoon', { name }),
      isFeatureAllowed('Flag.new', { name })
    ]).then(([{ is_permitted: coming_soon_allowed }, { is_permitted: new_allowed }]) => {
      setIsComingSoon(() => coming_soon_allowed);
      setIsNew(() => new_allowed);
    });
  }, [name]);

  const handleItemClick = () => {
    if (onClick) {
      onClick();
    }

    onItemClick();
  };

  return (
    <>
      {!path ? (
        <div className={styles.item} onClick={handleItemClick}>
          <div>
            <span className={styles.icon}>
              <Icon name={name} />
            </span>
            <div className={styles.title}>
              {title}
              {is_coming_soon && <NavBadge value="coming soon" />}
              {is_new && <NavBadge value="new" type="green" />}
            </div>
          </div>
        </div>
      ) : (
        <NavLink to={path} className={styles.item} onClick={handleItemClick}>
          <div>
            <span className={styles.icon}>
              <Icon name={name} />
            </span>
            <div className={styles.title}>
              {title}
              {is_coming_soon && <NavBadge value="coming soon" />}
              {is_new && <NavBadge value="new" type="green" />}
            </div>
          </div>
        </NavLink>
      )}
    </>
  );
};

export default SideNavItem;
