export const processDuplicateCode = (name = '', slug = '', separator = '_') => {
  if (!name || !slug) return;

  let count = 0;
  let result = name;
  const split_name = name.split(separator);
  if (split_name.length === 1) result = `${name} ${separator} ${slug}`;
  else {
    const last_index = split_name.length - 1;
    const count_part = split_name[last_index];
    const slug_part = split_name[last_index - 1];

    if (!slug_part.includes(slug) && !count_part.includes(slug)) {
      result = `${name} ${separator} ${slug}`;
    } else if (count_part.includes(slug)) {
      result = `${split_name
        .slice(0, -1)
        .join(separator)
        .trim()} ${separator} ${slug} ${separator} ${count + 1}`;
    } else {
      count = Number(count_part) || 0;
      result = `${split_name
        .slice(0, -2)
        .join(separator)
        .trim()} ${separator} ${slug} ${separator} ${count + 1}`;
    }
  }

  return result.replace(/\s/gi, '');
};

export const processDuplicateName = (
  name = '',
  slug = '',
  separator = '-',
  preserve_space = true
) => {
  if (!name || !slug) return;

  let count = 0;
  let result = name;
  const split_name = name.split(separator);
  if (split_name.length === 1) result = `${name} ${separator} ${slug}`;
  else {
    const slug_part = split_name.pop();

    if (!slug_part.includes(slug)) {
      result = `${name} ${separator} ${slug}`;
    } else {
      const [, count_part] = slug_part.trim().split(' ');
      count = Number(count_part) || 0;
      result = `${split_name.join(separator).trim()} ${separator} ${slug} ${count + 1}`;
    }
  }

  return preserve_space ? result : result.replace(/\s/gi, '');
};
