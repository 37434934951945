import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListTransactional from './list/list';
import { TransactionalViewPage } from './views';

const TransactionalModule = () => {
  return (
    <Routes>
      <Route path=":id/*" element={<TransactionalViewPage />} />
      <Route path="/*" element={<ListTransactional />} />
    </Routes>
  );
};

export default TransactionalModule;
