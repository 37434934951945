export const TOGGLE_CONTACT_FIELD_VISIBILITY = 'change contact field visibility';
export const TOGGLE_FOOTNOTE_VISIBILITY = 'change foot note field visibility';
export const TOGGLE_AUTOMATION_VISIBILITY = 'change automation field visibility';
export const updateContactFieldVisibility = (data) => ({
  payload: data,
  type: TOGGLE_CONTACT_FIELD_VISIBILITY
});

export const updateFootnoteVisibility = (data) => ({
  payload: data,
  type: TOGGLE_FOOTNOTE_VISIBILITY
});

export const updateAutomationVisibility = (data) => ({
  payload: data,
  type: TOGGLE_AUTOMATION_VISIBILITY
});
