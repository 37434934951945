const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'development') {
  console.log = () => {};
}

const app_config = {
  development: {
    fl_key: 'FLWPUBK_TEST-78c22655d96a8004fd223ac90337bba3-X'
  },
  testing: {
    fl_key: 'FLWPUBK-90e273cfab39d7a8b8cc6213411f1424-X'
  },
  staging: {
    fl_key: 'FLWPUBK-90e273cfab39d7a8b8cc6213411f1424-X'
  },
  production: {
    fl_key: 'FLWPUBK-90e273cfab39d7a8b8cc6213411f1424-X'
  }
};

export default app_config[REACT_APP_ENV];
