export const refillWalletValidationConfig = {
  currency: {
    required: true
  },
  credits_to_buy: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value && Number(value) < 1000) {
        result.message = 'Credits must be at least 1000.';
      } else result.is_valid = true;
      return result;
    }
  },
  expiration_date: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };

      const date = new Date();
      const min_timestamp = date.setMonth(date.getMonth() + 1);
      if (value && Date.parse(value) < min_timestamp) {
        result.message = 'Date cannot be less than a month.';
      } else result.is_valid = true;
      return result;
    }
  }
};
