import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useWebPushLogService } from 'hooks/messaging/logs/webpush';
import React, { useEffect, useState } from 'react';

export const WebPushMessageStatBar = ({ id }) => {
  const { fetchWebPushLogs } = useWebPushLogService();

  const [logs, setLogs] = useState({});

  useEffect(() => {
    if (!id) return;
    Promise.all([
      fetchWebPushLogs({ query_string: `resource_id=${id}&status=sent&count=1` }),
      fetchWebPushLogs({ query_string: `resource_id=${id}&status=opened&count=1` })
    ]).then(([{ size: sent }, { logs: opened }]) => {
      setLogs((curr_logs) => ({
        ...curr_logs,
        sent,
        opened
      }));
    });
  }, [id]);

  return (
    <>
      <SlidingStatBar>
        <PrimaryStatCard
          main_stat={{
            label: 'Sent',
            value: logs.sent,
            color: 'var(--blue-primary)'
          }}
          bottom_stat={{ label: '-', value: '-' }}
        />
        <PrimaryStatCard
          main_stat={{
            label: 'Opened',
            value: logs.opened,
            color: 'var(--blue-primary)'
          }}
          bottom_stat={{ label: '-', value: '-' }}
        />
      </SlidingStatBar>
    </>
  );
};
