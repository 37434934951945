import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as MailboxService from 'services/users/mailbox';

export const useMailboxService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchMailboxes = async (options = {}) => {
    const { error, payload } = await MailboxService.read({ ...options, token });
    if (error) {
      return { mailboxes: [], size: 0, error: 'Unable to fetch mailboxes at this time.' };
    }

    const {
      data: mailboxes,
      meta: { size }
    } = payload;
    return { mailboxes, size, error: null };
  };

  const initiateVerification = async (options = {}) => {
    const { error, payload } = await MailboxService.initiate_verification({ ...options, token });
    if (error) {
      toast.error('Unable to verify email at this time.');
      return { mailbox: null };
    }

    return { mailbox: payload };
  };

  const verifyMailbox = async (id, options = {}) => {
    const { error } = await MailboxService.verify(id, { ...options, token });
    if (error) {
      toast.error(error);
      return false;
    }

    toast.success('Verification successful.');
    return true;
  };

  return {
    fetchMailboxes,
    initiateVerification,
    verifyMailbox
  };
};
