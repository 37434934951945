import { GridRow } from 'app/layouts/grid';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useEventLogService } from 'hooks/automation/eventlog';
import { useNeuronService } from 'hooks/automation/neuron';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const EventStatBar = () => {
  const { event_code } = useParams();
  const { tenant_id } = useSelector((store) => store.user_data);
  const { fetchEventLogs } = useEventLogService();
  const { fetchNeurons } = useNeuronService();
  const { fetchSteps } = useAutomationStepService();

  const [stats, setStats] = useState({});

  const fetchWorkflowCount = async (event_code, workflow_map = {}, page = 0) => {
    const { steps } = await fetchSteps({
      query_string: `event=${event_code}&return_only=template_id&page=${page}&population=1000`
    });

    if (!steps.length) return Object.keys(workflow_map).length;

    (steps || []).forEach((step) => {
      workflow_map[step.template_id] = 1;
    });

    return await fetchWorkflowCount(event_code, workflow_map, page + 1);
  };

  useEffect(() => {
    const code = `${event_code}:${tenant_id}`;
    Promise.all([
      fetchEventLogs({ query_string: `code=${code}&count=1` }),
      fetchNeurons({ query_string: `event=${code}&count=1` }),
      fetchWorkflowCount(code, {})
    ]).then(([{ size: invocations }, { size: neurons }, workflows]) => {
      setStats((curr_stats) => ({ ...curr_stats, invocations, neurons, workflows }));
    });
  }, [event_code]);

  return (
    <>
      <GridRow num_of_columns={1}>
        <SlidingStatBar>
          <PrimaryStatCard
            main_stat={{ label: 'Unique Invocations', value: stats.invocations }}
            bottom_stat={{ label: '-', value: '-' }}
          />
          <PrimaryStatCard
            main_stat={{ label: 'Linked Workflows', value: stats.workflows }}
            bottom_stat={{ label: '-', value: '-' }}
          />
          <PrimaryStatCard
            main_stat={{ label: 'Linked Neurons', value: stats.neurons }}
            bottom_stat={{ label: '-', value: '-' }}
          />
        </SlidingStatBar>
      </GridRow>
    </>
  );
};
