import { PrimaryStatCard } from 'app/shared/cards';
import { getMonthTimeRange } from 'app/shared/utils/date';
import { useTransactionalService } from 'hooks/transactional';
import { useMailLogService } from 'hooks/maillog';
import { useMailActionService } from 'hooks/mailaction';
import React, { useEffect, useState } from 'react';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';

export const TransactionalStatBar = () => {
  const { start, end } = getMonthTimeRange();
  const { fetchTransactionals } = useTransactionalService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchMailActions } = useMailActionService();

  const [transactional_count, setTransactionalCount] = useState({
    all_time: 0,
    monthly: 0
  });

  const [emails_count, setEmailsCount] = useState({
    all_time: 0,
    monthly: 0
  });

  const [emails_opens, setEmailOpens] = useState({
    all_time: 0,
    monthly: 0
  });

  const [link_clicks, setLinkClicks] = useState({
    all_time: 0,
    monthly: 0
  });
  useEffect(() => {
    fetchTransactionals({ query_string: `count=1` }).then(({ error, size }) => {
      if (error) toast.error(error);
      setTransactionalCount((curr_count) => ({
        ...curr_count,
        all_time: size
      }));
    });
    fetchTransactionals({ query_string: `time_stamp=${start}~${end}&count=1` }).then(
      ({ error, size }) => {
        if (error) toast.error(error);

        setTransactionalCount((curr_count) => ({
          ...curr_count,
          monthly: size
        }));
      }
    );
    fetchMailLogs({ query_string: `class=transactional&count=1` }).then(({ size, error }) => {
      if (error) {
        toast.error(error);
        return;
      }
      setEmailsCount((curr_count) => ({
        ...curr_count,
        all_time: size
      }));
    });
    fetchMailLogs({ query_string: `class=transactional&time_stamp=${start}~${end}&count=1` }).then(
      ({ size, error }) => {
        if (error) {
          toast.error(error);
          return;
        }
        setEmailsCount((curr_count) => ({
          ...curr_count,
          monthly: size
        }));
      }
    );

    fetchMailActions({ query_string: `resource_type=transactional&action=opened&count=1` }).then(
      ({ error, size }) => {
        if (error) return toast.error(error);
        setEmailOpens((curr_count) => ({
          ...curr_count,
          all_time: size
        }));
      }
    );
    fetchMailActions({
      query_string: `resource_type=transactional&action=opened&time_stamp=${start}~${end}&count=1`
    }).then(({ size, error }) => {
      if (error) return toast.error(error);
      setEmailOpens((curr_count) => ({
        ...curr_count,
        monthly: size
      }));
    });

    fetchMailActions({
      query_string: `resource_type=transactional&action=clicked_link&count=1`
    }).then(({ error, size }) => {
      if (error) return toast.error(error);

      setLinkClicks((curr_count) => ({
        ...curr_count,
        all_time: size
      }));
    });
    fetchMailActions({
      query_string: `resource_type=transactional&action=clicked_link&time_stamp=${start}~${end}&count=1`
    }).then(({ error, size }) => {
      if (error) return toast.error(error);

      setLinkClicks((curr_count) => ({
        ...curr_count,
        monthly: size
      }));
    });
  }, []);

  const statDetails = [
    {
      main_stat: {
        label: 'Total Transactionals',
        value: transactional_count.all_time
      },
      bottom_stat: { label: 'This Month', value: transactional_count.monthly }
    },
    {
      main_stat: {
        label: 'Total Emails Sent',
        value: emails_count.all_time
      },
      bottom_stat: { label: 'This Month', value: emails_count.monthly }
    },
    {
      main_stat: {
        label: 'Total Emails Opens',
        value: emails_opens.all_time
      },
      bottom_stat: { label: 'This Month', value: emails_opens.monthly }
    },
    {
      main_stat: {
        label: 'Total Link Clicks',
        value: link_clicks.all_time
      },
      bottom_stat: { label: 'This Month', value: link_clicks.monthly }
    }
  ];
  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
    </>
  );
};
