import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import Spinner from 'app/shared/spinners/spinner-15/spinner-15';
import { truncateText } from 'app/shared/utils/general';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import { useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { serializeAction } from './serializer';
import styles from './widget.module.css';
import { toast } from 'react-toastify';

export const AutomationExitWidget = () => {
  const { id: template_id } = useParams();
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);
  const steps_in_store = useSelector((store) => store.steps);

  const { deleteStep, fetchSteps } = useAutomationStepService();
  const { fetchResources } = useResourceQuery();

  const [is_loading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (isNaN(template_id)) return;

    setIsLoading(() => true);
    fetchSteps({ query_string: `template_id=${template_id}&type=exit` })
      .then(async ({ steps, error }) => {
        if (error) return toast.error(error);
        const responses = await Promise.all(
          steps.map((step) => {
            const [resource_class, resource_id] = step.source.split(':');
            return fetchResources(resource_class, '', {
              query_string: `id=${resource_id}&return_only=name,title,link`
            });
          })
        );

        const parsed_steps = steps.map((step, index) => {
          let resource_name;
          if (responses[index]) {
            const { error, resources } = responses[index];
            if (error) throw new Error(error);

            const [resource] = resources;
            resource_name = resource.name || resource.title || resource.link;
          }

          return { ...step, resource_name };
        });
        setSteps(() => parsed_steps);
      })
      .finally(() => {
        setIsLoading(() => false);
      });
  }, [steps_in_store]);

  const handleAdd = () => {
    setWidgetToCreate(() => 'trigger');
    toggleStepModal({
      parents: [],
      show: true,
      source_widget: 'action',
      template_id,
      type: 'exit'
    });
  };

  return (
    <BasicInfoSection
      title="Exit triggers"
      icon={
        <Button
          icon_name="add"
          text="Add"
          className={styles.exitBtn}
          disabled={isNaN(template_id)}
          onClick={handleAdd}
        />
      }
    >
      {steps && steps.length ? (
        <div className={styles.exitTriggers}>
          {steps.map((step) => (
            <div key={step.id} className={styles.exitTriggerItem}>
              <span>
                When a contact {serializeAction(step.event, truncateText(step.resource_name, 35))}
              </span>
              <span className={styles.icon}>
                <Icon name="trash" onClick={() => deleteStep(step.id)} />
              </span>
            </div>
          ))}
        </div>
      ) : is_loading ? (
        <Spinner />
      ) : (
        <span>No exit triggers found.</span>
      )}
    </BasicInfoSection>
  );
};
