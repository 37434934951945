import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { MainForm } from './main';
import { SidebarForm } from './sidebar';
import { EditorLayout } from 'app/member/editors/layout';

import useValidator from 'hooks/validator';
import { transactionalValidationConfig } from 'app/shared/utils/form/validation/transactional';
import { useTransactionalService } from 'hooks/mailing/transactional';
import { useMailboxService } from 'hooks/users/mailbox';
import { toast } from 'react-toastify';

export const CreateTransactional = () => {
  const { id: transactional_id } = useParams();
  const history = useNavigate();
  const { tenant_id } = useSelector((state) => state.user_data);
  const { fetchMailboxes } = useMailboxService();
  const { createTransactional, fetchTransactionalById, searchTransactionals, updateTransactional } =
    useTransactionalService();
  const { errors, validateField, form_is_valid } = useValidator(transactionalValidationConfig);

  const [transactional, setTransactional] = useState({ tenant_id });
  const [mailbox, setMailbox] = useState({ emails: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isNaN(transactional_id)) {
      fetchTransactionalById(transactional_id).then(({ transactional }) => {
        Object.keys(transactional).forEach((key) => {
          validateField(key, transactional[key]);
        });
        setTransactional(transactional);
      });
    }

    fetchMailboxes().then(({ mailboxes, error }) => {
      if (error) toast.error(error);

      setMailbox(mailboxes[0] || { emails: [] });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttributeChange = (attr_key, attr_value) => {
    validateField(attr_key, attr_value);
    setTransactional((transactional) => ({
      ...transactional,
      [attr_key]: attr_value
    }));
  };

  const handleTransactionalCreation = async (data) => {
    setLoading(true);
    transactional_id
      ? await updateTransactional(transactional_id, { data })
      : await createTransactional({ data });
    setLoading(false);
    history('/transactionals');
  };

  const handleSave = async () => {
    const data = { ...transactional, tenant_id };
    if (loading) return;

    const { transactionals } = await searchTransactionals('code', data.code);
    const t_id = isNaN(transactional_id) ? 0 : Number(transactional_id);
    const match = transactionals.find((t) => t.code === data.code && t.id !== t_id);
    if (match) return toast.error('Transactional code already taken.');
    await handleTransactionalCreation(data);
  };

  const Main = <MainForm value={transactional.body} onEditorChange={handleAttributeChange} />;
  const Sidebar = (
    <SidebarForm
      transactional={transactional}
      onChange={handleAttributeChange}
      mailbox={mailbox}
      setMailbox={setMailbox}
      errors={errors}
    />
  );

  return (
    <EditorLayout
      main={Main}
      sidebar={Sidebar}
      onSave={handleSave}
      disable={!form_is_valid || loading}
    />
  );
};
