import { toDateString } from '@go-mailer/jarvis/lib/utilitiy/date';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { useWebPushService } from 'hooks/messaging/push/web';
import { useAudienceService } from 'hooks/users/audience';
import React, { useEffect, useState } from 'react';

export const WebPushBasicInfo = ({ id }) => {
  const { fetchAudiences } = useAudienceService();
  const { fetchPushMessages } = useWebPushService();

  const [audiences, setAudiences] = useState([]);
  const [message_data, setMessageData] = useState({});

  useEffect(() => {
    if (!id) return;
    fetchPushMessages({ query_string: `id=${id}` }).then(({ messages: [message] }) => {
      if (!message) return;
      setMessageData(message);
      if (message.type === 'campaign') {
        fetchAudiences({ query_string: `id=${message.audiences.join('')}` }).then(
          ({ audiences: auds }) => {
            setAudiences(auds);
          }
        );
      }
    });
  }, [id]);

  return (
    <>
      <BasicInfoSection title="Basic Details">
        <DetailItem title="title">{message_data.title}</DetailItem>
        <DetailItem title="sender ID">{message_data.sender_id}</DetailItem>
        <DetailItem title="Message">{message_data.message}</DetailItem>
        <DetailItem title="Type">{message_data.type}</DetailItem>
        {message_data.type === 'campaign' && (
          <>
            <DetailItem title="status">{message_data.status}</DetailItem>
            <DetailItem title="Audiences">
              {audiences.map((aud) => (
                <div key={aud.id}>{aud.name}</div>
              ))}
            </DetailItem>
            <DetailItem title="schedule">{toDateString(message_data.schedule.date)}</DetailItem>
          </>
        )}
      </BasicInfoSection>
    </>
  );
};
