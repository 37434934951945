import { Icon } from 'assets/icons';
import React from 'react';
import { toast } from 'react-toastify';
import styles from './code.module.css';

export const CodeWrapper = ({ text }) => {
  const handleCopy = (snippet) => {
    navigator.clipboard
      .writeText(snippet)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.codeWrapper}>{text}</div>
        <Icon name="copy" onClick={() => handleCopy(text)} />
      </div>
    </>
  );
};
