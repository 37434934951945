import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TenantService from 'services/iam/tenant';

export const useTenantService = () => {
  const { tenant_id, token } = useSelector((store) => store.user_data);

  const createTenant = async (options = {}) => {
    const { error, payload: tenant } = await TenantService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create organization at this time');
      return { tenant: {} };
    }

    toast.success('tenant created successfully.');
    return { tenant };
  };

  const fetchMemberships = async (options = {}) => {
    const { error, payload } = await TenantService.readMemberships(tenant_id, {
      ...options,
      token
    });
    if (error) {
      return { memberships: [], size: 0, error: 'Unable to fetch memberships at this time.' };
    }

    const {
      data: memberships,
      meta: { size }
    } = payload;
    return { memberships, size, error: null };
  };

  const fetchTenant = async (id, options = {}) => {
    const { error, payload } = await TenantService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch organization at this time.');
      return { tenant: {} };
    }

    return { tenant: payload };
  };

  const fetchTenants = async (options = {}) => {
    const { error, payload } = await TenantService.read({ ...options, token });
    if (error) {
      return { tenants: [], size: 0, error: 'Unable to fetch organizations at this time.' };
    }

    const {
      data: tenants,
      meta: { size }
    } = payload;
    return { tenants, size, error: null };
  };

  const searchTenants = async (keys, keyword, options = {}) => {
    const { error, payload } = await TenantService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch organizations at this time.');
      return { tenants: [], size: 0 };
    }

    const {
      data: tenants,
      meta: { size }
    } = payload;
    return { tenants, size };
  };

  const updateTenant = async (id, options = {}) => {
    const { error, payload: tenant } = await TenantService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update organization at this time.');
      return { tenant: {} };
    }

    return { tenant };
  };

  return { createTenant, fetchMemberships, fetchTenant, fetchTenants, searchTenants, updateTenant };
};
