import cx from 'classnames';
import { Icon } from 'assets/icons';
import { TreeContext } from 'contexts/automation';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import styles from './widget.module.css';
import { serializeDelay } from './serializer';

export const AutomationDelayWidget = (props) => {
  const { id: source_handle_id, isConnectable, data } = props;
  const { deleteStep } = useAutomationStepService();
  const steps_in_store = useSelector((store) => store.steps);
  const { setWidgetToCreate, toggleStepModal } = useContext(TreeContext);

  const [rule, setRule] = useState({});

  useEffect(() => {
    if (!data || !data.rules) return;
    setRule(data.rules[0]);
  }, [data]);

  const button_disabled = useMemo(() => {
    return !!Object.values(steps_in_store).find(
      (step) => step.parents && step.parents.includes(data.id)
    );
  }, [steps_in_store]);

  const handleAdd = () => {
    if (button_disabled) return;
    setWidgetToCreate(() => '');
    toggleStepModal({
      branch_value: data.branch_value,
      template_id: data.template_id,
      parents: [data.id],
      source: data.source,
      show: true,
      rules: [rule],
      source_widget: 'delay'
    });
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    await deleteStep(data.id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setWidgetToCreate('delay');
    toggleStepModal({
      template_id: data.template_id,
      parents: data.parents || [],
      show: true,
      mode: 'edit',
      data
    });
  };

  return (
    <div className={styles.widget}>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div className={cx(styles.wrapper, styles.grey)}>
        <div className={styles.content} onClick={handleEdit}>
          <span>
            Wait for <b>{serializeDelay(rule)}</b>
          </span>
          <span className={styles.icon}>
            <Icon name="trash" onClick={handleDelete} />
          </span>
        </div>
      </div>
      <span id={styles.vertLine} />
      <span className={cx(styles.addBtn, button_disabled && styles.disabledBtn)}>
        <Icon name="add" onClick={handleAdd} />
      </span>
      <Handle
        type="source"
        position={Position.Bottom}
        id={source_handle_id}
        isConnectable={isConnectable}
      />
    </div>
  );
};
