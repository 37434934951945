import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as StepsService from 'services/steps';
import {
  addManyStepsToStore,
  addOneStepToStore,
  removeOneStepFromStore,
  updateStepInStore
} from 'store/actions/step';

export const useCreateStep = () => {
  const { token } = useSelector((store) => store.user_data);
  return async (data, options = {}) => {
    const { error, payload } = await StepsService.create({ ...options, data, token });
    if (error) {
      toast.error('Unable to create automation step at this time.');
      return null;
    }

    toast.success('Step created successfully');
    return payload;
  };
};

export const useDeleteStep = () => {
  const { token } = useSelector((store) => store.user_data);
  return async (id, data, options = {}) => {
    const { error, payload } = await StepsService.deleteById(id, { ...options, data, token });
    if (error) {
      toast.error('Unable to delete automation step at this time.');
      return null;
    }

    toast.success('Step deleted successfully');
    return { ...data, ...payload };
  };
};

export const useFetchSteps = () => {
  const { token } = useSelector((store) => store.user_data);
  return async (options = {}) => {
    const { error, payload } = await StepsService.read({ ...options, token });
    if (error) return { error: 'Unable to load steps at this time.', payload };
    return { payload, error: null };
  };
};

export const useUpdateStep = () => {
  const { token } = useSelector((store) => store.user_data);
  return async (id, data, options = {}) => {
    const { error, payload } = await StepsService.updateById(id, { ...options, data, token });
    if (error) {
      toast.error('Unable to update automation step at this time.');
      return null;
    }

    toast.success('Step updated successfully');
    return { ...data, ...payload };
  };
};

export const useAutomationStepService = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.user_data);
  return {
    addOneToStore: (step = {}) => {
      dispatch(addOneStepToStore(step));
    },
    createStep: async (data, options = {}) => {
      const { error, payload } = await StepsService.create({ ...options, data, token });
      if (error) {
        toast.error('Unable to create automation step at this time.');
        return null;
      }

      toast.success('Step created successfully');
      dispatch(addOneStepToStore(payload));
      return payload;
    },
    deleteStep: async (id, options = {}) => {
      const { error, payload } = await StepsService.deleteById(id, { ...options, token });
      if (error) {
        toast.error('Unable to delete automation step at this time.');
        return null;
      }

      toast.success('Step deleted successfully');
      payload.forEach((step_id) => dispatch(removeOneStepFromStore(step_id)));
      return { ...payload };
    },
    fetchSteps: async (options = {}) => {
      const { error, payload } = await StepsService.read({ ...options, token });
      if (error) return { error: 'Unable to load steps at this time.', data: null };
      const {
        data: steps,
        meta: { size }
      } = payload;
      return { steps, size, error: null };
    },
    updateStep: async (id, data, options = {}) => {
      const { error, payload } = await StepsService.updateById(id, { ...options, data, token });
      if (error) {
        toast.error('Unable to update automation step at this time.');
        return null;
      }

      toast.success('Step updated successfully');
      dispatch(updateStepInStore({ id, ...data, payload }));
      return { ...data, ...payload };
    }
  };
};

export const useStepStoreActions = () => {
  const dispatch = useDispatch();
  return {
    addManySteps: (steps = []) => {
      dispatch(addManyStepsToStore(steps));
    },
    addOneStep: (step = {}) => {
      dispatch(addOneStepToStore(step));
    },
    removeStep: (id) => {
      dispatch(removeOneStepFromStore(id));
    },
    updateStep: (step) => {
      dispatch(updateStepInStore(step));
    }
  };
};
