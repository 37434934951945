import { useSelector } from 'react-redux';
import * as TaxService from 'services/tax';

export const useTaxService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchTaxes = async (options = {}) => {
    const { error, payload } = await TaxService.read({ ...options, token });
    if (error) {
      return { taxes: [], size: 0, error: 'Unable to fetch taxes at this time.' };
    }

    const {
      data: taxes,
      meta: { size }
    } = payload;
    return { taxes, size, error: null };
  };

  return {
    fetchTaxes
  };
};
