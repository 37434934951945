import React, { useEffect } from 'react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { PasswordConfiguration } from './password';
import { MultiFactorAuthentication } from './auth-configurations';
import { setPageTitle } from 'store/actions/header';
import { useDispatch } from 'react-redux';
import useScreenSize from 'hooks/size';
import { Spacer } from 'app/layouts/generic';

export const SecuritySetting = () => {
  const { isDesktop } = useScreenSize();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Security', path: '/settings/security' }
      ])
    );
  }, []);

  return (
    <>
      <GridRow num_of_columns={isDesktop ? 4 : 2}>
        {isDesktop && <GridColumn />}
        <GridColumn span={2}>
          <MultiFactorAuthentication />
          <Spacer multiple={3} />
          <PasswordConfiguration />
        </GridColumn>
      </GridRow>
      {isDesktop && <GridColumn />}
    </>
  );
};
