import {
  ADD_MANY_AUTOMATIONS_TO_STORE,
  ADD_ONE_AUTOMATION_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_AUTOMATIONS_TO_STORE
} from 'store/actions/automation';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_MANY_AUTOMATIONS_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (automations, automation) => ({
            ...automations,
            [automation.id]: automation
          }),
          {}
        )
      };
    case ADD_ONE_AUTOMATION_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (automations, automation) => ({
            ...automations,
            [automation.id]: automation
          }),
          {}
        )
      };
    case LOAD_AUTOMATIONS_TO_STORE:
      return {
        ...payload.reduce(
          (automations, automation) => ({
            ...automations,
            [automation.id]: automation
          }),
          {}
        )
      };
    default:
      return state;
  }
};
