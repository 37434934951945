import { useSelector } from 'react-redux';
import * as WebPushLogService from 'services/messaging/logs/webpush';

export const useWebPushLogService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchWebPushLogs = async (options = {}) => {
    const { error, payload } = await WebPushLogService.read({ ...options, token });
    if (error) {
      return {
        logs: [],
        size: 0,
        error: 'Unable to fetch web push logs at this time.'
      };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { logs, size, error: null };
  };

  const searchWebPushLogs = async (keys, keyword, options = {}) => {
    const { error, payload } = await WebPushLogService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, logs: [], size: 0 };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { error, logs, size };
  };

  return { fetchWebPushLogs, searchWebPushLogs };
};
