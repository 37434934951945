import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as BillingService from 'services/billing';

export const useBillingService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createBillingInfo = async (options = {}) => {
    const { error, payload } = await BillingService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchBillingInfos = async (options = {}) => {
    const { error, payload } = await BillingService.read({ ...options, token });
    if (error) {
      return { error, billing_infos: null };
    }

    const {
      data: billing_infos,
      meta: { size }
    } = payload;
    return { billing_infos, size, error: null };
  };

  const upsertBillingInfo = async (options = {}) => {
    const { error, payload } = await BillingService.upsert({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  return { createBillingInfo, fetchBillingInfos, upsertBillingInfo };
};
