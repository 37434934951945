import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { useMembershipService } from 'hooks/iam/membership';
import { useRoleService } from 'hooks/iam/role';
import useValidator from 'hooks/validator';
import React, { useEffect, useState } from 'react';
import { UserInvitationSchema } from './schema';
import { toast } from 'react-toastify';

export const InvitationForm = ({ onSave = () => {}, closeModal }) => {
  const { inviteUser } = useMembershipService();
  const { fetchRoles } = useRoleService();
  const { errors, form_is_valid, validateField } = useValidator(UserInvitationSchema);

  const [form_data, setFormData] = useState({});
  const [is_saving, setIsSaving] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selected_role, setSelectedRole] = useState({});

  useEffect(() => {
    fetchRoles().then(({ roles, error }) => {
      if (error) return toast.error(error);

      setRoles(() => roles.map((role) => ({ ...role, value: role.id, label: role.name })));
    });
  }, []);

  useEffect(() => {
    Object.keys(form_data).forEach((key) => validateField(key, form_data[key]));
  }, [form_data]);

  const handleInputChange = (key, value) => {
    setFormData((curr_data) => ({ ...curr_data, [key]: value }));
  };

  const handleRoleChange = (selection) => {
    setSelectedRole(selection);
    setFormData((curr_data) => ({ ...curr_data, role: selection.value }));
  };

  const submit = async () => {
    setIsSaving(() => true);
    await inviteUser({ data: { ...form_data, role_id: form_data.role } });
    setIsSaving(() => false);
    onSave();
    closeModal();
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Email address">
            <SimpleInput
              value={form_data.email}
              onInput={(v) => handleInputChange('email', v)}
              error={errors.email}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Role">
            <MultiSelect
              value={selected_role}
              onChange={handleRoleChange}
              error={errors.role}
              options={roles}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button text="Send Invite" onClick={submit} disabled={!form_is_valid || is_saving} />
        </GridColumn>
      </GridRow>
    </>
  );
};
