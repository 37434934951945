/** */

export const ADD_AUD_CONTACTS = 'ADD CONTACTS TO AN AUDIENCE';
export const ADD_SEARCH_RESULTS_TO_STORE = 'ADD AUDIENCE SEARCH CONTACTS TO STORE';
export const CLEAR_AUD_CONTACTS = 'CLEAR AUDIENCE CONTACTS';
export const REM_AUD_CONTACTS = 'REMOVE CONTACTS FROM AN AUDIENCE';

export const addManyAudContacts = (payload) => ({
  type: ADD_AUD_CONTACTS,
  payload
});

export const addAudContactsSearchResults = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const clearAudContacts = (payload) => ({
  type: CLEAR_AUD_CONTACTS,
  payload
});

export const removeAudContacts = (payload) => ({
  type: REM_AUD_CONTACTS,
  payload
});
