import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { MultiSelect } from 'app/shared/select';
import { useDispatch, useSelector } from 'react-redux';
import { set_process } from 'store/actions/process';
import { isEmailValid } from 'app/shared/utils/input';
import { useFeatureFlag } from 'hooks/go-flags';
import GmModal from 'app/shared/modal/modal';
import EmailVerificationForm from 'app/shared/verify-email/verify-email';
import * as MailboxService from 'services/mailbox';
import * as FileService from 'services/file';
import { SimpleInput } from 'app/shared/input';
import cx from 'classnames';
import { TextArea } from 'app/shared/input/textarea';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './sidebar.module.css';
import { Checkbox } from 'app/shared/input/checkbox';
import { Icon } from 'assets/icons';
import { isGeneralEmail } from 'app/shared/utils/general';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { DetailItem } from 'app/shared/info-section/item';
import { GridColumn, GridRow } from 'app/layouts/grid';

export const SidebarForm = ({
  campaign,
  audiences = [],
  testAudience,
  isTestAudienceSelected,
  mailbox,
  setMailbox,
  onChange,
  draft_status,
  errors
}) => {
  const dispatch = useDispatch();
  const { token, tenant_id } = useSelector((state) => state.user_data);
  const { email_verification } = useSelector((state) => state.processes);
  const [is_verifying, setIsVerifying] = useState(false);
  const [show_verification_modal, setShowVerificationModal] = useState(false);
  const [sender_eamil_is_verified, setSenderEmailVerificationStatus] = useState(true);
  const [repeat_count, setRepeatCount] = useState(0);
  const [repeat_interval, setRepeatInterval] = useState(0);
  const [date, setDate] = useState();

  const [is_repetition_permitted, setRepetitionPermitted] = useState(false);
  const { isFeatureAllowed } = useFeatureFlag();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [show_generic_email_modal, setShowGenericEmailModal] = useState(false);
  const [verify_generic_email, setVerifyGenericEmail] = useState(false);

  const audienceCheckBoxClass = `${styles.audienceCheckBox} ${
    isTestAudienceSelected ? styles.audienceChecked : ''
  }`;

  useEffect(() => {
    if (!email_verification) return;

    setSenderEmailVerificationStatus(true);
    mailbox.emails.push(campaign.sender_email);
    dispatch(set_process('email_verification', false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_verification, dispatch]);

  useEffect(() => {
    if (!campaign.sender_email) setSenderEmailVerificationStatus(true);
    processSenderEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, mailbox]);
  useEffect(() => {
    if (campaign?.attachments) setFileName(campaign.attachments[0]?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const get_selected_audiences = (audience_list) => {
    const audienceArray = [];
    for (const audience of audience_list) {
      audiences.find((aud) => {
        return aud.id === audience ? audienceArray.push(aud) : '';
      });
    }
    return audienceArray;
  };

  useEffect(() => {
    isFeatureAllowed('Feature.CampaignRepetition', { tenant_id }).then(({ is_permitted }) =>
      setRepetitionPermitted(is_permitted)
    );
  }, []);

  const [selected_audiences, setSelectedAudiences] = useState([]);
  useEffect(() => {
    if (campaign.mailing_lists && audiences) {
      const audiences = get_selected_audiences(campaign.mailing_lists);
      setSelectedAudiences(
        audiences?.map((audience) => ({
          label: audience.name,
          value: audience.id
        }))
      );
      return;
    }

    setSelectedAudiences([]);
  }, [audiences, campaign]);

  const handleAudienceSelection = (audiences) => {
    setSelectedAudiences(audiences);
    onChange('mailing_lists', audiences?.map((audience) => audience.value) || []);
  };

  const handleTestAudienceSelection = () => {
    if (!testAudience) {
      toast.error('No test audience found');
      return;
    }
    if (!isTestAudienceSelected) {
      const { name, id } = testAudience;
      setSelectedAudiences([{ label: name, value: id }]);
      onChange('mailing_lists', [id]);
    } else {
      setSelectedAudiences([]);
      onChange('mailing_lists', []);
    }
  };

  const handleDateChange = (value) => {
    onChange('schedule', {
      date: value
    });
    setDate(value);
  };

  useEffect(() => {
    if (verify_generic_email && campaign.sender_email) {
      handleEmailVerification();
    }
  }, [verify_generic_email]);

  const handleEmailVerification = async () => {
    try {
      setIsVerifying(true);
      if (isGeneralEmail(campaign.sender_email) && !verify_generic_email) {
        setShowGenericEmailModal(true);
        return;
      }
      const response = await MailboxService.initiate_verification({
        token,
        data: { email: campaign.sender_email }
      });
      const { error, payload } = response;

      if (error) {
        toast.error('Unable to verify email. Try again later.');
        return;
      }

      setMailbox(payload);
      setShowVerificationModal(true);
    } finally {
      setIsVerifying(false);
      setVerifyGenericEmail(false);
    }
  };

  const handleGenericEmailVerification = async (permitted) => {
    if (permitted) {
      setVerifyGenericEmail(true);
    }
    setShowGenericEmailModal(false);
  };

  const processSenderEmail = () => {
    if (!isEmailValid(campaign.sender_email)) return;

    if (!mailbox.emails.includes(campaign.sender_email)) {
      setSenderEmailVerificationStatus(false);
      return;
    }

    setSenderEmailVerificationStatus(true);
  };

  const handleItemSelection = () => {
    const hide_unsubscription_link = campaign?.hide_unsubscription_link;
    onChange('hide_unsubscription_link', !hide_unsubscription_link);
  };

  const handleFileDelete = async () => {
    onChange('attachments', []);
    setFileName('');
    toast.success('File Removed.');
  };

  const handleFileSelection = async (e) => {
    if (!e.target.files) return;
    const selected_file = e.target.files[0];

    if (e.target.files && selected_file.size <= 5 * 1024 * 1024) {
      const formData = new FormData();
      formData.append('file', selected_file);

      setLoading(true);
      FileService.uploadFile({
        data: formData,
        token,
        headers: {
          'Content-Type': 'application/form-data'
        }
      })
        .then((response) => {
          const { error, payload } = response;
          if (error) {
            toast.error(error);
            return;
          }
          const name = selected_file.name;
          setFileName(name);
          onChange('attachments', [{ name, path: payload.location }]);
          toast.success('File Uploaded.');
        })
        .finally(() => setLoading(false));
    } else {
      toast.error('File size exceeds the limit of 5MB');
    }
  };

  return (
    <div className={styles.container}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Title" required>
            <SimpleInput
              className="gm-input"
              id="campaign_title"
              type="text"
              value={campaign.name}
              onInput={(e) => onChange('name', e)}
              error={errors.name}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Subject" required>
            <SimpleInput
              className="gm-input"
              id="campaign_subject"
              type="text"
              value={campaign.subject}
              onInput={(e) => onChange('subject', e)}
              error={errors.subject}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Sender's name" required>
            <SimpleInput
              className="gm-input"
              id="sender_name"
              type="text"
              value={campaign.sender_name}
              onInput={(e) => onChange('sender_name', e)}
              error={errors.sender_name}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Sender's email address" required>
            <MultiSelect
              options={(mailbox.emails || []).map((email) => ({
                label: email,
                value: email
              }))}
              onChange={(v) => onChange('sender_email', v.value)}
              value={{ value: campaign.sender_email, label: campaign.sender_email }}
              isCreatable
              error={errors.sender_email}
            />
          </DetailItem>
        </GridColumn>

        {!sender_eamil_is_verified && (
          <GridColumn>
            <div className="text-right">
              {is_verifying ? (
                <div>
                  {' '}
                  <i>Verifying...</i>
                </div>
              ) : (
                <p>
                  <b className="px-1 text-danger is-clickable" onClick={handleEmailVerification}>
                    Click to verify email address.
                  </b>
                </p>
              )}
            </div>
          </GridColumn>
        )}

        <GridColumn>
          <Checkbox
            checkboxClass={audienceCheckBoxClass}
            checked={isTestAudienceSelected}
            input_id="test_audience_id"
            label="Send to test audience?"
            labelClass={styles.testAudience}
            onClick={handleTestAudienceSelection}
          />
        </GridColumn>

        <GridColumn>
          <DetailItem title="Select Audience(s)" required>
            <MultiSelect
              options={audiences
                ?.filter((audience) => !audience.is_test_audience)
                .map((list) => ({
                  label: list.name,
                  value: list.id
                }))}
              onChange={handleAudienceSelection}
              value={selected_audiences}
              isMulti
              disabled={isTestAudienceSelected}
              error={errors.mailing_lists}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Preview Text">
            <TextArea
              className="gm-input"
              id="campaign_preview_text"
              value={campaign.preview_text}
              onInput={(e) => onChange('preview_text', e)}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <section className={`${styles.picker}`}>
            <DetailItem title="Schedule" required>
              <DatePicker
                isClearable
                showTimeSelect
                placeholderText={date}
                dateFormat="MMMM d, yyyy h:mmaa"
                className=" gm-input"
                minDate={new Date()}
                selected={campaign?.schedule?.date && new Date(campaign?.schedule?.date)}
                onChange={(e) => handleDateChange(e)}
                popperClassName={styles.date_picker}
              />
              {errors.schedule && (
                <span className={cx(styles.error, 'mb-1 d-inline-block')}>{errors.schedule}</span>
              )}
            </DetailItem>
          </section>
        </GridColumn>

        <GridColumn>
          <Checkbox
            checked={campaign?.hide_unsubscription_link}
            input_id="hide_subscription_link"
            label="Hide unsubscription link"
            onClick={handleItemSelection}
          />
        </GridColumn>

        <GridColumn>
          <div className={`${styles.pdf}`}>
            {fileName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="attach_pdf" loading={loading} />
                <span onClick={handleFileSelection} className={styles.file}>
                  {fileName}
                </span>
                <span className={styles.btn}>
                  <Icon name="trash" onClick={handleFileDelete} />
                </span>
              </div>
            ) : (
              <>
                <input
                  id="input-file-upload"
                  type="file"
                  accept=".pdf"
                  onChange={handleFileSelection}
                  onClick={(e) => (e.target.value = null)}
                />
                <label htmlFor="input-file-upload">
                  <Icon name="attach_pdf" loading={loading} />
                  <span onClick={handleFileSelection} className={`${styles.attach} form-group`}>
                    Attach PDF
                  </span>
                </label>
                {loading && (
                  <div className="progress">
                    <div
                      className={`progress-bar ${
                        loading ? 'progress-bar-striped progress-bar-animated' : ''
                      }`}
                      role="progressbar"
                      style={{ width: '100%' }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
        </GridColumn>

        <GridColumn>
          <div className="text-info">{draft_status}</div>
        </GridColumn>

        {is_repetition_permitted && (
          <GridColumn>
            <div className={`${styles.repetition}`}>
              <DetailItem title="Repeat Count">
                <SimpleInput
                  className="gm-input"
                  id="repeat_count"
                  type="text"
                  value={repeat_count}
                  onInput={(e) => setRepeatCount(e)}
                />
              </DetailItem>
              <DetailItem title="Repeat Interval (days)">
                <SimpleInput
                  className="gm-input"
                  id="repeat_interval"
                  type="text"
                  value={repeat_interval}
                  onInput={(e) => setRepeatInterval(e)}
                />
              </DetailItem>
            </div>
          </GridColumn>
        )}
      </GridRow>
      <GmModal
        title="Verify e-mail address"
        show_title={true}
        show_modal={show_verification_modal}
        onClose={() => setShowVerificationModal(false)}
      >
        <EmailVerificationForm
          email={campaign.sender_email}
          mailbox={mailbox}
          onClose={() => setShowVerificationModal(false)}
        />
      </GmModal>
      <ConfirmationDialog
        title="Verify generic email domain"
        message="You are about to verify a mailbox on a generic domain. Please note that your emails may not be sent."
        callback={handleGenericEmailVerification}
        is_open={show_generic_email_modal}
      />
    </div>
  );
};
