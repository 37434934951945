import React, { useEffect, useState } from 'react';
import './email-form.css';
import * as FileService from 'services/file';
import * as FootnoteService from 'services/footnote';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'app/shared/button';

function DragDropFile() {
  const { token, id: tenant_id } = useSelector((state) => state.user_data);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  useEffect(() => {
    FootnoteService.read({
      token,
      query_string: `tenant_id=${tenant_id}`
    }).then((response) => {
      const {
        payload: { data }
      } = response;

      if (data) {
        setFileDataURL(data[0].url);
      }
    });
  }, []);

  useEffect(() => {
    let fileReader = false;
    let isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFileForPreview(e.dataTransfer);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFileForPreview(e.target);
    }
  };

  const imageMimeType = /image\/(png|jpg|jpeg)/i;
  const setFileForPreview = (e) => {
    const file = e.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.error('Image mime type is not valid');
      return;
    }
    setFile(file);
  };
  const submit = () => {
    const data = new FormData();
    data.append('image', file);

    setLoading(true);
    FileService.uploadFootNote({ data, token })
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          toast.error(error);
          return;
        }
        const data = {
          url: payload.location,
          tenant_id
        };

        FootnoteService.create({
          data,
          token
        })
          .then((res) => {
            const { error } = res;
            if (error) {
              toast.error(error);
              return;
            }
            toast.success('Footnote Uploaded.');
          })
          .finally(() => setLoading(false));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className="upload-container ml-5 mt-5"
    >
      <h6>Email signature</h6>

      <form
        id="form-file-upload"
        encType="multipart/form-data"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="preview-box">{<img src={fileDataURL} />}</div>

        <input
          type="file"
          id="input-file-upload"
          onChange={handleChange}
          accept=".png, .jpg, .jpeg"
          onClick={(e) => (e.target.value = null)}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <p>
              Drag & drop file here Or &nbsp;
              <span className="upload-button">browse files</span>
            </p>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <div className="img-info">
        <small>Supported formats: JPG, JPEG, PNG</small> & <small>Maximum size: 5MB</small>
      </div>
      <Button
        text="Save changes"
        onClick={submit}
        className="w-50 mt-4 mb-4 ml-auto"
        loading={loading}
      />
    </div>
  );
}
export default DragDropFile;
