/** */
import { urls } from './_url';
import { apiGet, apiPost, apiDelete, apiPut } from './_http';

/** */
const base_url = `${urls.automata}/events`;

export const create = async (options = {}) => {
  return await apiPost(`${base_url}`, { ...options });
};

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};

export const search = async (keys, keyword, options = {}) => {
  const query_string = options.query_string
    ? `${options.query_string}&keyword=${keyword}`
    : `keyword=${keyword}`;
  return await apiGet(`${base_url}/search/${keys}`, {
    ...options,
    query_string
  });
};

export const deleteOne = async (id, options) => {
  return await apiDelete(`${base_url}/${id}`, { ...options });
};

export const deleteMany = async (options) => {
  return await apiDelete(`${base_url}`, { ...options });
};

export const updateById = async (id, options) => {
  return await apiPut(`${base_url}/${id}`, { ...options });
};
