import { urls } from './_url';
import { apiGet, apiPost } from './_http';

const base_url = `${urls.notification}/notifications`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const create = async (options = {}) => {
  return await apiPost(`${base_url}/trigger`, { ...options });
};
