import { useSelector } from 'react-redux';
import * as DraftService from 'services/draft';

export const useDraftService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createDraft = async (options = {}) => {
    const { error, payload } = await DraftService.create({ ...options, token });
    if (error) {
      return null;
    }

    return payload;
  };

  const deleteDraft = async (id, options = {}) => {
    const { error } = await DraftService.deleteById(id, { ...options, token });
    if (error) {
      return false;
    }

    return true;
  };

  const updateDraft = async (id, options = {}) => {
    const { error, payload } = await DraftService.updateById(id, { ...options, token });
    if (error) {
      return null;
    }

    return payload;
  };

  return {
    createDraft,
    deleteDraft,
    updateDraft
  };
};
