import React from 'react';
import cx from 'classnames';
import { Icon } from 'assets/icons';
import styles from './banner.module.css';

export const InfoBanner = ({ message = '', type = 'info' }) => {
  return (
    <div className={cx(styles.wrapper, styles[type])}>
      <Icon name="info" />
      <div className={styles.message}>{message}</div>
    </div>
  );
};
