import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as WebPushService from 'services/messaging/push/web';

export const useWebPushService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createPushMessage = async (options = {}) => {
    const { error, payload } = await WebPushService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to create message at this time. Please try again.');
      return { message: null };
    }

    toast.success('Message created successfully.');
    return { message: payload };
  };

  const fetchPushMessages = async (options = {}) => {
    const { error, payload } = await WebPushService.read({ ...options, token });
    if (error) {
      return {
        messages: [],
        size: 0,
        error: 'Unable to fetch message at this time.'
      };
    }

    const {
      data: messages,
      meta: { size }
    } = payload;
    return { messages, size, error: null };
  };

  const searchPushMessage = async (keys, keyword, options = {}) => {
    const { error, payload } = await WebPushService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, messages: [], size: 0 };
    }

    const {
      data: messages,
      meta: { size }
    } = payload;
    return { error, messages, size };
  };

  const updatePushMessage = async (id, options = {}) => {
    const { error, payload } = await WebPushService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { message: null };
    }

    toast.success('PushMessage updated successfully.');
    return { message: payload };
  };

  return { fetchPushMessages, createPushMessage, searchPushMessage, updatePushMessage };
};
