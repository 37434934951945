import { useState } from 'react';

export const useDebouce = () => {
  const [timer, setTimer] = useState(null);

  const clearTimer = () => setTimer(null);
  const debounce = (debounceFn = (f) => f, delay = 500) => {
    if (timer) clearTimeout(timer);
    const t = setTimeout(debounceFn, delay);
    setTimer(t);
  };

  return { clearTimer, debounce };
};
