import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useContext } from 'react';
import { addMetadata } from 'store/actions/metadata';

import styles from './api.module.css';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { Icon } from 'assets/icons';
import { Spacer } from 'app/layouts/generic';
import { truncateText } from 'app/shared/utils/general';
import { SimpleInput } from 'app/shared/input';
import { useKeyService } from 'hooks/key';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { PermissionsContext } from 'contexts/permissions';

export const APIConfiguration = () => {
  const dispatch = useDispatch();
  const { id, firstname } = useSelector((state) => state.user_data);
  const { createKey, fetchKey } = useKeyService();
  const { permissions } = useContext(PermissionsContext);

  const [api_key, setAPIKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [show_key, setShowKey] = useState(false);
  const [show_confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    fetchKey(id)
      .then((response) => {
        if (!response) {
          return;
        }

        setAPIKey(response.key);
        dispatch(addMetadata({ api_key: response.key }));
      })
      .catch((e) => e);
  }, [id, dispatch]);

  const copyAPIKey = () => {
    navigator.clipboard
      .writeText(api_key)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };

  const handleConfirmation = (permitted) => {
    if (permitted) submitForm();
    setConfirmation(() => false);
  };

  const submitForm = () => {
    const data = {
      id,
      name: firstname
    };

    setLoading(true);
    createKey({ data })
      .then((response) => {
        if (!response) {
          return;
        }

        setAPIKey(response.key);
        dispatch(addMetadata({ api_key: response.key }));
      })
      .catch((e) => e)
      .finally(() => setLoading(false));
  };

  const toggleKeyVisibility = () => {
    setShowKey((val) => !val);
  };

  return (
    <>
      <BasicInfoSection title="API key configuration">
        <div className={styles.keyWrapper}>
          <SimpleInput
            type={show_key ? 'text' : 'password'}
            className={styles.keyDisplay}
            style={{ marginBottom: '0px' }}
            value={truncateText(api_key, 50)}
            disabled
          />
          <Icon name="copy" onClick={copyAPIKey} />
          <Icon name={show_key ? 'hide' : 'show'} onClick={toggleKeyVisibility} />
        </div>
        <Spacer multiple={3} />
        <GridRow num_of_columns={2}>
          <GridColumn />
          <GridColumn>
            <Button
              className="w-100"
              onClick={(e) => setConfirmation(true)}
              text={api_key ? 'Regenerate API key' : 'Generate API key'}
              loading={loading}
              disabled={!permissions['developer_config:update']}
            />
          </GridColumn>
        </GridRow>
      </BasicInfoSection>
      <ConfirmationDialog
        title={`${api_key ? 'Regenerate' : 'Generate'} API Key`}
        message="You are about generate a new API key. You will not be able to undo this action."
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
    </>
  );
};
