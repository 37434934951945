import { useSelector } from 'react-redux';
import * as InvoiceService from 'services/invoice';

export const useInvoiceService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchInvoices = async (options = {}) => {
    const { error, payload } = await InvoiceService.read({ ...options, token });
    if (error) {
      return { invoices: [], size: 0, error: 'Unable to fetch invoices at this time.' };
    }

    const {
      data: invoices,
      meta: { size }
    } = payload;
    return { invoices, size, error: null };
  };

  return {
    fetchInvoices
  };
};
