import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useResourceQuery } from 'hooks/automation/resources';
import React, { useEffect, useState } from 'react';
import styles from './response.module.css';
import { useEffectsService } from 'hooks/automation/effects';
import { EFFECT_ADD_CONTACT_TAG } from '@go-mailer/jarvis/lib/constants/automation';
import { SimpleInput } from 'app/shared/input';
import { Checkbox } from 'app/shared/input/checkbox';
import { NeuronDelayConfig } from './delay';
import { Spacer } from 'app/layouts/generic';

export const NeuronResponseConfig = ({ data = {}, onAdd = () => {}, closeModal = () => {} }) => {
  const { fetchEffects } = useEffectsService();
  const { fetchResources } = useResourceQuery();

  const [effects, setEffects] = useState([]);
  const [resources, setResources] = useState([]);
  const [rule, setRule] = useState(null);
  const [selected_action, setSelectedAction] = useState(null);
  const [selected_resources, setSelectedResources] = useState([]);
  const [show_delay_config, setShowDelayConfig] = useState(false);
  const [tags, setTags] = useState('');

  useEffect(() => {
    fetchEffects().then(({ effects: raw_effects }) => {
      const parsed_effects = raw_effects.map((effect) => ({
        ...effect,
        label: effect.name,
        value: effect.code
      }));

      setEffects(() => parsed_effects);
      if (data && data.effect) {
        setSelectedAction(() => effects.find((effect) => effect.value === data.effect));
      }
    });

    if (data && data.rule) {
      setShowDelayConfig(() => true);
      setRule(data.rule);
    }

    return () => clearForm();
  }, [data]);

  useEffect(() => {
    if (!selected_action || !Object.keys(selected_action).length) return;

    setSelectedResources([]);
    setTags('');

    fetchResources(selected_action.resource_class, '').then(({ resources: raw_resources }) => {
      const parsed_resources = raw_resources.map((res) => ({
        ...res,
        label: res.name || res.title || res.link,
        value: res.id
      }));

      setResources(() => parsed_resources);
      if (data && data.target) {
        const [, ...resource_ids] = data.target.split(':');
        if (data.effect === EFFECT_ADD_CONTACT_TAG) {
          setTags(resource_ids[0]);
        } else {
          const id_maps = resource_ids?.reduce((s, r) => ({ ...s, [r]: r }), {});
          setSelectedResources(parsed_resources.filter((resource) => id_maps[resource.value]));
        }
      }
    });
  }, [selected_action]);

  useEffect(() => {
    if (!show_delay_config) setRule(null);
  }, [show_delay_config]);

  const clearForm = () => {
    setSelectedAction({});
    setSelectedResources([]);
  };

  const handleActionSelection = (selection = null) => {
    if (!selection) return;
    setSelectedAction(selection);
  };

  const handleResourceSelection = (selections = []) => {
    setSelectedResources(selections);
  };

  const submit = () => {
    const resources = selected_resources?.map((res) => res.value).join(':') || 'none';
    let target = `${selected_action.resource_class}:${resources}`;
    if (selected_action?.value === EFFECT_ADD_CONTACT_TAG) {
      target = `${selected_action.resource_class}:${tags}`;
    }

    const new_data = {
      effect: selected_action.value,
      rule,
      target
    };

    onAdd(new_data);
    clearForm();
    closeModal();
  };

  return (
    <div className={styles.formWrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Action">
            <MultiSelect
              options={effects}
              onChange={handleActionSelection}
              value={selected_action}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          {selected_action?.value === EFFECT_ADD_CONTACT_TAG ? (
            <DetailItem title="Tags">
              <SimpleInput value={tags} onInput={setTags} />
            </DetailItem>
          ) : (
            <DetailItem title="Resource">
              <MultiSelect
                options={resources}
                onChange={handleResourceSelection}
                value={selected_resources}
                isMulti
              />
            </DetailItem>
          )}
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <Checkbox
            label="Enable delay"
            checked={show_delay_config}
            onClick={() => setShowDelayConfig((show) => !show)}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      {show_delay_config && <NeuronDelayConfig data={data} onChange={setRule} />}
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Add Response" onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
