import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { useNeuronService } from 'hooks/automation/neuron';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addManyNeuronsToStore, addNeuronSearchResultsToStore } from 'store/actions/neuron';
import { NeuronCard } from '../neuron';

export const NeuronListPage = ({ keyword }) => {
  const dispatch = useDispatch();
  const neurons_in_store = useSelector((store) => store.neurons);
  const { fetchNeurons, searchNeurons } = useNeuronService();

  const [loading, setLoading] = useState(false);
  const [neurons, setNeurons] = useState([]);
  const [page_number, setPageNumber] = useState(0);

  useEffect(() => {
    if (!neurons_in_store) return;
    setNeurons(() => Object.values(neurons_in_store).sort((a, b) => (a.code < b.code ? -1 : 1)));
  }, [neurons_in_store]);

  useEffect(() => {
    handleRequest(keyword, 0);
    setPageNumber(() => 0);
  }, [keyword]);

  const handleDataRequest = async (page = 0, population = 50) => {
    setLoading(true);
    const { neurons } = await fetchNeurons({
      query_string: `page=${page}&population=${population}&sort_by=code`
    });
    dispatch(addManyNeuronsToStore(neurons));
    setLoading(false);
  };

  const handleRequest = (keyword, page_number) => {
    if (!keyword) handleDataRequest(page_number);
    else handleSearch(keyword, page_number);
  };

  const handleSearch = async (keyword = '', page = 0, population = 50) => {
    setLoading(true);
    const { neurons } = await searchNeurons('code,title,event', keyword, {
      query_string: `page=${page}&population=${population}&sort_by=code`
    });

    setLoading(false);
    if (page === 0) dispatch(addNeuronSearchResultsToStore(neurons));
    else dispatch(addManyNeuronsToStore(neurons));
  };

  const loadMore = () => {
    const page_num = page_number + 1;
    handleRequest(keyword, page_num);
    setPageNumber(() => page_num);
  };

  return (
    <>
      <GridRow num_of_columns={4}>
        {neurons?.map((neuron) => (
          <NeuronCard key={neuron.id} data={neuron} />
        ))}
      </GridRow>
      <Spacer multiple={8} />
      <GridRow num_of_columns={5}>
        <GridColumn span={2} />
        {loading ? (
          <div>Loading</div>
        ) : (
          <Button text="Load More" onClick={loadMore} type="secondary" />
        )}
        <GridColumn span={2} />
      </GridRow>
    </>
  );
};
