import * as OrganizationService from 'services/organization';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const useOrganizationService = () => {
  const { token } = useSelector((store) => store.user_data);
  const createOrganization = async (options = {}) => {
    const { error, payload } = await OrganizationService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchOrganizations = async (options = {}) => {
    const { error, payload } = await OrganizationService.read({ ...options, token });
    if (error) {
      return { error, orgs: null, size: 0 };
    }

    const {
      data: orgs,
      meta: { size }
    } = payload;
    return { orgs, size, error: null };
  };

  const updateOrganization = async (id, options = {}) => {
    const { error, payload } = await OrganizationService.updateOrgnizationById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to update Organization information at this time.');
      return null;
    }

    return payload;
  };

  return {
    createOrganization,
    fetchOrganizations,
    updateOrganization
  };
};
