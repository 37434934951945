import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { GridColumn, GridRow } from 'app/layouts/grid';
import useValidator from 'hooks/validator';
import { passwordValidationConfig } from 'app/shared/utils/form/validation/authentication';
import { useUserService } from 'hooks/iam/user';

export const PasswordConfiguration = () => {
  const { id: user_id } = useSelector((state) => state.user_data);
  const { updatePassword } = useUserService();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const { errors, validateField, form_is_valid } = useValidator(passwordValidationConfig);

  const clearForm = () => {
    setFormData({});
  };

  const handleChange = (field, value) => {
    validateField(
      field,
      field === 'confirm_password'
        ? { newPassword: formData.password, confirmPassword: value }
        : value
    );

    if (field === 'password' && formData?.confirm_password?.length) {
      validateField('confirm_password', {
        newPassword: value,
        confirmPassword: formData.confirm_password
      });
    }

    setFormData((previous_values) => ({
      ...previous_values,
      [field]: value
    }));
  };

  const submitForm = async () => {
    const data = {
      ...formData
    };

    setLoading(true);
    const result = await updatePassword(user_id, { data });
    setLoading(false);
    if (!result) {
      toast.error('Unable to update password');
      return;
    }

    toast.success('Password updated.');
    clearForm();
  };

  return (
    <>
      <BasicInfoSection title="Authentication">
        <DetailItem title="Old Password">
          <SimpleInput
            type="password"
            required
            input_id="old_password"
            value={formData.old_password}
            onInput={(value) => handleChange('old_password', value)}
            error={errors.old_password}
          />
        </DetailItem>
        <GridRow num_of_columns={2}>
          <GridColumn>
            <DetailItem title="New Password">
              <SimpleInput
                type="password"
                required
                input_id="password"
                value={formData.password}
                onInput={(value) => handleChange('password', value)}
                error={errors.password}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Confirm password">
              <SimpleInput
                type="password"
                required
                input_id="confirm_password"
                value={formData.confirm_password}
                onInput={(value) => handleChange('confirm_password', value)}
                error={errors.confirm_password}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>

        <GridRow num_of_columns={2}>
          <GridColumn />
          <GridColumn>
            <Button
              onClick={submitForm}
              text="Save changes"
              loading={loading}
              disabled={!form_is_valid || loading}
            />
          </GridColumn>
        </GridRow>
      </BasicInfoSection>
    </>
  );
};
