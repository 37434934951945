import { useSelector } from 'react-redux';
import * as ModuleService from 'services/sales/module';

export const useModuleService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchModules = async (options = {}) => {
    const { error, payload } = await ModuleService.read({ ...options, token });
    if (error) {
      return { modules: [], size: 0, error: 'Unable to fetch modules at this time.' };
    }

    const {
      data: modules,
      meta: { size }
    } = payload;
    return { modules, size, error: null };
  };

  return {
    fetchModules
  };
};
