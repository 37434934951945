/** */
export const READ_MANY_ROLES = 'read multiple roles.';
export const READ_ONE_ROLE = 'read one role.';

export const ADD_ONE_ROLE_TO_STORE = 'add one role to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add role search results to store.';
export const ADD_MANY_ROLES_TO_STORE = 'add multiple roles to store.';
export const LOAD_ROLES_TO_STORE = 'load up roles to store.';
export const REM_ONE_ROLE_FROM_STORE = 'remove a role from the store.';

/** */
export const readManyRoles = (payload) => ({
  type: READ_MANY_ROLES,
  payload
});

export const readOneRole = (payload) => ({
  type: READ_ONE_ROLE,
  payload
});

export const addManyRolesToStore = (payload) => ({
  type: ADD_MANY_ROLES_TO_STORE,
  payload
});

export const addOneRoleToStore = (payload) => ({
  type: ADD_ONE_ROLE_TO_STORE,
  payload
});

export const addRoleSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadRolesToStore = (payload) => ({
  type: LOAD_ROLES_TO_STORE,
  payload
});

export const removeOneRoleFromStore = (payload) => ({
  type: REM_ONE_ROLE_FROM_STORE,
  payload
});
