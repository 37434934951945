/** */
import React, { useEffect, useState } from 'react';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { DateRangePicker } from 'app/shared/datepickers';
import { generateDefaultRange } from 'app/shared/utils/date';
import { Spacer } from 'app/layouts/generic';
import { Route, Routes } from 'react-router-dom';
import { TransactionalsDashboardSection } from './sections/transactionals';
import { CampaignsDashboardSection } from './sections/campaigns';
import { ContactsDashboardSection } from './sections/contacts';
import { setPageTitle } from 'store/actions/header';
import { useDispatch } from 'react-redux';

const GMDashboard = () => {
  const dispatch = useDispatch();
  const [thirty_days_ago, today] = generateDefaultRange();
  const [selected_date, setSelectedDate] = useState([thirty_days_ago, today]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Dashboard', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NAV_ITEMS = {
    campaigns: {
      index: 1,
      title: 'Campaigns',
      path: '/dashboard/'
    },
    transactionals: {
      index: 2,
      title: 'Transactionals',
      path: '/dashboard/transactionals'
    },
    contacts: {
      index: 3,
      title: 'Contacts',
      path: '/dashboard/contacts'
    }
  };

  const handleDateSelection = (selection) => {
    setSelectedDate(selection);
  };

  return (
    <PageContentWrapper>
      <SlidingNav
        nav_items={Object.values(NAV_ITEMS)}
        actions={<DateRangePicker onChange={handleDateSelection} />}
      />
      <Spacer multiple={4} />
      <Routes>
        <Route path="/*" element={<CampaignsDashboardSection date_range={selected_date} />} />
        <Route
          path="/dashboard/transactionals"
          element={<TransactionalsDashboardSection date_range={selected_date} />}
        />
        <Route
          path="/dashboard/contacts"
          element={<ContactsDashboardSection date_range={selected_date} />}
        />
      </Routes>
    </PageContentWrapper>
  );
};

export default GMDashboard;
