import React, { createContext, useState } from 'react';

export const BubbleDisplayContext = createContext({
  items: {},
  addItem: () => {},
  removeItem: () => {}
});

export const BubbleDisplayProvider = ({ children }) => {
  const [items, setItems] = useState({});

  const addItem = (key, item = () => {}) => {
    setItems((curr_items) => ({ [key]: item, ...curr_items }));
  };

  const removeItem = (key) => {
    const curr_items = items;
    delete curr_items[key];
    setItems(() => curr_items);
  };

  return (
    <BubbleDisplayContext.Provider value={{ items, addItem, removeItem }}>
      {children}
    </BubbleDisplayContext.Provider>
  );
};
