//
export const delay = async (seconds = 1) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};

export const getUniqueItemCount = (list = []) => {
  const unique_items = new Set();
  list.forEach((item) => {
    if (item.contact_id) {
      unique_items.add(item.contact_id);
    } else unique_items.add(item);
  });
  return unique_items.size || 0;
};

export const checkDNSStatus = (mailboxes = [], email_address = '') => {
  if (!mailboxes.length) return true;
  if (!email_address) return false;

  const [, address_fqdn] = email_address.split('@');
  const domain_names = mailboxes.reduce((sac, mailbox) => {
    const [, domain_name] = mailbox.split('@');
    return {
      ...sac,
      [domain_name]: mailbox
    };
  }, {});

  return !domain_names[address_fqdn];
};

export const convertToPercent = (value, total) => {
  if (!value || !total) return 0;
  return (Number(value) / Number(total)) * 100;
};

export const generateUniqueId = () => {
  const randomString = Math.random().toString(36).substr(2, 9);
  const timestamp = Date.now();
  return `${randomString}-${timestamp}`;
};

export const isHTMLBodyEmpty = (htmlBody) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlBody, 'text/html');
  const bodyContent = doc.querySelector('body').innerHTML.trim();
  return bodyContent === '';
};

export const capitalizeText = (value) =>
  value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();

export const truncateText = (value, size = 25) => {
  if (typeof value === 'string' && value.length > size) {
    return `${value.substring(0, size)}...`;
  }

  return value;
};

export const objectIsEmpty = (object = {}) => {
  return !object || Object.keys(object).length === 0;
};

export const isGeneralEmail = (email) => {
  const general_domains = [
    'yahoo.com',
    'gmail.com',
    'ymail.com',
    'outlook.com',
    'hotmail.com',
    'aol.com',
    'mail.com',
    'zoho.com',
    'yandex.com',
    'protonmail.com',
    'mail.ru',
    'icloud.com',
    'hushmail.com'
  ];
  // Split the email address to get the domain
  const parts = email.split('@');
  if (parts.length === 2) {
    const domain = parts[1].toLowerCase();
    // Check if the domain is in the list of general email domains
    return general_domains.includes(domain);
  }

  // If the email doesn't contain exactly one '@', it's not valid
  return false;
};

export const getMonthName = (date) => {
  return new Date(date).toLocaleString('default', { month: 'long' });
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const mergeArrays = (...arrays) => {
  const merged = {};

  arrays.forEach((array) => {
    if (Array.isArray(array)) {
      array.forEach((item) => {
        const { month, ...rest } = item;

        // Check if all values are 0 (excluding the month)
        const isAllZeros = Object.values(rest).every((value) => value === 0);

        if (!isAllZeros) {
          merged[month] = {
            ...merged[month],
            month,
            ...rest,
            ...(merged[month] || {})
          };
        }
      });
    }
  });

  const result = Object.values(merged).sort((a, b) => {
    const monthsOrder = months;
    return monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month);
  });

  return result;
};

export const getMonthNameFromIndex = (value) => {
  return months[value];
};
