/** */
export const READ_MANY_FLAG = 'read multiple flag.';
export const ADD_MANY_FLAG_TO_STORE = 'add multiple flag to store.';
export const REMOVE_ONE_FLAG_FROM_STORE = 'remove one flag from store';
export const REMOVE_MANY_FLAG_FROM_STORE = 'remove many flag from store';
export const ADD_ONE_FLAG_TO_STORE = 'add one flag to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add flag search results to store.';
export const LOAD_FLAGS_TO_STORE = 'load up flags to store.';
export const UPDATE_ONE_FLAG_IN_STORE = 'update an flag in store.';

/** */
export const readManyFlag = (payload) => ({
  type: READ_MANY_FLAG,
  payload
});

export const addManyFlagToStore = (payload) => ({
  type: ADD_MANY_FLAG_TO_STORE,
  payload
});

export const removeOneFlagFromStore = (payload) => ({
  type: REMOVE_ONE_FLAG_FROM_STORE,
  payload
});

export const removeManyFlagFromStore = (payload) => ({
  type: REMOVE_MANY_FLAG_FROM_STORE,
  payload
});

export const addOneFlagToStore = (payload) => ({
  type: ADD_ONE_FLAG_TO_STORE,
  payload
});

export const addFlagSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadFlagsToStore = (payload) => ({
  type: LOAD_FLAGS_TO_STORE,
  payload
});

export const updateFlagInStore = (payload) => ({
  type: UPDATE_ONE_FLAG_IN_STORE,
  payload
});
