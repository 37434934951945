/** */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDataToStore } from 'store/actions/user-data';
import { Alert } from 'app/shared/form-alert';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import styles from '../guest.module.css';
import { generateUniqueId } from 'app/shared/utils/general';
import { useGuestService } from 'hooks/guest';
import { Spacer } from 'app/layouts/generic';

export const OTPForm = ({ email = '' }) => {
  const dispatch = useDispatch();
  const { resendOtp, verifyOtp } = useGuestService();
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({ otp: '' });
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [user_email, setUserEmail] = useState('');

  useEffect(() => {
    setUserEmail(email);
  }, []);

  const handleOTPChange = (value) => {
    setFormData((form_data) => ({ ...form_data, otp: value }));
  };

  const submitForm = () => {
    setLoading(true);
    verifyOtp(form_data.otp)
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({ message: error, id: generateUniqueId() });
          return;
        }

        dispatch(addDataToStore({ ...payload, logged_in_time: Date.now() }));
      })
      .finally(() => setLoading(false));
  };

  const handleOtpResend = () => {
    setResending(true);
    resendOtp({ data: { email: user_email } })
      .then((response) => {
        const { error, payload } = response;
        const message = error || payload?.message;
        const type = error ? 'error' : 'success';
        setAlert({
          message,
          type,
          id: generateUniqueId()
        });
      })
      .finally(() => setResending(false));
  };

  return (
    <div>
      <h1>Welcome back!</h1>
      <p>Please enter your OTP.</p>
      <form>
        <Alert {...alert} />
        <SimpleInput
          placeholder="Enter OTP"
          onInput={handleOTPChange}
          value={form_data.otp}
          className={styles.otpForm}
          wrapperClassName={styles.inputMargin}
        />

        <div className={styles.formText}>
          {!resending ? <b onClick={handleOtpResend}>Resend OTP</b> : <b>Resending...</b>}
        </div>
        <Spacer multiple={4} />
        <Button
          className={styles.formBtn}
          type="submit"
          onClick={() => submitForm()}
          text="Log In"
          loading={loading}
        />
      </form>
    </div>
  );
};
