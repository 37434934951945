import { GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { TextArea } from 'app/shared/input/textarea';
import useValidator from 'hooks/validator';
import React, { useContext, useEffect } from 'react';
import { RoleValidationSchema } from '../schema';
import { PermissionsContext } from 'contexts/permissions';

export const RoleBasicInformation = ({
  data = {},
  is_saving = false,
  onDataChange = () => {},
  onSave = () => {}
}) => {
  const { errors, validateField, form_is_valid } = useValidator(RoleValidationSchema);
  const { permissions } = useContext(PermissionsContext);

  useEffect(() => {
    if (!data) return;
    Object.keys(data).forEach((key) => {
      validateField(key, data[key]);
    });
  }, [data]);

  return (
    <BasicInfoSection title="Basic Details">
      <GridRow num_of_columns={1}>
        <DetailItem title="Name">
          <SimpleInput
            error={errors.name}
            value={data.name}
            onInput={(value) => onDataChange('name', value)}
          />
        </DetailItem>
      </GridRow>
      <GridRow num_of_columns={1}>
        <DetailItem title="Description">
          <TextArea
            error={errors.description}
            value={data.description}
            onInput={(value) => onDataChange('description', value)}
          />
        </DetailItem>
      </GridRow>
      <GridRow num_of_columns={1}>
        <Button
          text="Save Changes"
          onClick={onSave}
          disabled={!form_is_valid || is_saving || !permissions['user_management:update']}
        />
      </GridRow>
    </BasicInfoSection>
  );
};
