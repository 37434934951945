import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './view.css';
import { setPageTitle } from 'store/actions/header';

import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { BasicAudienceInfo } from './components/basic-info';
import { useAudienceService } from 'hooks/users/audience';
import { AudienceUpdationForm } from '../edit';
import { AudiencePageStatBar } from './components/stat-bar';
import { Spacer } from 'app/layouts/generic';
import { AudienceContacts } from './components/lists/contacts';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import useScreenSize from 'hooks/size';
import { AudienceQueryBuilder } from './components/query-builder';
import { AudienceCampaign } from './components/lists/campaign/campaigns';
import { useContactService } from 'hooks/contact';
import { getMonthTimeRange } from 'app/shared/utils/date';
import { toast } from 'react-toastify';
import { useCampaignService } from 'hooks/mailing/campaign';

export const MailingListPage = () => {
  const { id } = useParams();
  const { start, end } = getMonthTimeRange();
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();
  const { fetchAudienceById } = useAudienceService();
  const { fetchAudienceContacts } = useContactService();
  const { fetchCampaigns } = useCampaignService();

  const [audience, setAudience] = useState({});
  const [mode, setMode] = useState('view');
  const [audience_stats, setAudienceStats] = useState(false);
  const [contact_stat, setContactStat] = useState({
    valid_contacts: 0,
    invalid_contacts: 0,
    valid_contacts_month: 0,
    invalid_contacts_month: 0,
    campaign_all_time: 0,
    campaign_in_month: 0
  });
  const [is_loading_audience, setIsLoadingAudience] = useState(true);

  useEffect(() => {
    setIsLoadingAudience(() => true);
    fetchAudienceById(id)
      .then(({ audience }) => {
        setAudience(() => audience);
        dispatch(
          setPageTitle([
            { title: 'Audiences', path: '/audiences' },
            { title: audience.name || 'N/A', path: `/audiences/${id}` }
          ])
        );
      })
      .finally(() => setIsLoadingAudience(() => false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    Promise.all([
      fetchAudienceContacts(id, { query_string: `count=1&bool=is_valid` }),
      fetchAudienceContacts(id, { query_string: `count=1&bool=-is_valid` }),
      fetchAudienceContacts(id, {
        query_string: `subscription_history.time_stamp=${start}~${end}&count=1&bool=is_valid`
      }),
      fetchAudienceContacts(id, {
        query_string: `subscription_history.time_stamp=${start}~${end}&count=1&bool=-is_valid`
      }),
      fetchCampaigns({ query_string: `mailing_lists=${id}&count=1` }),
      fetchCampaigns({
        query_string: `time_stamp=${start}~${end}&count=1&mailing_lists=${id}`
      })
    ])
      .then((responses) => {
        const [
          { size: valid_contacts },
          { size: invalid_contacts },
          { size: valid_contacts_month },
          { size: invalid_contacts_month },
          { size: campaign_all_time },
          { size: campaign_in_month }
        ] = responses;

        setContactStat((stats) => ({
          ...stats,
          valid_contacts,
          invalid_contacts,
          valid_contacts_month,
          invalid_contacts_month,
          campaign_all_time,
          campaign_in_month
        }));
      })
      .catch(() => toast.error('Unable to fetch logs at this time.'));
  }, [audience_stats, id]);

  const handleUpdation = (data = null) => {
    if (!data) return;
    setAudience((curr_data) => ({ ...curr_data, ...data }));
    setMode(() => 'view');
  };

  const nav_config = [
    {
      title: 'Query Builder',
      path: `/audiences/${id}/query`,
      hidden: is_loading_audience || !isDesktop || audience.is_test_audience
    },
    { title: 'Valid Contacts', path: `/audiences/${id}/contacts/valid` },
    { title: 'Invalid Contacts', path: `/audiences/${id}/contacts/invalid` },
    { title: 'Campaigns', path: `/audiences/${id}/campaigns` }
  ].filter((item) => !item.hidden);

  const handleQueryChange = useCallback(() => {
    setAudienceStats((prev) => !prev);
  }, []);

  return (
    <div className="dt-content-wrapper">
      <PageContentWrapper>
        <GridRow>
          {isDesktop && (
            <GridColumn>
              <GridRow num_of_columns={1}>
                <GridColumn>
                  {mode === 'view' ? (
                    <BasicAudienceInfo
                      audience_data={audience}
                      onEditClick={() => setMode('edit')}
                    />
                  ) : (
                    <AudienceUpdationForm audience_data={audience} onSave={handleUpdation} />
                  )}
                </GridColumn>
              </GridRow>
            </GridColumn>
          )}
          <GridColumn span={isDesktop ? 3 : 4}>
            {!isDesktop && <SlidingNav nav_items={nav_config} />}
            {isDesktop && <AudiencePageStatBar contact_stat={contact_stat} />}
            <Spacer multiple={4} />
            {isDesktop && <SlidingNav nav_items={nav_config} />}
            <Spacer multiple={4} />
            <Routes>
              {!is_loading_audience && !audience.is_test_audience && (
                <Route
                  path="query"
                  element={<AudienceQueryBuilder onQueryChange={handleQueryChange} />}
                />
              )}
              <Route path="contacts/valid" element={<AudienceContacts key="valid" />} />
              <Route path="campaigns" element={<AudienceCampaign />} />
              <Route
                path="contacts/invalid"
                element={<AudienceContacts key="invalid" is_valid={false} />}
              />
              <Route
                path="/*"
                element={
                  isDesktop ? (
                    !is_loading_audience && !audience.is_test_audience ? (
                      <AudienceQueryBuilder onQueryChange={handleQueryChange} />
                    ) : (
                      <AudienceContacts key="valid" />
                    )
                  ) : mode === 'view' ? (
                    <BasicAudienceInfo
                      audience_data={audience}
                      onEditClick={() => setMode('edit')}
                    />
                  ) : (
                    <AudienceUpdationForm audience_data={audience} onSave={handleUpdation} />
                  )
                }
              />
            </Routes>
          </GridColumn>
        </GridRow>
      </PageContentWrapper>
    </div>
  );
};
