/** */
import { urls } from './_url';
import { apiGet, apiPost, apiPut } from './_http';

/** */
const base_url = `${urls.sales}/wallets`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const checkCreditsLevel = async (options = {}) => {
  return await apiGet(`${base_url}/credits/level`, { ...options });
};

export const create = async (options = {}) => {
  return await apiPost(`${base_url}`, { ...options });
};

export const createVirtualAccount = async (options) => {
  return await apiPost(`${base_url}/accounts/virtual`, { ...options });
};

export const update = async (options = {}) => {
  return await apiPut(`${base_url}`, { ...options });
};
