import React from 'react';
import styles from '../editor.module.css';
import { Icon } from 'assets/icons';

export const EditorLayout = ({ main, sidebar, button_text = 'Save', onSave, disable }) => {
  return (
    <>
      <main className={styles.main}>{main}</main>
      <div className={styles.sidebar}>
        <section>{sidebar}</section>
        <footer>
          <button disabled={disable} onClick={onSave}>
            <Icon name="check_circle" className={styles.icon} />
            <p>{button_text}</p>
          </button>
        </footer>
      </div>
    </>
  );
};
