import React from 'react';
import cx from 'classnames';
import { Icon } from 'assets/icons';

import styles from './options.module.css';
import { InfoBanner } from '../info-banner';

const Option = ({
  icon_name = 'add',
  text = 'Option text',
  optionClassName = '',
  onClick = () => {}
}) => {
  return (
    <div className={cx(styles.option, optionClassName)} onClick={onClick}>
      <div className={styles.iconWrapper}>
        <Icon name={icon_name} height={64} width={64} />
      </div>
      <span className={styles.title}>{text}</span>
    </div>
  );
};

export const OptionsModal = ({
  options = [],
  baseClassName = '',
  optionClassName = '',
  is_dkim_set = false
}) => {
  return (
    <>
      {!is_dkim_set && (
        <InfoBanner
          type="error"
          message="You have not configured your SPF/DKIM records on your domain. This may lead to poor email performance. To generate your records, navigate to Settings > Email"
        />
      )}
      <div className={cx(styles.wrapper, baseClassName)}>
        {options.map(({ icon_name, text, onClick }) => (
          <Option
            optionClassName={optionClassName}
            icon_name={icon_name}
            text={text}
            key={text}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  );
};
