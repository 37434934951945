import cx from 'classnames';
import { Icon } from 'assets/icons';
import React from 'react';
import styles from './banner.module.css';

export const NoticeBanner = ({ message = '', type = 'error', onClose = () => {} }) => {
  return (
    <div className={cx(styles.wrapper, styles[type])}>
      <p>{message} </p>
      <Icon name="close" onClick={onClose} />
    </div>
  );
};
