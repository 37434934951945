import { isEmailValid } from '../../input';

export const billingInfomationValidationConfig = {
  email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Email Address';
      } else result.is_valid = true;

      return result;
    }
  },
  address: {
    required: true
  },
  state: {
    required: true
  },
  country: {
    required: true
  }
};
