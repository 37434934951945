/** */
import {
  ADD_ONE_MESSAGE_TO_STORE,
  ADD_MANY_MESSAGES_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  REMOVE_MANY_MESSAGES_FROM_STORE,
  REMOVE_ONE_MESSAGE_FROM_STORE,
  LOAD_MESSAGE_TO_STORE,
  UPDATE_MESSAGE
} from '../actions/push';

export default (state = {}, action) => {
  const { type, payload } = action;
  const current_state = state;
  switch (type) {
    case ADD_MANY_MESSAGES_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (messages, message) => ({
            ...messages,
            [message.id]: message
          }),
          {}
        )
      };
    case ADD_ONE_MESSAGE_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (messages, message) => ({
            ...messages,
            [message.id]: message
          }),
          {}
        )
      };
    case LOAD_MESSAGE_TO_STORE:
      return {
        ...payload.reduce(
          (messages, message) => ({
            ...messages,
            [message.id]: message
          }),
          {}
        )
      };
    case REMOVE_MANY_MESSAGES_FROM_STORE:
      payload.forEach((id) => delete current_state[id]);
      return {
        ...current_state
      };
    case REMOVE_ONE_MESSAGE_FROM_STORE:
      delete current_state[payload];
      return { ...current_state };
    case UPDATE_MESSAGE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      };
    default:
      return state;
  }
};
