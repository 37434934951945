import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { WebPushBasicInfo } from './details/basic';
import { WebPushMessageLogs } from './logs/sent';
import { WebPushMessageStatBar } from './statbar';

const getNavItems = (message_id) => [
  {
    title: 'Recipients',
    path: `/messaging/push/web/${message_id}`
  }
];

export const WebPushMessageViewPage = () => {
  const { id } = useParams();

  return (
    <>
      <GridRow>
        <GridColumn>
          <WebPushBasicInfo id={id} />
        </GridColumn>
        <GridColumn span={3}>
          <GridRow num_of_columns={1}>
            <WebPushMessageStatBar id={id} />
          </GridRow>
          <Spacer multiple={4} />
          <GridRow num_of_columns={1}>
            <SlidingNav nav_items={getNavItems(id)} />
            <Routes>
              <Route path="/*" element={<WebPushMessageLogs id={id} />} />
            </Routes>
          </GridRow>
        </GridColumn>
      </GridRow>
    </>
  );
};
