import React, { useState } from 'react';

export const TreeContext = React.createContext({
  selected_step: {},
  action: {}, // this should replace selected_step when all PRs are accepted
  step_modal: {},
  widget_to_create: '',
  setAction: () => {},
  setSelectedStep: () => {},
  setWidgetToCreate: () => {},
  toggleStepModal: () => {},
  editStep: () => {}
});

export const AUTOMATION_ACTIONS = {
  NONE: 'none',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete'
};

export const AutomationProvider = ({ children }) => {
  const [action, setAction] = useState({
    action: AUTOMATION_ACTIONS.NONE,
    data: {}
  });
  const [selected_step, setSelectedStep] = useState({});
  const [step_modal, setStepModal] = useState({
    parent: {},
    parent_id: 0,
    journey_id: 0,
    show: false,
    step: null
  });
  const [widget_to_create, setWidgetToCreate] = useState('');

  const editStep = (data) => {
    setSelectedStep(() => data);
    setStepModal(() => data);
  };

  const toggleStepModal = (data) => {
    setStepModal(() => data);
  };

  const value = {
    editStep,
    setAction,
    setSelectedStep,
    setWidgetToCreate,
    toggleStepModal,
    action,
    selected_step,
    step_modal,
    widget_to_create
  };
  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>;
};
