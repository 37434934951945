import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useAuthConfigService } from 'hooks/iam/auth-config';
import { Switch } from 'app/shared/switch';

export const MultiFactorAuthentication = () => {
  const { tenant_id, memberships } = useSelector((state) => state.user_data);
  const { fetchConfigs, updateConfig } = useAuthConfigService();
  const [is_super_admin, setSuperAdmin] = useState(false);
  const [auth_config, setAuthConfig] = useState(null);

  useEffect(() => {
    fetchConfigs({ query_string: `tenant_id=${tenant_id}` }).then(
      ({ configs: [config], error }) => {
        if (error) return toast.error(error);
        const { role_id } = (memberships || []).find(
          (membership) => membership.tenant_id === tenant_id
        );
        setSuperAdmin(Number(role_id) === 1);
        setAuthConfig(config);
      }
    );
  }, []);

  const handleToggle = async (field, value) => {
    const data = {
      ...auth_config,
      otp: { ...auth_config?.otp, [field]: value }
    };

    await updateConfig({ data });
    setAuthConfig(data);
  };

  return (
    <>
      <BasicInfoSection title="Multi-factor Authentication">
        <GridRow num_of_columns={4}>
          <GridColumn span={3}>
            <DetailItem title="Email Token" />
          </GridColumn>
          <Switch
            checked={auth_config?.otp?.email}
            checkedText="Yes"
            uncheckedText="No"
            onChange={(value) => handleToggle('email', value)}
            disabled={!is_super_admin}
          />
        </GridRow>
      </BasicInfoSection>
    </>
  );
};
