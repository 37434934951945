/** */
import { urls } from '../_url';
import { apiGet, apiPut } from '../_http';

/** */
const base_url = `${urls.mailing}/configurations`;

export const generateRecords = async (options) => {
  return await apiPut(`${base_url}/dns/generation`, { ...options });
};

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const updateById = async (id, options = {}) => {
  return await apiPut(`${base_url}/${id}`, { ...options });
};
