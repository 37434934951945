import React, { useState, useContext, useEffect } from 'react';
import dateFormat from 'dateformat';
import { SocketContext } from 'app/hooks/socket';

import { Icon } from 'assets/icons';
import './notification.css';

const Notifications = () => {
  const { notifications, isLoading } = useContext(SocketContext);
  const [showNotification, setShowNotification] = useState(false);

  const displayNotifications = (notification) => {
    return (
      <li key={notification._id}>
        <p className="notification-type">{notification.event_code}</p>
        <p className="notification-message">{notification.message}</p>
        <p>{dateFormat(notification.created_on, 'dd-mm-yy, h:MM TT')}</p>
      </li>
    );
  };

  useEffect(() => {
    window.addEventListener(
      'click',
      () => {
        if (showNotification) {
          setShowNotification(() => false);
        }
      },
      { once: true }
    );
  }, [showNotification]);

  const toggleShowNotification = (e) => {
    setShowNotification((showNotification) => !showNotification);
  };

  return (
    <>
      <div onClick={(e) => toggleShowNotification(e)}>
        <Icon name="notifications" />
      </div>

      {showNotification && !isLoading && notifications.length > 0 && (
        <div className="notifications">
          {<ul>{notifications.map((notification) => displayNotifications(notification))}</ul>}
        </div>
      )}
      {showNotification && !isLoading && !notifications.length && (
        <div className="notifications">
          <div className="no-notification">
            <Icon name="notifications_off" />
            <p>No notification.</p>
          </div>
        </div>
      )}

      {showNotification && isLoading && (
        <div className="notifications loader-container">
          <p>loading...</p>
        </div>
      )}
    </>
  );
};

export default Notifications;
