import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ContactService from 'services/contact';
import * as TemplateService from 'services/template';

export const useContactService = () => {
  const { tenant_id, token } = useSelector((store) => store.user_data);

  const createContact = async (options = {}) => {
    const { error, payload } = await ContactService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    toast.success('Contact created successfully');
    return payload;
  };

  const downloadTemplate = async () => {
    return await TemplateService.download('contact', { token });
  };

  const fetchAudienceContacts = async (audience_ids = '', options = {}) => {
    const { error, payload } = await ContactService.readAudienceContacts(audience_ids, {
      ...options,
      token
    });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return { contacts: [], size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size };
  };

  const fetchContact = async (id, options = {}) => {
    const { error, payload } = await ContactService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return null;
    }

    return payload;
  };

  const fetchContacts = async (options = {}) => {
    const { error, payload } = await ContactService.read({ ...options, token });
    if (error) {
      return { contacts: null, error, size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size, error: null };
  };

  const fetchContactsBulk = async (options = {}) => {
    const { error, payload } = await ContactService.readBulk({
      ...options,
      data: { ...options.data, tenant_id },
      token
    });
    if (error) {
      return { contacts: [], error, size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size, error: null };
  };

  const fetchComparativeReport = async (options = {}) => {
    const { error, payload } = await ContactService.readComparativeReport({
      ...options,
      token
    });
    if (error) {
      return { report: [], error: 'Unable to fetch comparative report at this time.' };
    }

    return { report: payload, error: null };
  };

  const fetchDistributionReports = async (options = {}) => {
    const { error, payload } = await ContactService.readDistributionReports({
      ...options,
      token
    });
    if (error) {
      return { distribution: [], error: 'Unable to fetch distribution report at this time.' };
    }

    return { distribution: payload, error: null };
  };

  const fetchPerformanceReport = async (options = {}) => {
    const { error, payload } = await ContactService.readPerformanceReport({
      ...options,
      token
    });
    if (error) {
      return { report: {}, error: 'Unable to fetch performance report at this time.' };
    }

    return { report: payload };
  };

  const searchContact = async (keys, keyword, options = {}) => {
    const { error, payload } = await ContactService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch contacts at this time.');
      return { contacts: [], size: 0 };
    }

    const {
      data: contacts,
      meta: { size }
    } = payload;
    return { contacts, size };
  };

  const updateContact = async (id, options = {}) => {
    const { error, payload } = await ContactService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const uploadContacts = async (options = {}) => {
    const { error, payload } = await ContactService.upload({
      ...options,
      token,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/form-data'
      }
    });

    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };
  const removeContact = async (id) => {
    const { error } = await ContactService.deleteById(id, { token });
    if (error) {
      return toast.error('could not delete contact.');
    }
  };

  const removeContacts = async (options = {}) => {
    const { error } = await ContactService.deleteBulk({
      ...options,
      token
    });
    if (error) {
      return toast.error('could not delete contacts.');
    }
  };

  return {
    createContact,
    downloadTemplate,
    fetchAudienceContacts,
    fetchContact,
    fetchContacts,
    fetchContactsBulk,
    fetchComparativeReport,
    fetchDistributionReports,
    fetchPerformanceReport,
    searchContact,
    updateContact,
    uploadContacts,
    removeContact,
    removeContacts
  };
};
