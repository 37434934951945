import { PrimaryStatCard } from 'app/shared/cards';
import { getMonthTimeRange } from 'app/shared/utils/date';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useMailActionService } from 'hooks/mailaction';
import { useMailLogService } from 'hooks/maillog';
import React, { useEffect, useState } from 'react';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';

export const CampaignStatBar = () => {
  const { start, end } = getMonthTimeRange();
  const { fetchCampaigns } = useCampaignService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchMailActions } = useMailActionService();

  const [campaign_count, setCampaignCount] = useState({
    all_time: 0,
    monthly: 0
  });

  const [emails_count, setEmailsCount] = useState({
    all_time: 0,
    monthly: 0
  });

  const [emails_opens, setEmailOpens] = useState({
    all_time: 0,
    monthly: 0
  });

  const [link_clicks, setLinkClicks] = useState({
    all_time: 0,
    monthly: 0
  });

  useEffect(() => {
    fetchCampaigns({ query_string: `status=processed&return_only=id&bool=-is_test_campaign` }).then(
      ({ campaigns, size, error }) => {
        if (error) {
          toast.error('Unable to fetch campaigns at this time.');
          return;
        }
        if (size === 0) return;

        setCampaignCount((curr_count) => ({
          ...curr_count,
          all_time: size
        }));

        const campaign_ids = campaigns.map((campaign) => campaign.id);
        fetchMailLogs({
          query_string: `class=campaign&resource_id=${campaign_ids.join(',')}&count=1`
        }).then(({ size, error }) => {
          if (error) return toast.error(error);
          setEmailsCount((curr_count) => ({
            ...curr_count,
            all_time: size
          }));
        });

        fetchMailActions({
          query_string: `resource_type=campaign&resource_id=${campaign_ids.join(
            ','
          )}&action=opened&count=1`
        }).then(({ error, size }) => {
          if (error) return toast.error(error);
          setEmailOpens((curr_count) => ({
            ...curr_count,
            all_time: size
          }));
        });

        fetchMailActions({
          query_string: `resource_type=campaign&resource_id=${campaign_ids.join(
            ','
          )}&action=clicked_link&count=1`
        }).then(({ error, size }) => {
          if (error) return toast.error(error);
          setLinkClicks((curr_count) => ({
            ...curr_count,
            all_time: size
          }));
        });
      }
    );

    fetchCampaigns({
      query_string: `status=processed&time_stamp=${start}~${end}&bool=-is_test_campaign`
    }).then(({ campaigns, size, error }) => {
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }

      if (size === 0) return;

      setCampaignCount((curr_count) => ({
        ...curr_count,
        monthly: size
      }));

      const campaign_ids = campaigns.map((campaign) => campaign.id);
      fetchMailLogs({
        query_string: `class=campaign&resource_id=${campaign_ids.join(',')}&count=1`
      }).then(({ error, size }) => {
        if (error) return toast.error(error);
        setEmailsCount((curr_count) => ({
          ...curr_count,
          monthly: size
        }));
      });

      fetchMailActions({
        query_string: `resource_type=campaign&resource_id=${campaign_ids.join(
          ','
        )}&action=opened&count=1`
      }).then(({ error, size }) => {
        if (error) return toast.error(error);
        setEmailOpens((curr_count) => ({
          ...curr_count,
          monthly: size
        }));
      });

      fetchMailActions({
        query_string: `resource_type=campaign&resource_id=${campaign_ids.join(
          ','
        )}&action=clicked_link&time_stamp=${start}~${end}&count=1`
      }).then(({ error, size }) => {
        if (error) return toast.error(error);
        setLinkClicks((curr_count) => ({
          ...curr_count,
          monthly: size
        }));
      });
    });
  }, []);

  const statDetails = [
    {
      main_stat: {
        label: 'Total Campaigns',
        value: campaign_count.all_time
        // color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This Month', value: campaign_count.monthly }
    },
    {
      main_stat: {
        label: 'Total Emails Sent',
        value: emails_count.all_time
        // color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: emails_count.monthly }
    },
    {
      main_stat: {
        label: 'Total Emails Opens',
        value: emails_opens.all_time
        // color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: emails_opens.monthly }
    },
    {
      main_stat: {
        label: 'Total Link Clicks',
        value: link_clicks.all_time
        // color: 'var(--orange-primary)'
      },
      bottom_stat: { label: 'This month', value: link_clicks.monthly }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
    </>
  );
};
