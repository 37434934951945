import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SimpleInput } from 'app/shared/input';
import { Button } from 'app/shared/button';
import { Alert } from 'app/shared/form-alert';
import styles from '../guest.module.css';
import { ReactComponent as Icon } from 'assets/icons/auth/ResetPasswordIcon.svg';
import { isEmailValid } from 'app/shared/utils/input';
import { generateUniqueId } from 'app/shared/utils/general';
import { useGuestService } from 'hooks/guest';

const PasswordRecovery = () => {
  const { recoverPassword } = useGuestService();
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({ email: '' });
  const [is_loading, setIsLoading] = useState(false);
  const [is_request_disabled, setRequestDisabled] = useState(false);
  const [request_timeout, setRequestTimeout] = useState(null);
  const [errors, setErrors] = useState({
    email: ''
  });
  const history = useNavigate();

  const validateEmail = (value) => {
    let errorMessage = '';
    if (!value.trim()) {
      errorMessage = 'Email address is required.';
    } else if (!isEmailValid(value)) {
      errorMessage = 'Invalid email address.';
    }
    setErrors({ email: errorMessage });
  };

  const handleEmailChange = (value) => {
    setFormData({ email: value });
    validateEmail(value);
  };

  const submitForm = () => {
    if (disableSubmitButton) {
      validateEmail(form_data.email);
      return;
    }

    if (is_request_disabled) {
      setAlert({
        message: 'Please wait for few minutes before requesting again.',
        id: generateUniqueId()
      });
      return;
    }

    setIsLoading(true);
    recoverPassword({ data: form_data })
      .then((response) => {
        const { error, payload } = response;

        if (error) {
          setAlert({ message: error, id: generateUniqueId() });
          return;
        }

        setAlert({
          message: payload,
          type: 'success'
        });

        setRequestDisabled(true);
        const timeout = setTimeout(() => {
          setRequestDisabled(false);
        }, 60000);
        setRequestTimeout(timeout);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    return () => {
      clearTimeout(request_timeout);
    };
  }, []);

  const isFormDisabled = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    const isFieldsIncomplete = Object.values(values).some((value) => !value.trim());
    return hasErrors || isFieldsIncomplete;
  };

  const disableSubmitButton = isFormDisabled(errors, { password: form_data.email });

  return (
    <div className={styles.authWrapper}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      <div>
        <h1>Forgot Password? We got you!</h1>
        <p>
          Enter your registered email address and you will receive a link to create a new password
        </p>
        <form>
          <Alert {...alert} />
          <SimpleInput
            type="email"
            placeholder="Email`"
            onInput={handleEmailChange}
            value={form_data.email}
            className={styles.formInput}
            wrapperClassName={styles.inputMargin}
            error={errors.email}
          />
          <Button
            className={styles.formBtn}
            onClick={submitForm}
            text="Reset Password"
            loading={is_loading}
          />
          <div className={styles.formText} onClick={() => history('/login')}>
            Back to <b>Login</b>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecovery;
