import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useContactService } from 'hooks/contact';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useSMSLogService } from 'hooks/messaging/logs/sms';
import { Badge } from 'app/shared/badge';

export const SMSMessageLogs = ({ id: message_id }) => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchContacts, searchContact } = useContactService();
  const { fetchSMSLogs } = useSMSLogService();

  const [logs, setLogs] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_logs, setTotalLogs] = useState(0);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'recipient',
        formatter: (value) => (value ? value.firstname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'recipient',
        formatter: (value) => (value ? value.lastname : 'N/A'),
        isTitle: true
      },
      {
        title: 'Phone number',
        key: 'recipient',
        isTagline: true,
        formatter: (value) => value.phone_number || 'N/A'
      },
      {
        title: 'Time sent',
        key: 'time_stamp',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => <Badge type={value === 'failed' ? 'red' : 'green'} text={value} />
      }
    ],
    is_search_mode,
    items: Object.values(logs).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);

    const { logs: message_logs, size } = await fetchSMSLogs({
      query_string: `resource_id=${message_id}&page=${page}&population=${population}`
    });

    const contact_ids = message_logs.map((action) => action.recipient_id);
    const { contacts } = await fetchContacts({ query_string: `id=${contact_ids.join()}` });
    const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});

    const logs = message_logs.map((log) => ({
      ...log,
      recipient: contacts_by_id[log.recipient_id]
    }));

    setLogs((curr_log) => ({
      ...curr_log,
      ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
    }));
    setTotalLogs(() => size);

    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }

    setLoading(true);
    try {
      const { contacts } = await searchContact(keys, keyword, {
        query_string: `page=${page}&population=${population}&resource_id=${message_id}`
      });

      if (contacts.length) {
        const contacts_by_id = contacts.reduce((s, c) => ({ ...s, [c.id]: c }), {});
        const contact_ids = contacts.map((c) => c.id);
        const { maillogs, size } = await fetchSMSLogs({
          query_string: `resource_id=${message_id}&recipient_id=${contact_ids.join()}&page=${page}&population=${population}`
        });

        const logs = maillogs.map((log) => ({
          ...log,
          recipient: contacts_by_id[log.recipient_id]
        }));

        if (is_search_mode) {
          setLogs((curr_log) => ({
            ...curr_log,
            ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
          }));
        } else {
          setLogs(() => ({
            ...logs.reduce((s, a) => ({ ...s, [a.time_stamp]: a }), {})
          }));
          setSearchMode(true);
        }
        setTotalLogs(() => size);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            action={() => {}}
            onClick={() => {}}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              total_count: total_logs
            }}
            action={() => {}}
            onClick={() => {}}
            checkbox
            loading_data={loading}
            table_actions={[]}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
