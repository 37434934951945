import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { Route, Routes } from 'react-router-dom';
import { RoleCreationPage } from './roles/create';
import { UsersModuleDisplay } from './display';

const UsersModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Users', path: '.' }]));
  }, [dispatch]);

  return (
    <PageContentWrapper>
      <Routes>
        <Route path="roles/:id" element={<RoleCreationPage />} />
        <Route path="/*" element={<UsersModuleDisplay />} />
      </Routes>
    </PageContentWrapper>
  );
};

export default UsersModule;
