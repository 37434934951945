export const DelaySchema = {
  interval: {
    pattern: /[\d]/,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (Number(value) >= 0) {
        result.is_valid = true;
      } else result.message = 'Interval must be a positive number';

      return result;
    }
  }
};
