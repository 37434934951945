import React from 'react';

export const Spacer = ({ multiple = 1 }) => {
  const style = {
    display: 'block',
    width: '100%',
    paddingBottom: `${multiple * 5}px`
  };

  return <div style={style}></div>;
};
