import React from 'react';
import styles from './info-section.module.css';

export const BasicInfoSection = ({ title, icon = null, children }) => {
  return (
    <div className={styles.detailsWrapper}>
      {title && (
        <header>
          <span>{title}</span>
          {icon}
        </header>
      )}
      <div className={styles.statSummary}>{children}</div>
    </div>
  );
};
