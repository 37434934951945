import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { Spacer } from 'app/layouts/generic';
import { TransactionalOverview } from './sections/overview';
import { ClicksLogs } from './sections/clicks';
import { OpenLogs } from './sections/opens';
import { BounceLogs } from './sections/bounces';
import { useTransactionalService } from 'hooks/transactional';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { TransactionalPreview } from './sections/preview';

const getNavItems = (transactional_id) => [
  {
    title: 'Overview',
    path: `/transactionals/${transactional_id}/`
  },
  {
    title: 'Details',
    path: `/transactionals/${transactional_id}/details`
  },
  {
    title: 'Opens log',
    path: `/transactionals/${transactional_id}/opens`
  },
  {
    title: 'Clicks log',
    path: `/transactionals/${transactional_id}/clicks`
  },
  {
    title: 'Bounce log',
    path: `/transactionals/${transactional_id}/bounces`
  }
];

export const TransactionalViewPage = () => {
  const { id } = useParams();
  const { fetchTransactionalById } = useTransactionalService();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTransactionalById(id).then(({ transactional }) => {
      dispatch(
        setPageTitle([
          { title: 'My Transactionals', path: '/transactionals' },
          { title: transactional.name, path: `/transactionals/${id}` }
        ])
      );
    });
  }, [id]);

  return (
    <PageContentWrapper>
      <SlidingNav nav_items={getNavItems(id)} />
      <Spacer multiple={4} />
      <div className="dt-content-wrapper">
        <Routes>
          <Route path="bounces" element={<BounceLogs />} />
          <Route path="clicks" element={<ClicksLogs />} />
          <Route path="details" element={<TransactionalPreview />} />
          <Route path="opens" element={<OpenLogs />} />
          <Route path="/*" element={<TransactionalOverview />} />
        </Routes>
      </div>
    </PageContentWrapper>
  );
};
