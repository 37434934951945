import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { useAutomationJourneyService } from 'hooks/automation/journey';
import { useAutomationTemplateService } from 'hooks/automation/template';
import React, { useEffect, useState } from 'react';

export const AutomationStatBar = () => {
  const { fetchTemplates } = useAutomationTemplateService();
  const { fetchJourneys } = useAutomationJourneyService();

  const [template_stat, setTemplateStat] = useState({ active: 0, inactive: 0 });
  const [journey_stat, setJourneyStat] = useState({
    active: 0,
    completed: 0,
    active_ratio: 0,
    completed_ratio: 0
  });

  useEffect(() => {
    Promise.all([
      fetchTemplates({ query_string: 'status=active&count=1' }),
      fetchTemplates({ query_string: 'status=inactive&count=1' })
    ]).then(([{ size: active }, { size: inactive }]) => {
      setTemplateStat(() => ({ active, inactive }));
    });

    Promise.all([
      fetchJourneys({ query_string: 'count=1' }),
      fetchJourneys({ query_string: 'status=started&count=1' }),
      fetchJourneys({ query_string: 'status=completed&count=1' })
    ]).then(([{ size: all_journeys }, { size: active }, { size: completed }]) => {
      setJourneyStat(() => ({
        active,
        completed,
        active_ratio:
          !!active && !!all_journeys ? Number((active / all_journeys).toFixed(2)) * 100 : 0,
        completed_ratio:
          !!completed && !!all_journeys ? Number((completed / all_journeys).toFixed(2)) * 100 : 0
      }));
    });
  }, []);

  return (
    <SlidingStatBar>
      <PrimaryStatCard
        main_stat={{ label: 'Active automations', value: template_stat.active }}
        bottom_stat={{ label: 'Inactive', value: template_stat.inactive }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Active journeys', value: journey_stat.active }}
        bottom_stat={{ label: 'Ratio', value: `${journey_stat.active_ratio}%` }}
      />
      <PrimaryStatCard
        main_stat={{ label: 'Completed journeys', value: journey_stat.completed }}
        bottom_stat={{ label: 'Ratio', value: `${journey_stat.completed_ratio}%` }}
      />
    </SlidingStatBar>
  );
};
