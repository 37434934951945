import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import styles from './campaign.module.css';
import { truncateText } from 'app/shared/utils/general';
import useScreenSize from 'hooks/size';
import { useCampaignService } from 'hooks/mailing/campaign';

export const AudienceCampaign = () => {
  const { id: audience_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchCampaigns } = useCampaignService();

  const [clear_table, setClearTable] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_campaigns, setTotalCampaigns] = useState(0);
  const { isTablet } = useScreenSize();

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Campaign Name',
        key: 'name',
        isTitle: true,
        formatter: (value) => truncateText(value, 25) || ''
      },
      {
        title: 'Sender Name',
        key: 'sender_name',
        isTagline: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Sender Email',
        key: 'sender_email',
        isTagline: true,
        formatter: (value) => {
          return truncateText(value, isTablet ? 25 : 35) || 'N/A';
        }
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          switch (value) {
            case 'processed':
              return <span className={styles.greenBadge}>{value}</span>;
            case 'processing':
            case 'queued':
              return <span className={styles.infoBadge}>{value}</span>;
            case 'cancelled':
              return <span className={styles.dangerBadge}>{value}</span>;
            default:
              return <span className={styles.dangerBadge}>{value}</span>;
          }
        },
        isBadge: true
      },
      {
        title: 'Send date',
        key: 'schedule',
        formatter: (value) => {
          if (!value?.date) return '...';
          return new Date(value.date).toDateString();
        },
        isMetadata: true
      }
    ],
    clear_table,
    is_search_mode,
    items: campaigns.sort((a, b) => b.time_stamp - a.time_stamp),

    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = async (action) => {
    const { name, type } = action;

    if (type.toLowerCase() === 'bulk') {
      switch (name.toLowerCase()) {
        default:
      }
    }

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        default:
      }
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    if (loading) return;
    setLoading(true);
    const { campaigns, size } = await fetchCampaigns({
      query_string: `mailing_lists=${audience_id}&page=${page}&population=${population}&sort_by=-created_on&return_only=is_advanced,id,name,sender_name,sender_email,status,schedule,failed_sends_list,created_on,is_test_campaign`
    });
    setCampaigns(campaigns);
    setTotalCampaigns(() => size);

    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }
    setLoading(true);

    const { campaigns, size } = await fetchCampaigns({
      query_string: `mailing_lists=${audience_id}&page=${page}&population=${population}&&search_key=${keys}&search_keyword=${keyword}&sort_by=-created_on`
    });

    setClearTable(() => false);
    if (is_search_mode) {
      setCampaigns(campaigns);
    } else {
      setCampaigns(campaigns);
      setSearchMode(true);
    }

    setTotalCampaigns(() => size);
    setLoading(false);
  };

  return (
    <div className="contactPage">
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              action={() => {}}
              onClick={() => {}}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: total_campaigns
              }}
              action={handleDatatableAction}
              onClick={() => {}}
              checkbox
              loading_data={loading}
              table_actions={[]}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
            />
          )}
        </GridColumn>
      </GridRow>
    </div>
  );
};
