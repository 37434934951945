import { GridColumn, GridRow } from 'app/layouts/grid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PlanDetails } from './plan/details';
import { Billinginformation } from './info/display';
import { PaymentMethod } from './payment-method/payment-method.js';
import { Spacer } from 'app/layouts/generic';
import { InvoiceList } from './invoice/invoice';

export const Billing = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/billing' },
        { title: 'Billing', path: '/settings/billing' }
      ])
    );
  }, []);

  return (
    <GridRow
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn>
        <PlanDetails />
        <Spacer multiple={3} />
        <PaymentMethod />
      </GridColumn>
      <GridColumn span={2}>
        <Billinginformation />
        <Spacer multiple={3} />
        <InvoiceList />
      </GridColumn>
    </GridRow>
  );
};
