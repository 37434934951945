import { Button } from 'app/shared/button';
import React, { useEffect, useState } from 'react';
import { Column } from './Column';
import styles from './selector.module.css';
import * as AudienceService from 'services/audience';

import { MultiSelect } from 'app/shared/select';
import { useSelector } from 'react-redux';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';

const CreateWebContactMapper = ({ selected_audiences, setSelectedAudiences, generateCode }) => {
  const [audiences, setAudiences] = useState([]);
  const [data] = useState([{ id: 1, mappings: [{ email: 'email' }] }]);
  const [mapping_config, setMappingConfig] = useState({});
  const [mapping_components, setMappingComponents] = useState([]);
  const { user_data } = useSelector((state) => state);
  const { token } = user_data;

  useEffect(() => {
    AudienceService.read({ token, query_string: 'is_archived=false&return_only=name,id,' })
      .then((response) => {
        const {
          error,
          payload: { data }
        } = response;
        if (error) return;

        setAudiences(data);
      })
      .catch((e) => e);
  }, [token]);

  useEffect(() => {
    if (data) {
      const { mappings } = data[0];
      const mapping_components = mappings.map((mapping, index) =>
        buildContactColComponent(index, mapping)
      );
      setMappingComponents(mapping_components);
    }
  }, []);

  const addContactColComponent = () => {
    const list = [...mapping_components, buildContactColComponent(mapping_components.length)];
    setMappingComponents(list);
  };

  const buildContactColComponent = (id, data) => {
    return (
      <Column
        hide={true}
        key={id}
        id={id}
        data={data}
        onChange={handleContactColChange}
        onRemove={handleRemoveContactCol}
      />
    );
  };

  const handleContactColChange = (data) => {
    const { key, field_name: user_defined_value, internal_field } = data;
    setMappingConfig((col) => ({
      ...col,
      [key]: {
        property: user_defined_value,
        field: internal_field
      }
    }));
  };

  const handleRemoveContactCol = (id) => {
    setMappingComponents((mapping_components) =>
      mapping_components.filter((mapping) => mapping.props.id !== id)
    );
    setMappingConfig((col) => {
      delete col[id];
      return { ...col };
    });
  };

  const submit = () => {
    const mapped_data = processFieldMapper(mapping_config);
    generateCode(mapped_data);
  };

  const processFieldMapper = (fields) => {
    const result = Object.values(fields).reduce((prev, curr) => {
      if (!curr.property) return prev;
      return [...prev, { [curr.field.value]: curr.property }];
    }, []);
    return result;
  };

  return (
    <GridRow num_of_columns={1}>
      <GridColumn>
        <DetailItem title="Audiences">
          <MultiSelect
            options={audiences.map((list) => ({
              label: list.name,
              value: list.id
            }))}
            onChange={setSelectedAudiences}
            value={selected_audiences}
            isMulti
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>{mapping_components}</GridColumn>
      <GridColumn>
        <GridRow num_of_columns={2}>
          <GridColumn>
            <Button
              icon_name="add"
              onClick={addContactColComponent}
              text="Add field"
              type="secondary"
              className={styles.btn}
            />
          </GridColumn>
          <GridColumn>
            <Button
              text="Generate Snippet"
              disabled={mapping_components.length === 0}
              onClick={submit}
            />
          </GridColumn>
        </GridRow>
      </GridColumn>
    </GridRow>
  );
};

export default CreateWebContactMapper;
