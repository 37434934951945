import { urls } from '../_url';
import { apiGet } from '../_http';

const base_url = `${urls.automata}/logs/events`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};

export const search = async (keys, keyword, options = {}) => {
  const query_string = options.query_string
    ? `${options.query_string}&keyword=${keyword}`
    : `keyword=${keyword}`;
  return await apiGet(`${base_url}/search/${keys}`, {
    ...options,
    query_string
  });
};
