import styles from './neuron.module.css';
import React, { useEffect, useState } from 'react';
import { Icon } from 'assets/icons';
import { Switch } from '@arco-design/web-react';
import GmModal from 'app/shared/modal/modal';
import { NeuronForm } from '../form';
import { useResourceQuery } from 'hooks/automation/resources';
import { serializeAction } from 'app/member/editors/automation/components/widget/serializer';
import { truncateText } from 'app/shared/utils/general';
import { useNeuronService } from 'hooks/automation/neuron';
import { toast } from 'react-toastify';

export const NeuronCard = ({ data = {} }) => {
  const { fetchResources } = useResourceQuery();
  const { updateNeuron } = useNeuronService();

  const [is_active, setIsActive] = useState(false);
  const [resource_name, setResourceName] = useState('');
  const [show_creation_form, setShowCreationForm] = useState(false);

  useEffect(() => {
    if (!data.event || !data.source) return;

    const [resource_class, resource_id] = data.source.split(':');
    fetchResources(resource_class, '', {
      query_string: `id=${resource_id}&return_only=name,title,link`
    }).then(({ resources }) => {
      const [resource] = resources;
      if (!resource) return;
      setResourceName(() => resource.name || resource.title || resource.link);
    });

    if (data && data.status) setIsActive(() => data.status === 'active');
  }, [data]);

  const handleChange = async () => {
    const status = is_active ? 'inactive' : 'active';
    const new_data = { status };
    const { neuron } = await updateNeuron(data.id, { data: new_data });
    if (neuron) {
      toast.success(`Neuron ${is_active ? 'deactivated' : 'activated.'}`);
      setIsActive((active) => !active);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.actions}>
            <Icon name="edit" onClick={() => setShowCreationForm(true)} />
            <Switch
              className={!is_active && styles.switch}
              checked={is_active}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.bodyWrapper}>
          <span className={styles.actionIcon}>
            <Icon name="bolt" />
          </span>
          <span>
            When a contact {serializeAction(data.event || 'none', truncateText(resource_name, 30))}
          </span>
        </div>
      </div>
      {show_creation_form && (
        <GmModal
          className={styles.formModal}
          position="right"
          show_title
          show_modal={show_creation_form}
          title="Create Neuron"
          onClose={() => setShowCreationForm(false)}
        >
          <NeuronForm data={data} />
        </GmModal>
      )}
    </>
  );
};
