import React from 'react';
import { formatDateForDisplay } from 'app/shared/utils/date';
import styles from './preview.module.css';
import { Spacer } from 'app/layouts/generic';

export const AttachmentDetails = ({ attachments }) => {
  return (
    <div className={styles.detailsWrapper}>
      <header>Attachments</header>
      <div className={styles.statSummary}>
        {attachments.map((attachment) => (
          <>
            <div key={attachment.name}>{attachment.name}</div>
            <Spacer multiple={2} key={`spacer-${attachment.name}`} />
          </>
        ))}
      </div>
    </div>
  );
};

const DetailItem = ({ title = '', children }) => {
  return (
    <div className={styles.item}>
      <p>{title}</p>
      {children}
    </div>
  );
};

export const CampaignDetails = ({ campaign, audiences }) => {
  return (
    <div className={styles.detailsWrapper}>
      <header>Basic Details</header>
      <div className={styles.statSummary}>
        <DetailItem title="Name">
          <p>{campaign.name}</p>
        </DetailItem>
        <DetailItem title="subject">
          <p>{campaign.subject}</p>
        </DetailItem>
        <DetailItem title="sender name">
          <p>{campaign.sender_name}</p>
        </DetailItem>
        <DetailItem title="sender email">
          <p>{campaign.sender_email}</p>
        </DetailItem>
        <DetailItem title="schedule date">
          <p>{campaign.schedule ? formatDateForDisplay(campaign.schedule.date) : 'N/A'}</p>
        </DetailItem>
        <DetailItem title="audiences">
          <ul>
            {audiences.map((item, i) => (
              <li key={i}> {item.name} </li>
            ))}
          </ul>
        </DetailItem>
      </div>
    </div>
  );
};
