import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { useMembershipService } from 'hooks/iam/membership';
import { useRoleService } from 'hooks/iam/role';
import useScreenSize from 'hooks/size';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const MembershipRoleConfig = ({
  membership_data = {},
  closeModal = () => {},
  onSave = () => {}
}) => {
  const { updateMembership } = useMembershipService();
  const { fetchRoles } = useRoleService();
  const { isMobile } = useScreenSize();

  const [is_saving, setIsSaving] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selected_role, setSelectedRole] = useState({});

  useEffect(() => {
    fetchRoles().then(({ roles, error }) => {
      if (error) return toast.error(error);
      setRoles(() =>
        roles.map((role) => ({
          ...role,
          label: role.name,
          value: role.id
        }))
      );
    });

    return () => {
      setSelectedRole({});
    };
  }, [membership_data.user_email]);

  const submit = async () => {
    const data = { role_id: selected_role.value };
    setIsSaving(true);
    await updateMembership(membership_data.id, { data });
    setIsSaving(false);

    //
    onSave();
    closeModal();
  };

  return (
    <>
      <GridRow num_of_columns={isMobile ? 1 : 2}>
        <GridColumn>
          <DetailItem title="Email address">{membership_data?.user_email || 'N/A'}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Current role">{membership_data?.role?.name || 'N/A'}</DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="choose role">
            <MultiSelect options={roles} value={selected_role} onChange={setSelectedRole} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={isMobile ? 1 : 3}>
        {!isMobile && (
          <>
            <GridColumn />
            <GridColumn />
          </>
        )}
        <GridColumn>
          <Button text="Update role" onClick={submit} disabled={is_saving} />
        </GridColumn>
      </GridRow>
    </>
  );
};
