import React, { useEffect, useState } from 'react';
import styles from './purchase.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { usePlanService } from 'hooks/sales/plan';
import { useContactService } from 'hooks/contact';
import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { useTaxService } from 'hooks/sales/tax';
import { useCurrencyService } from 'hooks/currency';
import { toast } from 'react-toastify';
import { toReadableNumber } from 'app/shared/utils/number';

export const PurchaseSubscription = ({ closeModal, onSave = () => {} }) => {
  const { fetchTaxes } = useTaxService();
  const { fetchContacts } = useContactService();
  const { fetchCurrencies } = useCurrencyService();
  const { fetchPlans } = usePlanService();

  const period = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'annual' }
  ];

  const [renewal_period, setRenewalPeriod] = useState({});
  const [selected_currency, setSelectedCurrency] = useState('');
  const [plans, setPlans] = useState([]);
  const [selected_plan, setSelectedPlan] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [plan_details, setPlanDetails] = useState({
    cost: 0,
    discount: 0,
    discount_rate: 0,
    discounted_price: 0,
    period: 'monthly',
    tax_rate: 0
  });

  useEffect(() => {
    fetchTaxes().then(({ taxes, error }) => {
      if (error) return toast.error(error);
      setTaxes(taxes);
    });
  }, []);

  useEffect(() => {
    fetchCurrencies().then(({ currencies, error }) => {
      if (error) {
        toast.error('Unable to fetch currencies at this time.');
        return;
      }
      if (currencies.length) {
        const mapped_currency = currencies.map((curr) => {
          return {
            ...curr,
            label: curr.code,
            value: curr.code
          };
        });
        setCurrencies(mapped_currency);
        setSelectedCurrency(mapped_currency.find((option) => option.value === 'NGN'));
      }
    });
  }, []);

  useEffect(() => {
    determinePlanPrice();
  }, [selected_plan, renewal_period, selected_currency]);

  useEffect(() => {
    fetchContacts({ query_string: `count=1` }).then(({ error, size }) => {
      if (error) {
        toast.error('Unable to fetch contact at this time.');
        return;
      }
      return fetchPlans().then(({ plans, error }) => {
        if (error) return toast.error(error);

        const valid_plans = determineValidPlans(plans, size);

        setPlans(valid_plans);
        setSelectedPlan(valid_plans[0] || {});
        setRenewalPeriod({ label: 'Monthly', value: 'monthly' });
      });
    });
  }, []);

  const determinePlanPrice = () => {
    if (
      !selected_currency ||
      !selected_currency.value ||
      !renewal_period ||
      !renewal_period.value ||
      !selected_plan ||
      !selected_plan.value
    )
      return;

    const currency = selected_currency.value;
    const { prices, discounts } = selected_plan;
    let cost = prices.find((p) => p.currency === currency)?.amount || 0;
    let discount_rate = discounts.month;

    if (renewal_period.value === 'annual') {
      cost *= 12;
      discount_rate = discounts.annual;
    }

    const { tax: tax_rate } = taxes.find((tax) => tax.currency === currency) || { tax: 0 };
    const discount = Number((cost * discount_rate) / 100);
    const discounted_price = Number(cost - discount);
    const tax = Number((discounted_price * Number(tax_rate)) / 100);

    setPlanDetails(() => ({
      amount_to_pay: Number(discounted_price) + Number(tax),
      cost,
      discount,
      discount_rate,
      discounted_price,
      period: renewal_period.value,
      tax,
      tax_rate
    }));
  };

  const determineValidPlans = (plans, contact_size) => {
    const parsed_plans = [];
    plans.forEach((plan) => {
      const [min, max] = plan.contact_bracket.split(':').map(Number);
      parsed_plans.push({
        ...plan,
        min,
        contact_bracket: { min, max },
        label: `${min.toLocaleString()} - ${max.toLocaleString()}`,
        value: plan.code
      });
    });

    const valid_plans = parsed_plans.filter(
      ({ contact_bracket }) => Number(contact_bracket.max) >= contact_size
    );

    return valid_plans.sort((a, b) => Number(a.min) - Number(b.min));
  };

  const handleProceed = () => {
    const checkout_data = {
      ...plan_details,
      renewal_period: renewal_period.value,
      currency: selected_currency,
      plan: selected_plan
    };

    onSave(checkout_data);
    closeModal();
  };

  return (
    <>
      <GridRow num_of_columns={4}>
        <GridColumn span={3}>
          <DetailItem>
            <div>
              {selected_currency.label}{' '}
              <span className={styles.title}>
                {toReadableNumber(plan_details.discounted_price)}
              </span>{' '}
              / {renewal_period.value === 'monthly' ? 'month' : 'year'}
            </div>
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem>
            <MultiSelect
              className={styles.currency}
              options={currencies}
              onChange={setSelectedCurrency}
              value={selected_currency}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn span={2}>
          <DetailItem title="Number of contacts">
            <MultiSelect
              options={plans}
              onChange={setSelectedPlan}
              value={selected_plan}
              isSorted={false}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Renewal period">
            <MultiSelect
              options={period.map(({ label, value }) => ({
                label,
                value
              }))}
              onChange={setRenewalPeriod}
              value={renewal_period}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <ul className="m-0 pl-3">
        {selected_plan.features?.map((f, i) => (
          <li key={i}>{f}</li>
        ))}
      </ul>

      <Spacer multiple={2} />
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn>
          <Button text="Cancel" onClick={() => closeModal()} type="secondary" />
        </GridColumn>
        <GridColumn>
          <Button text="Save" onClick={handleProceed} />
        </GridColumn>
      </GridRow>
    </>
  );
};
