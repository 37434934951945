import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../guest.module.css';
import { Button } from 'app/shared/button';
import reset_icon from 'assets/icons/auth/reset_successful.png';

const PasswordResetSuccessful = () => {
  const history = useNavigate();

  return (
    <div className={styles.authSuccessWrapper}>
      <div className={styles.successScreen}>
        <img src={reset_icon} alt="Reset Password Icon" />
        <p>Password reset successful</p>
        <Button className={styles.formBtn} onClick={() => history('/login')} text="Login" />
      </div>
    </div>
  );
};

export default PasswordResetSuccessful;
