import React, { useState, useEffect, useRef } from 'react';

import './context-menu.css';

/**
 actionType = {
  action?: () => {},
  value: string | integer
  label: string,
  className?: string
  hidden: boolean
 }
 */
const ContextMenu = ({ actions, callback, text, position = 'left' }) => {
  const [i_was_clicked, setIWasClicked] = useState(false);
  const [show_menu, setShowMenu] = useState(false);
  const context_menu_label = useRef(null);
  const menu_wrapper = useRef(null);

  useEffect(() => {
    window.addEventListener(
      'click',
      () => {
        if (show_menu && !i_was_clicked) {
          setShowMenu(() => false);
        }
        if (show_menu) {
          setIWasClicked(() => false);
        }
      },
      { once: true }
    );

    if (menu_wrapper.current) {
      const menu_tray = menu_wrapper.current;

      const { offsetTop } = menu_wrapper.current;
      menu_tray.style.top = `${offsetTop + 5}px`;
      if (position === 'right') {
        menu_tray.style.right = '0px';
      }
    }
  }, [i_was_clicked]);

  const toggleShowMenu = (e) => {
    setIWasClicked(() => true);
    setShowMenu((show_menu) => !show_menu);
  };

  const selectAction = (action, e) => {
    callback(action);

    toggleShowMenu(e);
  };

  const renderAction = (action, index) => {
    if (action.hidden) return null;
    if (action && action.action) {
      return (
        <li
          className={`gm-context-menu-item ${action.className}`}
          key={index}
          onClick={(e) => selectAction(action.action, e)}
        >
          {action.label}
        </li>
      );
    }

    return (
      <li
        className="gm-context-menu-item"
        key={index}
        onClick={(e) => selectAction(action.value, e)}
      >
        <span>{action.label}</span>
      </li>
    );
  };

  return (
    <>
      <div
        ref={context_menu_label}
        className="context-menu-label"
        onClick={(e) => toggleShowMenu(e)}
      >
        {text || '...'}

        {actions && (
          <>
            {show_menu && (
              <div ref={menu_wrapper} className="gm-action-context-menu">
                <ul>{(actions || []).map((action, index) => renderAction(action, index))}</ul>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContextMenu;
