import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useAudienceService } from 'hooks/users/audience';
import { AttachmentDetails, CampaignDetails } from './details';
import { Spacer } from 'app/layouts/generic';
import styles from './preview.module.css';
import useScreenSize from 'hooks/size';
import { toast } from 'react-toastify';

export const CampaignPreview = () => {
  const { id } = useParams();
  const { fetchAudiences } = useAudienceService();
  const { fetchCampaign } = useCampaignService();
  const { isDesktop } = useScreenSize();

  const [campaign, setCampaign] = useState({});
  const [campaign_body, setCampaignBody] = useState('');
  const [campaign_audience, setCampaignAudience] = useState([]);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    fetchCampaign(id).then(({ campaign }) => {
      setCampaign(campaign);
      setCampaignBody(campaign.body);
      getCampaignAudience(campaign.mailing_lists);
      setAttachments(campaign.attachments);
    });
  }, []);

  const getCampaignAudience = (audience_ids = []) => {
    fetchAudiences({
      query_string: `id=${audience_ids.toString()}&return_only=name`
    }).then(({ audiences, error }) => {
      if (error) {
        toast.error('Unable to fetch audience at this time.');
        return;
      }
      setCampaignAudience(audiences);
    });
  };

  // const handleRetryCampaign = async () => {
  //   if (campaign.status !== 'processed') {
  //     return toast.error('Only processed campaigns can be resent.');
  //   }

  //   toast.info('Resending Campaign...');
  //   const retry_count = campaign.metadata.retry_count ? campaign.metadata.retry_count + 1 : 1;
  //   const response = await CampaignService.updateById(id, {
  //     data: {
  //       ...campaign,
  //       metadata: {
  //         ...campaign.metadata,
  //         retry_count
  //       },
  //       status: 'queued',
  //       is_enqueued: false
  //     },
  //     token
  //   });
  //   const { error } = response;
  //   if (error) {
  //     toast.error(error);
  //     return;
  //   }
  //   dispatch(addOneCampaignToStore({ ...campaign, status: 'queued' }));
  //   toast.success('Campaign requeued successfully');
  // };

  return (
    <>
      {!isDesktop && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <div className={cx(styles.iframeWrapper, 'shadow-sm campaign-view my-md-1')}>
                <iframe
                  className="w-100"
                  title="Campaign body"
                  style={{
                    width: '100%',
                    minHeight: '75vh',
                    padding: '15px',
                    background: 'var(--neutral-light)',
                    border: '1px solid var(--neutral-dark-5)',
                    borderRadius: '8px'
                  }}
                  srcDoc={campaign_body}
                ></iframe>
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn span={4}>
              <CampaignDetails campaign={campaign} audiences={campaign_audience} />
              <Spacer multiple={4} />
              {attachments?.length > 0 && <AttachmentDetails attachments={attachments} />}
            </GridColumn>
          </GridRow>
        </>
      )}

      {isDesktop && (
        <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
          <GridColumn span={3}>
            <div className={cx(styles.iframeWrapper, 'shadow-sm campaign-view my-md-1')}>
              <iframe
                className="w-100"
                title="Campaign body"
                style={{
                  width: '100%',
                  minHeight: '75vh',
                  padding: '15px',
                  background: 'var(--neutral-light)',
                  border: '1px solid var(--neutral-dark-5)',
                  borderRadius: '8px'
                }}
                srcDoc={campaign_body}
              ></iframe>
            </div>
          </GridColumn>

          <GridColumn span={1}>
            <CampaignDetails campaign={campaign} audiences={campaign_audience} />
            <Spacer multiple={4} />
            {attachments?.length > 0 && <AttachmentDetails attachments={attachments} />}
          </GridColumn>
        </GridRow>
      )}
    </>
  );
};
