import React, { useState } from 'react';
import Select, { components } from 'react-select';
import Createable from 'react-select/creatable';
import styles from './select.module.css';
import cx from 'classnames';

const custom_theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // neutral10: 'var(--neutral-dark-4)',
    neutral80: '#FFFFFF',
    neutral90: '#FFFFFF'
  }
});

const custom_styles = {
  menuList: (provided) => ({
    ...provided,
    minWidth: 300
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--blue-primary)',
    color: '#ffffff'
  }),
  singleValue: (provided) => ({
    ...provided,
    borderRadius: '5px',
    backgroundColor: 'var(--blue-primary)',
    color: 'var(--neutral-light)',
    padding: '0 15px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    maxHeight: '40px',
    overflowY: 'hidden'
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
    margin: '0',
    color: 'var(--neutral-dark-2)'
  }),
  option: (provided) => ({
    ...provided,
    width: '100% !important',
    color: 'var(--neutral-dark-2)',
    backgroundColor: '#fff'
  })
};

const Control = ({ selectProps, ...props }) => {
  const error_class = selectProps.error ? styles.errorBorder : '';
  return <components.Control {...props} className={cx(styles.control, error_class)} />;
};

const Option = (props) => {
  const { isMulti, isSelected } = props;
  if (isMulti)
    return (
      <components.Option {...props}>
        <div className={styles.menulist}>
          <div className={`${styles.gmCheckbox} ${isSelected ? styles.checkboxOn : ''} `}></div>
          {props.children}
        </div>
      </components.Option>
    );
  return <components.Option {...props} className={styles.option} />;
};

const MenuList = (props) => {
  const {
    options,
    getValue,
    selectProps: { onChange }
  } = props;
  const handleBulkSelect = () => {
    if (isAllOptionsSelected()) {
      onChange([]);
      return;
    }
    onChange(options);
  };

  const isAllOptionsSelected = () => {
    return getValue().length === options.length;
  };

  if (props.isMulti)
    return (
      <components.MenuList {...props}>
        <div className={`${styles.menulist} ${styles.menulist_header}`}>
          <div
            className={`${styles.gmCheckbox} ${isAllOptionsSelected() ? styles.checkboxOn : ''}`}
            onClick={handleBulkSelect}
          ></div>
          <div>Select All</div>
        </div>
        {props.children}
      </components.MenuList>
    );

  return <components.MenuList {...props} />;
};

export const MultiSelect = ({
  label = '',
  input_id = '',
  defaultValue = [],
  options = [],
  sort_options_by = 'label',
  placeholder,
  onChange,
  value,
  isMulti,
  required = false,
  hideSelectedOptions = false,
  isCreatable = false,
  error = '',
  disabled = false,
  isSorted = true
}) => {
  const sortOptions = (sort_options_by) => {
    if (!isSorted) return options;
    if (sort_options_by === 'value') {
      return options.sort((a, b) => a.value - b.value);
    }

    return options.sort((a, b) => a.label?.localeCompare(b.label));
  };

  const [isPlaceHolderValueSet, setIsPlaceHolderValueSet] = useState(isMulti);
  const handleBlur = () => {
    if (!isMulti) return;
    setIsPlaceHolderValueSet(true);
  };
  const handleFocus = () => {
    if (!isMulti) return;
    setIsPlaceHolderValueSet(false);
  };

  const formatValue = () => {
    const formattedValue = [];
    if (value && value.length > 0) {
      formattedValue.push({
        label: value[0].label.length > 12 ? `${value[0].label.slice(0, 12)}...` : value[0].label,
        value: value[0].value
      });

      if (value.length > 1) {
        formattedValue.push({
          label: `+ ${value.length - 1} more`,
          value: value.length - 1
        });
      }
    }
    return formattedValue;
  };

  if (isCreatable) {
    return (
      <div className={styles.formGroup}>
        {label && (
          <label htmlFor={input_id}>
            {label}
            {required && <span className={styles.error}>*</span>}
          </label>
        )}
        <Createable
          isMulti={isMulti}
          className={cx(styles.rselect, { [styles.errorBorder]: error })}
          options={sortOptions(sort_options_by)}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          components={{
            Control,
            Option,
            MenuList
          }}
          closeMenuOnSelect={!isMulti}
          menuPlacement="auto"
          placeholder={placeholder}
          styles={custom_styles}
          theme={custom_theme}
          hideSelectedOptions={hideSelectedOptions}
          error={error}
          isDisabled={disabled}
        />
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }
  // console.log({ error }, { required });

  return (
    <>
      <div className={styles.formGroup}>
        {label && (
          <label htmlFor={input_id}>
            {label}
            {required && <span className={styles.error}>*</span>}
          </label>
        )}
        <Select
          isMulti={isMulti}
          className={cx(styles.rselect, { [styles.errorBorder]: error })}
          options={sortOptions(sort_options_by)}
          onChange={onChange}
          defaultValue={defaultValue}
          value={isPlaceHolderValueSet ? formatValue() : value}
          components={{
            Control,
            Option,
            MenuList
          }}
          closeMenuOnSelect={!isMulti}
          menuPlacement="auto"
          placeholder={placeholder}
          styles={custom_styles}
          onBlur={handleBlur}
          onFocus={handleFocus}
          theme={custom_theme}
          hideSelectedOptions={hideSelectedOptions}
          error={error}
          isDisabled={disabled}
        />
        {error && <span className={styles.error}>{error}</span>}
      </div>
    </>
  );
};
