/** */
import React from 'react';
import './spinner.css';

const Spinner = (props) => {
  return (
    <div className="gm-spinner-25">
      <div className="loadingio-spinner-dual-ring-xgh9cnx5hni">
        <div className="ldio-1bxre1v847">
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
