import { useSelector } from 'react-redux';
import * as EventLogService from 'services/automation/eventlog';

export const useEventLogService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchEventLogs = async (options = {}) => {
    const { error, payload } = await EventLogService.read({ ...options, token });
    if (error) {
      return { logs: [], size: 0, error };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { logs, size, error: null };
  };

  const searchEventLogs = async (keys, keyword, options = {}) => {
    const { error, payload } = await EventLogService.search(keys, keyword, { token, ...options });
    if (error) {
      return { logs: [], size: 0 };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { logs, size };
  };

  return {
    fetchEventLogs,
    searchEventLogs
  };
};
