import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import React from 'react';

export const AudiencePageStatBar = ({ contact_stat }) => {
  return (
    <SlidingStatBar>
      <PrimaryStatCard
        main_stat={{
          label: 'Campaigns',
          value: contact_stat.campaign_all_time,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: contact_stat.campaign_in_month }}
      />
      <PrimaryStatCard
        main_stat={{
          label: 'Valid Contacts',
          value: contact_stat.valid_contacts,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: contact_stat.valid_contacts_month }}
      />
      <PrimaryStatCard
        main_stat={{
          label: 'Invalid Contacts',
          value: contact_stat.invalid_contacts,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: contact_stat.invalid_contacts_month }}
      />
    </SlidingStatBar>
  );
};
