export const ADD_MANY_AUTOMATIONS_TO_STORE = 'add multiple automations to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add automation search results to store';
export const ADD_ONE_AUTOMATION_TO_STORE = 'add one automation to store.';
export const LOAD_AUTOMATIONS_TO_STORE = 'load up automations to store.';

export const addManyAutomationsToStore = (payload) => ({
  type: ADD_MANY_AUTOMATIONS_TO_STORE,
  payload
});

export const addAutomationSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const addOneAutomationToStore = (payload) => ({
  type: ADD_ONE_AUTOMATION_TO_STORE,
  payload
});

export const loadAutomationsToStore = (payload) => ({
  type: LOAD_AUTOMATIONS_TO_STORE,
  payload
});
