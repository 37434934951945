import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as AuthConfigService from 'services/iam/auth-config';

export const useAuthConfigService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchConfigs = async (options = {}) => {
    const { error, payload } = await AuthConfigService.read({ ...options, token });
    if (error) {
      return { configs: [], size: 0, error: 'Unable to fetch configs at this time.' };
    }

    const {
      data: configs,
      meta: { size }
    } = payload;
    return { configs, size, error: null };
  };

  const updateConfig = async (options = {}) => {
    const { error, payload: config } = await AuthConfigService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update config at this time');
      return { config: {} };
    }

    toast.success('Config updated successfully.');
    return { config };
  };

  return { fetchConfigs, updateConfig };
};
