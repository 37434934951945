import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as PreferenceService from 'services/messaging/preference';

export const useMessagingPreferenceService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchPreferences = async (options = {}) => {
    const { error, payload } = await PreferenceService.read({ ...options, token });
    if (error) {
      return {
        preferences: [],
        size: 0,
        error: 'Unable to fetch settings at this time.'
      };
    }

    const {
      data: preferences,
      meta: { size }
    } = payload;
    return { preferences, size, error: null };
  };

  const setPreference = async (options = {}) => {
    const { error, payload } = await PreferenceService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to save configuration at this time. Please try again.');
      return null;
    }

    toast.success('Configuration saved successfully.');
    return payload;
  };

  return { fetchPreferences, setPreference };
};
