import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as FileService from 'services/file';

export const useFilesService = () => {
  const { token } = useSelector((store) => store.user_data);

  const uploadImage = async (options = {}) => {
    const { error, payload } = await FileService.uploadImage({ ...options, token });
    if (error) {
      return toast.error(error);
    }

    return payload;
  };

  return { uploadImage };
};
