import { useSelector } from 'react-redux';
import * as WalletLogService from 'services/wallet-log';

export const useWalletLogService = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchWalletLogs = async (options = {}) => {
    const { error, payload } = await WalletLogService.read({ ...options, token });
    if (error) {
      return { walletlogs: [], size: 0, error: 'Unable to fetch mail actions at this time.' };
    }

    const {
      data: walletlogs,
      meta: { size }
    } = payload;
    return { walletlogs, size, error: null };
  };

  return { fetchWalletLogs };
};
