const useQuery = () => {
  const { search } = window.location;
  const query_string = search.substring(1);
  const query_parts = query_string.split('&');
  return query_parts.reduce((sac, part) => {
    const [key, value] = part.split('=');
    if (!key) return sac;
    return {
      ...sac,
      [key]: value
    };
  }, {});
};

export { useQuery };
