import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import React from 'react';

export const AutoTopUpConfig = ({ config = {}, onChange = () => {} }) => {
  return (
    <GridRow num_of_columns={2}>
      <GridColumn>
        <DetailItem title="Threshold">
          <SimpleInput
            type="number"
            value={config.threshold}
            onInput={(v) => onChange('threshold', v)}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="Credits to purchase">
          <SimpleInput
            type="number"
            value={config.credits}
            onInput={(v) => onChange('credits', v)}
          />
        </DetailItem>
      </GridColumn>
    </GridRow>
  );
};
