/** */
import {
  ADD_MANY_CONTACT_FIELD_TO_STORE,
  ADD_ONE_CONTACT_FIELD_TO_STORE
} from '../actions/contact-fields';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_MANY_CONTACT_FIELD_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (contact_fields, contact_field) => ({
            ...contact_fields,
            [contact_field.id]: contact_field
          }),
          {}
        )
      };
    case ADD_ONE_CONTACT_FIELD_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    default:
      return state;
  }
};
