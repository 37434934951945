import { GridColumn, GridRow } from 'app/layouts/grid';
import { usePermissionService } from 'hooks/iam/permission';
import { useRoleService } from 'hooks/iam/role';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleBasicInformation } from './components/basic';
import { PermissionsConfiguration } from './components/permissions';
import { toast } from 'react-toastify';

export const RoleCreationPage = () => {
  const navigate = useNavigate();
  const { id: role_id } = useParams();
  const { fetchPermissions } = usePermissionService();
  const { createRole, fetchRole, updateRole } = useRoleService();

  const [basic_info, setBasicInfo] = useState({});
  const [grouped_permissions, setGroupedPermissions] = useState({});
  const [is_saving, setIsSaving] = useState(false);
  const [permission_config, setPermissionConfig] = useState({});

  useEffect(() => {
    fetchPermissions().then(({ permissions, error }) => {
      if (error) return toast.error(error);
      const permissions_by_group = groupPermissions(permissions);
      setGroupedPermissions(() => permissions_by_group);
    });
  }, []);

  useEffect(() => {
    if (isNaN(role_id)) return;
    fetchRole(role_id).then(({ role }) => {
      processDefaultPermissionConfig(role.permissions || []);
      setBasicInfo(() => ({
        name: role.name,
        description: role.description || ''
      }));
    });
  }, [role_id]);

  const groupPermissions = (permissions = []) => {
    const permissions_by_group = {};
    for (const permission of permissions) {
      if (!permissions_by_group[permission.group]) {
        permissions_by_group[permission.group] = [];
      }
      permissions_by_group[permission.group].push(permission);
    }

    return permissions_by_group;
  };

  const handleBasicInfoChange = (key, value) => {
    setBasicInfo((curr_info) => ({
      ...curr_info,
      [key]: value
    }));
  };

  const handlePermissionConfig = (permission = null, action = '') => {
    if (!permission || !permission.code) return;
    const { code, resource } = permission;
    setPermissionConfig((curr_config) => ({
      ...curr_config,
      [code]: {
        ...(curr_config[code] || {}),
        code,
        resource,
        [action]: {
          name: action,
          is_permitted: curr_config[code] ? !curr_config[code][action]?.is_permitted : true
        }
      }
    }));
  };

  const processDefaultPermissionConfig = (permissions = []) => {
    const config = {};
    for (const permission of permissions) {
      const { actions, code, resource } = permission || {};
      for (const action of actions || []) {
        config[code] = {
          ...(config[code] || {}),
          code,
          resource,
          [action.name]: { ...action }
        };
      }
    }

    setPermissionConfig(() => config);
  };

  const submit = async () => {
    const data = {
      ...basic_info,
      permissions: Object.values(permission_config).map((actions) => {
        const parsed_actions = [];
        const { code, resource } = actions;
        for (const key of Object.keys(actions)) {
          if (key === 'resource' || key === 'code') continue;
          parsed_actions.push(actions[key]);
        }

        return { actions: parsed_actions, code, resource };
      })
    };

    setIsSaving(() => true);
    isNaN(role_id) ? await createRole({ data }) : await updateRole(role_id, { data });
    navigate('/users/roles');
  };

  return (
    <GridRow num_of_columns={4}>
      <GridColumn span={1}>
        <RoleBasicInformation
          data={basic_info}
          is_saving={is_saving}
          onDataChange={handleBasicInfoChange}
          onSave={submit}
        />
      </GridColumn>
      <GridColumn span={2}>
        {Object.keys(grouped_permissions).map((group) => (
          <PermissionsConfiguration
            key={group}
            name={group}
            data={grouped_permissions[group]}
            selections={permission_config}
            onConfigChange={handlePermissionConfig}
          />
        ))}
      </GridColumn>
      <GridColumn span={1}></GridColumn>
    </GridRow>
  );
};
