import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MemberhipAcceptance } from './acceptance';
import { CreateOrganization } from './create';

export const PreOrganizationModule = () => {
  return (
    <Routes>
      <Route path="acceptance/:key" element={<MemberhipAcceptance />} />
      <Route path="/*" element={<CreateOrganization />} />
    </Routes>
  );
};
