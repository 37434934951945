import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ContactPage } from './view';
import ListContact from './list/list';

const ContactModule = () => {
  return (
    <Routes>
      <Route path=":id/*" element={<ContactPage />} />
      <Route path="/*" element={<ListContact />} />
    </Routes>
  );
};

export default ContactModule;
