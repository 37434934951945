import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailLogService } from 'hooks/maillog';
import { formatDateForDisplay } from 'app/shared/utils/date';
import { useCampaignService } from 'hooks/mailing/campaign';
import { toReadableNumber } from 'app/shared/utils/number';
import useScreenSize from 'hooks/size';

export const RetriesLog = () => {
  const { id: campaign_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { fetchCampaign } = useCampaignService();
  const { fetchMailLogs } = useMailLogService();
  const { isMobile } = useScreenSize();

  const [retries_log, setRetriesLog] = useState({});
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_retries, setTotalRetries] = useState(0);

  const config = {
    actions: { single: () => [] },
    css: {},
    fields: [
      {
        title: 'ID',
        key: 'id',
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Emails sent',
        key: 'sends',
        isTagline: true,
        formatter: (value) =>
          isMobile
            ? toReadableNumber(value || 'N/A') + ' recipients'
            : toReadableNumber(value || 'N/A')
      },
      {
        title: 'Bounces',
        key: 'bounces',
        formatter: (value) => toReadableNumber(value || 'N/A')
      },
      {
        title: 'Est. Cost (₦)',
        key: 'cost',
        isTitle: true,
        formatter: (value) =>
          isMobile ? 'N' + toReadableNumber(value || 'N/A') : toReadableNumber(value || 'N/A')
      },
      {
        title: 'Time sent',
        key: 'time',
        formatter: (value) => formatDateForDisplay(value),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: Object.values(retries_log).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  useEffect(() => {
    setLoading(true);
    fetchCampaign(campaign_id)
      .then(async ({ campaign: response }) => {
        if (!response.metadata) return;
        const { retry_history } = response.metadata;

        const logs = [];
        let id = 0;
        for (const trial of retry_history) {
          const [{ size: sends }, { size: bounces }] = await Promise.all([
            fetchMailLogs({
              query_string: `count=1&class=campaign&resource_id=${campaign_id}&retry_id=${id}`
            }),
            fetchMailLogs({
              query_string: `count=1&class=campaign&status=bounced&resource_id=${campaign_id}&retry_id=${id}`
            })
          ]);

          logs.push({ ...trial, id: id + 1, sends, bounces });
          id++;
        }
        setRetriesLog(() => logs);
        setTotalRetries(() => (retry_history ? retry_history.length : 0));
      })
      .finally(() => setLoading(false));
  }, [campaign_id]);

  const handleDataRequest = async (page, population = 50) => {};

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            action={() => {}}
            onClick={() => {}}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              total_count: total_retries
            }}
            action={() => {}}
            onClick={() => {}}
            checkbox
            loading_data={loading}
            table_actions={[]}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
