import * as MembershipService from 'services/iam/membership';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppStore } from 'hooks/store';

export const useMembershipService = () => {
  const { clearResources } = useAppStore();
  const { tenant_id, token } = useSelector((store) => store.user_data);

  const fetchMemberships = async (options = {}) => {
    const { error, payload } = await MembershipService.read({ ...options, token });
    if (error) {
      return { error, memberships: null, size: 0 };
    }

    const {
      data: memberships,
      meta: { size }
    } = payload;
    return { memberships, size, error: null };
  };

  const updateMembership = async (id, options = {}) => {
    const { error, payload: membership } = await MembershipService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update membership at this time');
      return { membership: {} };
    }

    toast.success('Membership updated successfully.');
    return { membership };
  };

  /** */
  const manageMembership = async (action, options = {}) => {
    const { error, payload } = await MembershipService.manage(action, {
      ...options,
      token
    });

    if (error) {
      toast.error(`Cannot ${action} membership at this time`);
      return { membership: {} };
    }

    if (typeof membership === 'string') toast.success(payload);
    return { membership: payload };
  };

  const acceptInvitation = async (options = {}) => {
    return await manageMembership('accept', options);
  };

  const inviteUser = async (options = {}) => {
    return await manageMembership('invite', {
      ...options,
      data: { ...(options.data || {}), tenant_id }
    });
  };

  const revokeInvitation = async (options = {}) => {
    return await manageMembership('revoke', {
      ...options,
      data: { ...(options.data || {}), tenant_id }
    });
  };

  const removeMembership = async (options = {}) => {
    return await manageMembership('remove', {
      ...options,
      data: { ...(options.data || {}), tenant_id }
    });
  };

  const switchTenant = async (options = {}) => {
    const { membership } = await manageMembership('switch', { ...options });
    if (Object.keys(membership).length) clearResources();
    return membership;
  };

  return {
    acceptInvitation,
    fetchMemberships,
    inviteUser,
    removeMembership,
    revokeInvitation,
    switchTenant,
    updateMembership
  };
};
