import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as NeuronService from 'services/automation/neuron';

export const useNeuronService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createNeuron = async (options = {}) => {
    const { error, payload } = await NeuronService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return { neuron: null };
    }

    return { neuron: payload };
  };

  const fetchNeuron = async (id, options = {}) => {
    const { error, payload } = await NeuronService.readById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchNeurons = async (options = {}) => {
    const { error, payload } = await NeuronService.read({ ...options, token });
    if (error) {
      return { error, neurons: [], size: 0 };
    }

    const {
      data: neurons,
      meta: { size }
    } = payload;
    return { neurons, size, error: null };
  };

  const searchNeurons = async (keys, keyword, options = {}) => {
    const { error, payload } = await NeuronService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, neurons: [], size: 0 };
    }

    const {
      data: neurons,
      meta: { size }
    } = payload;
    return { error, neurons, size };
  };

  const updateNeuron = async (id, options = {}) => {
    const { error, payload } = await NeuronService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { neuron: null };
    }

    return { neuron: payload };
  };

  return {
    createNeuron,
    fetchNeuron,
    fetchNeurons,
    searchNeurons,
    updateNeuron
  };
};
