import React from 'react';
import cx from 'classnames';

import Skeleton from 'react-loading-skeleton';

function SkeletonGraph({ styles }) {
  return (
    <div className={cx(styles.wrapper, 'col-sm-12')} style={{ zIndex: 0 }}>
      <div className={styles.header}>
        <h5 className={styles.title}>
          <Skeleton height={50} width={200} />
        </h5>
        <div className={styles.filters}>
          <div>
            <Skeleton height={50} width={`100%`} />
          </div>
          <div>
            <Skeleton height={50} width={`100%`} />
          </div>
        </div>
      </div>
      <Skeleton width={`100%`} height={`85%`} />
    </div>
  );
}

export const DistributionSkeleton = () => {
  const styles = {
    item: {
      marginBottom: '10px',
      zIndex: 0
    }
  };

  return (
    <div style={styles.wrapper}>
      {new Array(6).fill(0).map((x, i) => {
        let width = '85%';
        if (i % 2 === 0) width = '40%';

        return <Skeleton style={styles.item} width={width} height={20} key={i} />;
      })}
    </div>
  );
};

export const TimeSeriesSkeleton = () => {
  const styles = {
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      top: 0,
      overflow: 'hidden',
      zIndex: 0
    },
    item: {
      position: 'absolute',
      bottom: 0
    }
  };

  return (
    <div style={styles.wrapper}>
      {new Array(25).fill(0).map((x, i) => {
        let height = '85%';
        if (i % 2 === 0) height = '40%';

        return <Skeleton style={styles.item} width={30} height={height} key={i} />;
      })}
    </div>
  );
};

export default SkeletonGraph;
