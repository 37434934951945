/** */
import {
  ADD_AUD_CONTACTS,
  ADD_SEARCH_RESULTS_TO_STORE,
  CLEAR_AUD_CONTACTS,
  REM_AUD_CONTACTS
} from '../actions/audience-contact';

export default (state = {}, action) => {
  const { type, payload } = action;
  const new_state = { ...state };
  switch (type) {
    case ADD_AUD_CONTACTS:
      return {
        ...state,
        ...payload.reduce(
          (contacts, contact) => ({
            ...contacts,
            [contact.id]: contact
          }),
          {}
        )
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (contacts, contact) => ({
            ...contacts,
            [contact.id]: contact
          }),
          {}
        )
      };
    case REM_AUD_CONTACTS:
      payload.forEach((id) => {
        delete new_state[id];
      });

      return new_state;
    case CLEAR_AUD_CONTACTS:
      return {};
    default:
      return state;
  }
};
