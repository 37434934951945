/** */
import { toast } from 'react-toastify';
import React, { useState, useEffect, useContext } from 'react';
import fileSaver from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { useAudienceService } from 'hooks/users/audience';
import { useContactService } from 'hooks/contact';
import { addManyAudiencesToStore } from 'store/actions/audience';
import useScreenSize from 'hooks/size';
import { Spacer } from 'app/layouts/generic';
import { BubbleDisplayContext } from 'contexts/bubble';
import { UploadJob } from './job';

const ImportContact = (props) => {
  const { closeModal, mailing_list } = props;
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { addItem, removeItem } = useContext(BubbleDisplayContext);
  const { fetchAudiences } = useAudienceService();
  const { downloadTemplate, uploadContacts } = useContactService();

  const { id: tenant_id } = useSelector((state) => state.user_data);
  const audiences_in_store = useSelector((state) => state.audiences);

  const [file, setFile] = useState(null);
  const [mailing_lists, setMailingLists] = useState([]);
  const [selected_lists, setSelectedList] = useState([]);

  useEffect(() => {
    setMailingLists(() =>
      Object.values(audiences_in_store).filter((audience) => !audience.is_test_audience)
    );
  }, [audiences_in_store]);

  useEffect(() => {
    fetchAudiences({
      query_string: 'is_archived=0&return_only=name,id,is_test_audience'
    }).then(({ audiences, error }) => {
      if (error) {
        toast.error('Unable to fetch audience at this time.');
        return;
      }
      dispatch(addManyAudiencesToStore(audiences));
    });
  }, []);

  const downloadContactsTemplate = async () => {
    const response = await downloadTemplate();
    fileSaver(response, 'contacts.csv');
  };

  const resetImport = () => {
    setSelectedList([]);
    setFile(null);
  };

  const supported_extensions = ['.csv', '.xlsx'];
  const submit = async () => {
    if (!file) {
      toast.error('no file selected');
      return;
    }

    if (!supported_extensions.some((ext) => file.name.endsWith(ext))) {
      toast.error('Only csv or xlsx format is supported');
      return;
    }

    const audiences = selected_lists.map((list) => list.value);
    const job = {
      id: file.name,
      file,
      uploadFunction: uploadContacts,
      context: { tenant_id, audiences }
    };
    addItem(
      job.id,
      <UploadJob
        key={job.id}
        job={job}
        onComplete={() => {
          removeItem(job.id);
        }}
      />
    );

    closeModal();
    resetImport();
  };

  return (
    <div>
      <div className="custom-file">
        <input
          type="file"
          accept=".csv,.xlsx"
          className="custom-file-input"
          id="contact_file"
          onChange={(e) => setFile(e.target.files[0])}
          onClick={(e) => (e.target.value = null)}
        />
        <label className="custom-file-label" htmlFor="contact_file">
          {file ? file.name : 'Select file'}
        </label>
      </div>
      <div className="mt-3">
        {mailing_list ? (
          <select className="custom-select mb-3" disabled>
            <option>{mailing_list.name}</option>
          </select>
        ) : (
          <MultiSelect
            options={mailing_lists.map((list) => ({
              label: list.name,
              value: list.id,
              is_test_audience: list.is_test_audience
            }))}
            onChange={setSelectedList}
            value={selected_lists}
            placeholder="Select audience(s)"
            isMulti
          />
        )}
      </div>
      {isMobile && <Spacer multiple={2} />}
      <div className="grid cols-2">
        <Button
          onClick={() => downloadContactsTemplate()}
          className="w-100"
          text="Download Template"
          type="secondary"
        />
        {isMobile && <Spacer multiple={2} />}
        <Button onClick={submit} className="w-100" text="Import" />
      </div>
    </div>
  );
};

export default ImportContact;
