import { Spacer } from 'app/layouts/generic';
import { SiteIcon } from 'assets/icons';
import { useMembershipService } from 'hooks/iam/membership';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './acceptance.module.css';

export const MemberhipAcceptance = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const { acceptInvitation } = useMembershipService();

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!key) return;
    const [email, tenant_id, role_id] = key.split(':');
    const data = { email, tenant_id, role_id };

    setMessage('Accepting invitation');
    acceptInvitation({ data }).then(({ membership }) => {
      if (!Object.keys(membership).length) {
        setMessage('Sorry an error occurred. Please try again later.');
      } else {
        setMessage('Invitation accepted. You will be be redirected to your dashboard shortly');
      }

      setInterval(async () => {
        navigate('/dashboard');
      }, 5000);
    });
  }, [key]);

  return (
    <div className={styles.wrapper}>
      <SiteIcon />
      <Spacer multiple={4} />
      <div className={styles.text}>{message}</div>
    </div>
  );
};
