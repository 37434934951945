import React from 'react';
import cx from 'classnames';
import styles from '../button.module.css';
import { Icon } from 'assets/icons';

export const MoreButton = ({
  className = '',
  iconClassName = '',
  text = '',
  onClick = () => {},
  disabled
}) => {
  return (
    <button
      className={cx(styles.button, styles.moreBtn, className)}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={styles.text}>{text}</span>
      <span className={cx(styles.icon, iconClassName)}>
        {' '}
        <Icon name="expand" />{' '}
      </span>
    </button>
  );
};
