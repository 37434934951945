import { useSelector } from 'react-redux';
import { checkFeatureFlagPermission } from 'services/iam';
const { REACT_APP_ENV } = process.env;

export const useFeatureFlag = () => {
  const { token } = useSelector((state) => state.user_data);
  const isFeatureAllowed = async (name = '', criteria = {}) =>
    await checkFeatureFlagPermission({
      data: {
        name,
        criteria,
        environment: REACT_APP_ENV
      },
      token
    });

  return { isFeatureAllowed };
};
