/** */
export const CREATE_SMS = 'create sms message';
export const DELETE_SMS = 'delete sms message';
export const READ_SMS = 'read sms messages';
export const UPDATE_SMS = 'update sms message';

/** */
export const ADD_ONE_SMS_TO_STORE = 'add one sms message to store';
export const ADD_MANY_SMS_TO_STORE = 'add many sms messages to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add sms message search results to store';
export const LOAD_SMS_TO_STORE = 'load sms messages to store from API call';
export const REMOVE_MANY_SMS_FROM_STORE = 'remove many sms messages from the store.';
export const REMOVE_ONE_SMS_FROM_STORE = 'remove a sms message from the store.';

/** */
export const createSMS = (payload) => ({
  type: CREATE_SMS,
  payload
});

export const deleteSMS = (payload) => ({
  type: DELETE_SMS,
  payload
});

export const deleteManySMS = (payload) => ({
  type: REMOVE_MANY_SMS_FROM_STORE,
  payload
});

export const readSMS = (payload) => ({
  type: READ_SMS,
  payload
});

export const updateSMSInStore = (payload) => ({
  type: UPDATE_SMS,
  payload
});

/** */

export const addManySMSToStore = (payload) => ({
  type: ADD_MANY_SMS_TO_STORE,
  payload
});

export const addOneSMSToStore = (payload) => ({
  type: ADD_ONE_SMS_TO_STORE,
  payload
});

export const addSMSSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadSMSToStore = (payload) => ({
  type: LOAD_SMS_TO_STORE,
  payload
});

export const removeManySMSFromStore = (payload) => ({
  type: REMOVE_MANY_SMS_FROM_STORE,
  payload
});

export const removeOneSMSFromStore = (payload) => ({
  type: REMOVE_ONE_SMS_FROM_STORE,
  payload
});
