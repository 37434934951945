import React, { useEffect, useState } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { toast } from 'react-toastify';
import { CodeWrapper } from 'app/shared/wrappers/code';
import { Spacer } from 'app/layouts/generic';
import { Icon } from 'assets/icons';
import GmModal from 'app/shared/modal/modal';
import styles from './dkim.module.css';

const DNSRecordDisplay = ({ email_config }) => {
  const [dkim, setDKIM] = useState({});
  const [spf, setSPF] = useState({});

  useEffect(() => {
    if (email_config?.dkim) setDKIM(email_config.dkim);
    if (email_config?.spf) setSPF(email_config.spf);
  }, [email_config]);

  const handleCopy = (snippet) => {
    navigator.clipboard
      .writeText(snippet)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };

  return (
    <>
      <Spacer multiple={4} />
      <DetailItem title="SPF Record">
        <CodeWrapper text={spf?.value} onClick={handleCopy} info_text="click on record to copy" />
      </DetailItem>
      <DetailItem title="DKIM Host">
        <CodeWrapper text={`${dkim?.selector}._domainkey`} />
      </DetailItem>
      <DetailItem title="DKIM Record">
        <CodeWrapper text={dkim?.value} onClick={handleCopy} info_text="click on record to copy" />
      </DetailItem>
    </>
  );
};

export const DKIMConfigurationDetails = ({ email_config, onDelete = () => {} }) => {
  const [show_details, setShowDetails] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <span className={styles.title}>{email_config.domain || 'N/A'}</span>
        <span className={styles.actions}>
          <Icon name="show" onClick={() => setShowDetails(true)} />
          <Icon name="trash" className={styles.trashIcon} onClick={() => onDelete(email_config)} />
        </span>
      </div>
      <GmModal
        show_modal={show_details}
        show_title
        title={`DNS records for ${email_config.domain}`}
        onClose={() => setShowDetails(false)}
      >
        <DNSRecordDisplay email_config={email_config} />
      </GmModal>
    </>
  );
};
