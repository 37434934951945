import React, { useState, useEffect, useRef } from 'react';

import './item.css';

const DatatableItem = (props) => {
  const {
    data,
    deselect,
    fields,
    index,
    bulk_selection,
    item_click_callback,
    selection_callback,
    unselection_callback
  } = props;
  const checkbox_ref = useRef();

  const [item_badge, setItemBadge] = useState(null);
  const [item_metadata, setMetadata] = useState('');
  const [item_title, setItemTitle] = useState('');
  const [item_tagline, setTagline] = useState('');

  const [is_selected, setIsSelected] = useState(false);

  useEffect(() => {
    buildItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkbox = checkbox_ref.current;
    if (deselect && is_selected) {
      checkbox.click();
    }

    if (bulk_selection && !is_selected) {
      checkbox.click();
    }
  }, [deselect, is_selected, bulk_selection]);

  const buildItem = () => {
    let title = '';
    fields.forEach((field) => {
      const { formatter, key } = field;
      let value = data[key];
      if (formatter) value = formatter(value);

      //
      if (field.isTitle) {
        title = `${title} ${value}`;
        setItemTitle(title);
      }
      if (field.isTagline) setTagline(value);
      if (field.isMetadata) setMetadata(value);
      if (field.isBadge) setItemBadge(value);
    });
  };

  const handleItemSelection = () => {
    if (!is_selected) selection_callback(data);
    else unselection_callback(data.id);
    setIsSelected(!is_selected);
  };

  return (
    <div key={index} className="gm-datatable-item">
      <div className="gm-datatable-checkbox">
        <input
          ref={checkbox_ref}
          type="checkbox"
          value={is_selected}
          onChange={handleItemSelection}
        />
      </div>
      <div className="gm-datatable-content" onClick={() => item_click_callback(data)}>
        <div className="gm-datatable-item-title">{item_title || ''}</div>
        <div className="gm-datatable-item-tagline">{item_tagline || ''}</div>
        <div className="gm-datatable-item-meta-wrapper">
          <span className="gm-datatable-item-metadata">{item_metadata || ''}</span>
        </div>
      </div>
      <div className="gm-datatable-item-badge">{item_badge}</div>
    </div>
  );
};

export default DatatableItem;
