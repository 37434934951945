import { useSelector } from 'react-redux';
import * as SMSLogService from 'services/messaging/logs/sms';

export const useSMSLogService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchSMSLogs = async (options = {}) => {
    const { error, payload } = await SMSLogService.read({ ...options, token });
    if (error) {
      return {
        logs: [],
        size: 0,
        error: 'Unable to fetch SMS logs at this time.'
      };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { logs, size, error: null };
  };

  const searchSMSLogs = async (keys, keyword, options = {}) => {
    const { error, payload } = await SMSLogService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, logs: [], size: 0 };
    }

    const {
      data: logs,
      meta: { size }
    } = payload;
    return { error, logs, size };
  };

  return { fetchSMSLogs, searchSMSLogs };
};
