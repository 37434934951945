import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Icon } from 'assets/icons';
import styles from './form-alert.module.css';

export const Alert = ({ id = 0, message = '', type = 'error', onClose = () => {} }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    setAlertMessage(message);
    setShowAlert(!!message);
  }, [message, renderCount]);

  const handleClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    onClose(id);
  };

  useEffect(() => {
    setRenderCount((prevCount) => prevCount + 1);
  }, [id]);

  const iconStyle = {
    ...(type === 'info' ? { color: '#fff' } : {})
  };

  return (
    <>
      {showAlert && (
        <div key={id} className={cx(styles.alert, styles[type])}>
          <span>{alertMessage}</span>
          <span onClick={handleClose} className={styles.closeBtn} style={iconStyle}>
            <Icon name="close" />
          </span>
        </div>
      )}
    </>
  );
};
