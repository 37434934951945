import { Spacer } from 'app/layouts/generic';
import { GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { TimeSeriesChart } from 'app/shared/charts/timeseries';
import { DateRangePicker } from 'app/shared/datepickers';
import GmModal from 'app/shared/modal/modal';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { generateDefaultRange } from 'app/shared/utils/date';
import { useAutomationConfigService } from 'hooks/automation/config';
import { useEventLogService } from 'hooks/automation/eventlog';
import { useContactService } from 'hooks/contact';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DistributionChartInstance } from './distribution';
import { ChartCreationForm } from './form';
import { EventStatBar } from './stat';
import styles from './view.module.css';

export const EventViewPage = () => {
  const chart_key_filters = [
    {
      label: 'Invocations',
      value: 'total',
      default: true,
      color: 'var(--blue-primary)'
    }
  ];

  const { event_code } = useParams();
  const { tenant_id } = useSelector((store) => store.user_data);
  const code = `${event_code}:${tenant_id}`;

  const { fetchContactsBulk } = useContactService();
  const { fetchEventLogs } = useEventLogService();
  const { fetchAutomationConfigs, updateAutomationConfig } = useAutomationConfigService();

  const [charts, setCharts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [is_loading_performance_data, setIsLoadingPerformanceData] = useState(true);
  const [is_loading_distribution_data, setIsLoadingDistributionData] = useState(true);
  const [performance_data, setPerformanceData] = useState({});
  const [show_creation_form, setShowCreationForm] = useState(false);

  const handleDateSelection = async (value) => {
    const [start, end] = value;
    setIsLoadingDistributionData(true);
    const eventlogs = await fetchEventLogsInPeriod({
      code,
      start: Date.parse(start),
      end: Date.parse(end)
    });

    setLogs(eventlogs);
    const emails = (eventlogs || []).map((log) => log.contact_email);
    const contacts = await fetchLogContacts({ emails });

    setContacts(contacts);
    setLogs(eventlogs);
    setIsLoadingDistributionData(false);
  };

  const fetchLogContacts = async ({ emails = [] }) => {
    let contacts = [];
    const batch_size = Math.ceil(emails.length / 2000);

    for (let i = 0; i < batch_size; i++) {
      const { contacts: payload } = await fetchContactsBulk({ data: { email: emails.join(',') } });
      contacts = [...contacts, ...payload];
      if (!payload.length) break;
    }

    return contacts;
  };

  const fetchEventLogsInPeriod = async ({ code = '', start, end, eventlogs = [], page = 0 }) => {
    const { logs } = await fetchEventLogs({
      query_string: `time_stamp=${start}~${end}&code=${code}&return_only=contact_email&page=${page}&population=2000`
    });

    if (!logs.length) return eventlogs;
    return await fetchEventLogsInPeriod({
      code,
      start,
      end,
      eventlogs: [...eventlogs, ...logs],
      page: page + 1
    });
  };

  const handleChartCreation = (data = []) => {
    setCharts(data);
  };

  const handleChartDeletion = async (field) => {
    const allowed_charts = charts.filter((chart) => chart !== field);
    const result = await updateAutomationConfig({
      data: { options: {}, data: { charts: allowed_charts } }
    });
    if (result) setCharts(allowed_charts);
  };

  const prepareInvocationTimeSeries = async (ranges = []) => {
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setPerformanceData(() => day_map);

    setIsLoadingPerformanceData(true);

    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      Promise.all([
        fetchEventLogs({
          query_string: `time_stamp=${start}~${end}&count=1&code=${code}`
        })
      ]).then(([{ size: total }]) => {
        setPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: { ...curr_data[label], total }
        }));
      });
    }
    setIsLoadingPerformanceData(false);
  };

  useEffect(() => {
    fetchAutomationConfigs().then(({ configs: [config] }) => {
      setCharts(config?.charts || []);
    });
  }, []);

  useEffect(() => {
    handleDateSelection(generateDefaultRange());
  }, [event_code]);

  return (
    <>
      <SlidingNav
        nav_items={[]}
        actions={
          <div className={styles.headerActions}>
            <DateRangePicker
              initial_value={generateDefaultRange()}
              onChange={handleDateSelection}
            />
            <Button
              text="Create distribution chart"
              className={styles.headerBtn}
              onClick={() => setShowCreationForm(true)}
            />
          </div>
        }
      />
      <Spacer multiple={4} />
      <EventStatBar />
      <Spacer multiple={4} />
      <GridRow num_of_columns={1}>
        <TimeSeriesChart
          data={Object.values(performance_data)}
          graph_title="Invocations over time"
          is_loading_data={is_loading_performance_data}
          key_filters={chart_key_filters}
          onDateRangeChange={prepareInvocationTimeSeries}
        />
        <GridRow num_of_columns={2}>
          {charts.map((chart) => (
            <DistributionChartInstance
              key={chart}
              type="invocations"
              field={chart}
              contacts={contacts}
              logs={logs}
              is_loading={is_loading_distribution_data}
              onDelete={handleChartDeletion}
            />
          ))}
        </GridRow>
      </GridRow>
      <GmModal
        bodyClassName={styles.chartCreationModal}
        title="Create Distribution Chart"
        show_modal={show_creation_form}
        show_title
        onClose={() => setShowCreationForm(false)}
      >
        <ChartCreationForm onSave={handleChartCreation} />
      </GmModal>
    </>
  );
};
