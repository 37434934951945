import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as TemplateService from 'services/automation/template';

export const useAutomationTemplateService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createTemplate = async (options = {}) => {
    const { error, payload } = await TemplateService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return { template: {} };
    }

    return { template: payload };
  };

  const fetchTemplate = async (id, options = {}) => {
    const { error, payload } = await TemplateService.readById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { template: {} };
    }

    return { template: payload };
  };

  const fetchTemplates = async (options = {}) => {
    const { error, payload } = await TemplateService.read({ ...options, token });
    if (error) {
      return { templates: [], size: 0, error };
    }

    const {
      data: templates,
      meta: { size }
    } = payload;
    return { templates, size, error: null };
  };

  const searchTemplates = async (keys, keyword, options = {}) => {
    const { error, payload } = await TemplateService.search(keys, keyword, { token, ...options });
    if (error) {
      return { templates: [], size: 0 };
    }

    const {
      data: templates,
      meta: { size }
    } = payload;
    return { templates, size };
  };

  const updateTemplate = async (id, options = {}) => {
    const { error, payload } = await TemplateService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { template: {} };
    }

    return { template: payload };
  };

  const updateTemplates = async (options = {}) => {
    const { error } = await TemplateService.updateBulk({
      token,
      ...options
    });

    return !error;
  };

  return {
    createTemplate,
    fetchTemplate,
    fetchTemplates,
    searchTemplates,
    updateTemplate,
    updateTemplates
  };
};
