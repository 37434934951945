import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { Checkbox } from 'app/shared/input/checkbox';
import { audienceValidationConfig } from 'app/shared/utils/form/validation/audience';
import { useAudienceService } from 'hooks/users/audience';
import useValidator from 'hooks/validator';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addOneAudienceToStore } from 'store/actions/audience';
import { PermissionsContext } from 'contexts/permissions';
import { TextArea } from 'app/shared/input/textarea';

export const AudienceUpdationForm = ({ audience_data, onSave = () => {} }) => {
  const dispatch = useDispatch();
  const { updateAudience } = useAudienceService();
  const { errors, validateField, form_is_valid } = useValidator(audienceValidationConfig);
  const [form_data, setFormData] = useState({});
  const [saving, setSaving] = useState(false);
  const { permissions } = useContext(PermissionsContext);

  useEffect(() => {
    setFormData(() => audience_data);

    validateField('name', audience_data.name);
  }, [audience_data]);

  const handleInputChange = (key, value = '') => {
    validateField(key, value);
    setFormData((curr_data) => ({
      ...curr_data,
      [key]: value
    }));
  };

  const handleSubmit = async () => {
    const data = {
      name: form_data.name,
      description: form_data.description,
      is_test_audience: form_data.is_test_audience
    };

    if (!data.name) {
      return toast.error('Please enter a valid name.');
    }

    setSaving(() => true);
    const payload = await updateAudience(audience_data.id, { data });
    if (payload) {
      dispatch(addOneAudienceToStore({ ...audience_data, ...payload }));
      toast.success('Update successful.');
      onSave({ ...form_data });
    }

    setSaving(() => false);
  };

  return (
    <BasicInfoSection title="Basic Details">
      <DetailItem title="Name">
        <SimpleInput
          value={form_data.name}
          required
          onInput={(v) => handleInputChange('name', v)}
          error={errors.name}
        />
      </DetailItem>
      <DetailItem title="Description">
        <TextArea
          value={form_data.description}
          onInput={(v) => handleInputChange('description', v)}
        />
        <Spacer multiple={4} />
        <Checkbox
          label="Set as test audience"
          checked={!!form_data.is_test_audience}
          onClick={() => handleInputChange('is_test_audience', !form_data.is_test_audience)}
        />
      </DetailItem>
      <Spacer multiple={6} />
      <Button
        text="Save changes"
        disabled={!form_is_valid || saving || !permissions['audience:update']}
        onClick={handleSubmit}
      />
    </BasicInfoSection>
  );
};
