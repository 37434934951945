import React, { useContext, useEffect, useState } from 'react';

import { Button } from 'app/shared/button';
import { Spacer } from 'app/layouts/generic';
import { truncateText } from 'app/shared/utils/general';
import { SimpleInput } from 'app/shared/input';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import { DetailItem } from 'app/shared/info-section/item';
import { PermissionsContext } from 'contexts/permissions';
import useValidator from 'hooks/validator';
import { DNS_config_validator } from '../schemas/dns';
import { InfoBanner } from 'app/shared/info-banner';

export const DKIMConfigurationEditor = ({ onSave = () => {} }) => {
  const { generateRecords } = useMailingConfigService();
  const { errors, form_is_valid, validateField } = useValidator(DNS_config_validator);
  const { permissions } = useContext(PermissionsContext);

  const [domain, setDomain] = useState('');
  const [saving, setSaving] = useState(false);
  const [show_confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    validateField('domain', domain);
  }, [domain]);

  const handleConfirmation = (permitted) => {
    if (permitted) submit();
    setConfirmation(() => false);
  };

  const submit = async () => {
    const data = {
      domain
    };

    try {
      setSaving(() => true);
      await generateRecords({ data });
      setDomain('');
      onSave();
    } finally {
      setSaving(() => false);
    }
  };

  return (
    <>
      <InfoBanner
        message={
          <span>
            You can learn how to configure your DNS records{' '}
            <a
              href="https://go-mailer.blog/how-to-configure-your-spf-and-dkim-records-in-cpanel"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              here{' '}
            </a>
          </span>
        }
        type="info"
      />
      <Spacer multiple={4} />
      <GridRow num_of_columns={3}>
        <GridColumn span={2}>
          <DetailItem title="Sender Domain">
            <SimpleInput
              type="text"
              placeholder="e.g example.com"
              value={truncateText(domain, 50)}
              error={errors.domain}
              required
              onInput={setDomain}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <Spacer multiple={5} />
          <Button
            onClick={(e) => setConfirmation(true)}
            text="Generate"
            loading={saving}
            disabled={!permissions['email_config:update'] || !form_is_valid}
          />
        </GridColumn>
      </GridRow>
      <ConfirmationDialog
        title="Generate DNS records"
        message={`You are about to generate new DNS records for ${domain}. Please ensure that you configure the correct values in your Domain's DNS settings.`}
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
    </>
  );
};
