import React, { useContext, useEffect, useMemo } from 'react';
import ReactFlow, { Background, Controls, Panel } from 'reactflow';

import 'reactflow/dist/style.css';
import './reactflow.css';
import styles from './layout.module.css';
import { useReactFlowLayout } from 'hooks/automation/tree';
import { useAutomationStepService, useStepStoreActions } from 'hooks/automation/steps';
import { TreeContext } from 'contexts/automation';
import GmModal from 'app/shared/modal/modal';
import { StepForm } from './add-step';
import { RootEntryButton, RootNodesConnectorButton } from '../../components/widget/root';
import { AutomationTriggerWidget, TriggerBranchWidget } from '../../components/widget/trigger';
import { AutomationDelayWidget } from '../../components/widget/delay';
import { AutomationActionWidget } from '../../components/widget/action';
import { toast } from 'react-toastify';

export const AutomationLayout = (props) => {
  const { automation_id: template_id } = props;

  const { fetchSteps } = useAutomationStepService();
  const { addManySteps } = useStepStoreActions();
  const { toggleStepModal, step_modal } = useContext(TreeContext);

  const { edges, nodes, onConnect, onEdgeChange, onNodeChange } = useReactFlowLayout(template_id);
  const nodeTypes = useMemo(
    () => ({
      rootNodeConnector: RootNodesConnectorButton,
      action: AutomationActionWidget,
      trigger: AutomationTriggerWidget,
      triggerBranch: TriggerBranchWidget,
      delay: AutomationDelayWidget,
      entryPointBtn: () => <></>
    }),
    []
  );

  useEffect(() => {
    if (template_id === 'new') return;
    fetchSteps({ query_string: `template_id=${template_id}&type=!exit` }).then(
      ({ steps, error }) => {
        if (error) return toast.error(error);
        addManySteps(steps);
      }
    );
  }, [template_id]);

  return (
    <div className={styles.journeyMap}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onNodesChange={onNodeChange}
        onEdgesChange={onEdgeChange}
        fitView={true}
        minZoom={0.1}
      >
        <Panel position="top-left">
          <RootEntryButton data={{ template_id }} />
        </Panel>
        <Controls />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
      <GmModal
        show_header={false}
        show_modal={step_modal?.show}
        bodyClassName={styles.modalBody}
        onClose={() => {
          toggleStepModal({
            template_id: 0,
            parents: [],
            show: false
          });
        }}
      >
        <StepForm />
      </GmModal>
    </div>
  );
};
