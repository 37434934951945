import { capitalizeText } from 'app/shared/utils/general';
import { useEffect, useState } from 'react';

const useValidator = (rules = {}) => {
  const [errors, setErrors] = useState({});
  const [form_is_valid, setFormValidity] = useState(false);

  const [required_fields, setRequiredFields] = useState([]);
  const [validation_status, setValidationStatus] = useState({});

  useEffect(() => {
    if (required_fields && required_fields.length) return;
    setRequiredFields(() => Object.keys(rules).filter((field) => rules[field].required));
  }, [rules]);

  useEffect(() => {
    let validity = false;
    for (const status of Object.values(validation_status)) {
      if (status) {
        validity = true;
        continue;
      }
      validity = false;
      break;
    }

    for (const required_field of required_fields) {
      if (!validation_status[required_field]) {
        validity = false;
      }
    }

    setFormValidity(validity);
  }, [required_fields, validation_status]);

  const transformName = (name) => {
    const transformedName = name.replace(/_/g, ' ');
    return capitalizeText(transformedName);
  };

  const validateField = (fieldName = '', value = '', options = {}) => {
    if (!fieldName || !rules[fieldName]) return true;

    let errorMessage = '';
    const cleanedFieldName = transformName(fieldName);
    const { customValidator, required, minLength, maxLength, pattern } = rules[fieldName];

    if (required && !value) {
      errorMessage = `${cleanedFieldName} is required.`;
    } else if (minLength && value.length < minLength) {
      errorMessage = `${cleanedFieldName} must be at least ${minLength} characters`;
    } else if (maxLength && value.length > maxLength) {
      errorMessage = `${cleanedFieldName} must be at most ${maxLength} characters`;
    } else if (pattern && !value.match(pattern)) {
      errorMessage = `Invalid ${cleanedFieldName} format.`;
    } else if (customValidator) {
      const { isValid, message } = customValidator(value, { ...options, required });
      if (!isValid) errorMessage = message;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
    setValidationStatus((curr_statuses) => ({ ...curr_statuses, [fieldName]: !errorMessage }));
    return !errorMessage;
  };

  return { errors, form_is_valid, validateField };
};

export default useValidator;
