import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ListMailingList from './list/list';
import { MailingListPage } from './view';

const MailingListModule = () => {
  return (
    <Routes>
      <Route path=":id/*" element={<MailingListPage />} />
      <Route path="/*" element={<ListMailingList />} />
    </Routes>
  );
};

export default MailingListModule;
