import dagre from '@dagrejs/dagre';

export const useDagreLayout = (width = 400, height = 150) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const getLaidOutElements = ({ nodes = [], edges = [], direction = 'LR' }) => {
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    // re-align nodes to match reactflow
    nodes.forEach((node) => {
      const node_with_position = dagreGraph.node(node.id);

      node.position = {
        x: node_with_position.x - width / 2,
        y: node_with_position.y - height / 2
      };
    });

    return { nodes, edges };
  };

  return { getLaidOutElements };
};
