import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import styles from './selector.module.css';
import { GridColumn } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';

const INTERNAL_FIELDS = ['firstname', 'lastname', 'date_of_birth', 'email', 'gender'].map(
  (field) => ({
    label: field,
    value: field
  })
);

export const Column = ({ hide, id, data, onChange = (f) => f, onRemove }) => {
  const [field_name, setFieldName] = useState('');
  const [internal_field, setInternalField] = useState(INTERNAL_FIELDS[0]);

  useEffect(() => {
    if (!data || !Object.keys(data).length) return;
    setFieldName(Object.values(data)[0]);
    setInternalField(INTERNAL_FIELDS.find((field) => field.value === Object.keys(data)[0]));
  }, [data]);

  useEffect(() => {
    onChange({
      key: id,
      field_name,
      internal_field
    });
  }, [field_name, internal_field]);

  return (
    <GridColumn>
      <div className={styles['contact-row-wrapper']}>
        <DetailItem title="Element ID">
          <SimpleInput input_id="field_name" value={field_name} onInput={(e) => setFieldName(e)} />
        </DetailItem>
        <DetailItem title="Map to">
          <MultiSelect
            options={INTERNAL_FIELDS}
            onChange={setInternalField}
            value={internal_field}
          />
        </DetailItem>
        {hide && (
          <span className={styles['remove-icon']}>
            <Icon name="trash" onClick={() => onRemove(id)} />
          </span>
        )}
      </div>
    </GridColumn>
  );
};
