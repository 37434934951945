import React, { useState, useEffect, useRef } from 'react';
import DataTableItem from './item/item';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';

import '../datatable.css';
import SpinnerFifty from 'app/shared/spinners/spinner-50/spinner-50';
import SpinnerTwentyFive from 'app/shared/spinners/spinner-25/spinner-25';
import { MoreButton } from 'app/shared/button/more';
import { IconInput } from 'app/shared/input';
import { Icon } from 'assets/icons';

const DataTable = (props) => {
  const { action, config, onClick, onDataRequest, onListModeChange, onSearchRequest, showHeader } =
    props;
  const { actions, clear_table, fields, items, search_text, is_search_mode } = config;

  const list_wrapper = useRef();

  const [page_number, setPageNumber] = useState(0);
  const [is_page_end, setIsPageEnd] = useState(false);
  const [is_bulk_selection, setIsBulkSelection] = useState(false);

  const [is_loading, setIsLoading] = useState(false);
  const [is_loading_more, setIsLoadingMore] = useState(false);
  const [is_processing, setProcessing] = useState(false);

  const [selected_items, setSelectedItems] = useState({});
  const [table_items, setTableItems] = useState([]);
  const [items_to_display, setItemsToDisplay] = useState([]);

  const [search_keys, setSearchKeys] = useState('');
  const [search_keyword, setSearchKeyword] = useState('');
  const [search_results, setSearchResults] = useState([]);

  useEffect(() => {
    buildSearchKeys();
    setPageNumber(0);
    const normal_list = list_wrapper.current;
    normal_list.addEventListener('scroll', () => handleListScrollEnd(normal_list));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItemsToDisplay([]);
    setIsLoading(true);
  }, [is_search_mode]);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_page_end]);

  useEffect(() => {
    setIsPageEnd(false);
    setProcessing(() => true);
    const processed_items = items.map((item) => ({
      ...item,
      search_metadata: {
        haystack: Object.values(item).join().toLowerCase(),
        checked: false
      }
    }));

    let results = [...(clear_table ? [] : table_items), ...processed_items].reduce(
      (sack, item) => ({
        ...sack,
        [item.id]: item
      }),
      {}
    );

    if (is_loading_more) setIsLoadingMore(!is_loading_more);

    //
    if (is_search_mode) {
      results = [...(clear_table ? [] : search_results), ...processed_items].reduce(
        (sack, item) => ({
          ...sack,
          [item.id]: item
        }),
        {}
      );
      setSearchResults(Object.values(results));
    } else {
      setTableItems(Object.values(results));
    }

    setItemsToDisplay(() => Object.values(results).sort((a, b) => b.id - a.id));
    if (is_loading) setIsLoading(!is_loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (!items_to_display.length) return;

    setProcessing(() => false);
  }, [items_to_display]);

  const closeActionMode = () => {
    setSelectedItems({});
    setIsBulkSelection(false);
  };

  const buildSearchKeys = () => {
    const keys = [];
    fields.forEach((field) => {
      if (!field.isTitle && !field.isTagline) return;
      keys.push(field.key);
    });

    setSearchKeys(keys.join());
  };

  const handleItemClick = (item_data) => {
    onClick(item_data);
  };

  const handleListScrollEnd = (target) => {
    const scroll_top = target.scrollTop;
    const scroll_height = target.scrollHeight;
    const offset_height = target.offsetHeight;
    const content_height = scroll_height - offset_height;

    //
    if (content_height > scroll_top) return;

    setIsPageEnd(true);
    setIsLoadingMore(true);
  };

  const handleSearch = async (keyword) => {
    onListModeChange(Boolean(keyword));
    setSearchKeyword(keyword);
    setSearchResults([]);

    setItemsToDisplay([]);
    setIsLoading(true);

    // throttle
    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500));
    onSearchRequest(search_keys, keyword, 0);
  };

  const handleSelection = (selected_item) => {
    const selections = { ...selected_items, [selected_item.id]: selected_item };
    setSelectedItems(selections);
  };

  const handleUnselection = (id) => {
    const selections = { ...selected_items };
    delete selections[id];
    setSelectedItems(selections);
  };

  const loadMore = () => {
    if (is_search_mode) {
      onSearchRequest(search_keys, search_keyword, page_number);
    } else onDataRequest(page_number);
    setPageNumber(page_number + 1);
  };

  const processAction = (name) => {
    if (!Object.keys(selected_items).length) return;

    const is_single = Object.keys(selected_items).length === 1;
    action({
      name,
      type: is_single ? 'single' : 'bulk',
      data: is_single ? Object.values(selected_items)[0] : Object.values(selected_items)
    });

    closeActionMode();
  };

  const toggleBulkSelection = () => {
    if (is_bulk_selection) {
      closeActionMode();
      return;
    }

    setIsBulkSelection(true);
    setTimeout(() => {
      const source = is_search_mode ? search_results : table_items;
      const selections = source.reduce((sack, result) => {
        if (!result.is_active) return sack;
        return {
          ...sack,
          [result.id]: result
        };
      }, {});
      setSelectedItems(selections);
    }, 250);
  };

  return (
    <div className="gm-datatable">
      <section className="gm-datatable-header">
        {Object.keys(selected_items).length ? (
          <div>
            <div className="gm-datatable-actions-wrapper">
              <div>
                <span className="gm-datatable-bulk-checkbox">
                  <input type="checkbox" onChange={toggleBulkSelection} />
                </span>
                <span className="gm-datatable-metadata">
                  {Object.keys(selected_items).length} item(s)
                </span>
                <div style={{ width: 'fit-content', marginRight: '10px' }}>
                  {Object.keys(selected_items).length > 1 ? (
                    <ContextMenu
                      actions={actions.bulk}
                      callback={processAction}
                      text={<MoreButton text="Actions" disabled={!actions.bulk} />}
                    />
                  ) : (
                    <ContextMenu
                      actions={actions.single()}
                      callback={processAction}
                      text={<MoreButton text="Action" />}
                    />
                  )}
                </div>
              </div>
              <Icon name="close" className="close-action" onClick={closeActionMode} />
            </div>
          </div>
        ) : showHeader ? (
          <div className="gm-datatable-search">
            <IconInput
              placeholder={search_text || 'Search'}
              icon_name="search"
              onInput={(value) => handleSearch(value)}
              value={search_keyword}
              wrapperClassName="search-input"
            />
          </div>
        ) : null}
      </section>
      <section className="gm-datatable-table">
        {is_loading ? (
          <div className="gm-datatable-loader">
            <SpinnerFifty />
          </div>
        ) : (
          <div ref={list_wrapper} className="gm-datatable-item-list-wrapper">
            {items_to_display.map((item, index) => (
              <DataTableItem
                data={item}
                fields={fields}
                index={index}
                item_click_callback={handleItemClick}
                key={item.id}
                bulk_selection={is_bulk_selection}
                selection_callback={handleSelection}
                unselection_callback={handleUnselection}
                deselect={!Object.keys(selected_items).length}
              />
            ))}
            <div className="mobi-no-items">
              {!is_processing && !items_to_display.length ? 'No items found' : ''}
            </div>
            <div className="gm-datatable-foot-loader">
              {is_loading_more ? <SpinnerTwentyFive /> : <></>}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};
export default DataTable;
