import React, { useState, useEffect } from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { useeReferralService } from 'hooks/iam/referrals';
import styles from './referral.module.css';
import { toast } from 'react-toastify';
import { toReadableNumber } from 'app/shared/utils/number';
import { useSelector } from 'react-redux';

export const ReferralList = () => {
  const { referral_code } = useSelector((store) => store.user_data);
  const { fetchReferrals } = useeReferralService();

  const [has_more, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [referrals, setReferrals] = useState({});

  useEffect(() => {
    handleReadRequest();
  }, []);

  const handleReadRequest = async (page = 0, population = 10) => {
    const { referrals, error, size } = await fetchReferrals({
      query_string: `code=${referral_code}&page=${page}&population=${population}&sort_by=-created_on`
    });

    if (error) return toast.error(error);
    const referral_map = referrals.reduce((sac, ref) => ({ ...sac, [ref.id]: ref }), {});
    setHasMore(!!size);
    setReferrals((cuur_referrals) => ({ ...cuur_referrals, ...referral_map }));
    setPage(() => page + 1);
  };

  const loadMoreReferrals = () => {
    handleReadRequest(page);
  };

  return (
    <BasicInfoSection title="My Referrals">
      {!Object.keys(referrals).length ? (
        <p style={{ textAlign: 'center' }}>You have no referral bonus yet</p>
      ) : (
        Object.values(referrals).map((referral) => (
          <GridRow key={referral.id} num_of_columns={3}>
            <GridColumn span={2}>
              <DetailItem title="ORGANIZATION NAME">{referral.referred?.name}</DetailItem>
            </GridColumn>

            <GridColumn>
              <DetailItem title="REWARD">{toReadableNumber(referral.amount)}</DetailItem>
            </GridColumn>
          </GridRow>
        ))
      )}
      {has_more && (
        <a className={styles.readMore} onClick={loadMoreReferrals}>
          Load More
        </a>
      )}
      <Spacer multiple={2} />
    </BasicInfoSection>
  );
};
