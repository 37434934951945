import styles from './web.module.css';
import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { CodeWrapper } from 'app/shared/wrappers/code';

export const WebPushConfiguration = () => {
  return (
    <div className={styles.wrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="VAPID Key">
            <CodeWrapper
              text={`BGlX_kj-D7u2_D0A-kBLEiXFfg9zY6-S6CPvAuTlU-c0sz8Z63rt4woH0jvcCiYdFPLBV6QAcLsXRKtiHXi4JMg`}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
    </div>
  );
};
