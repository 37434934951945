import { isHTMLBodyEmpty } from '../../general';
import { isEmailValid } from '../../input';

export const transactionalValidationConfig = {
  name: {
    required: true
  },
  subject: {
    required: true
  },
  sender_name: {
    required: true
  },
  sender_email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Sender Email';
      } else result.is_valid = true;

      return result;
    }
  },
  body: {
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!value || isHTMLBodyEmpty(value)) {
        result.message = 'Transactional body cannot be empty.';
      } else result.is_valid = true;

      return result;
    }
  },
  code: {
    pattern: /^[a-zA-Z0-9_]*$/,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value.startsWith('_')) {
        result.message = 'Code must not start with an underscore.';
      } else result.is_valid = true;

      return result;
    }
  }
};
