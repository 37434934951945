import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { countries } from 'app/data/countries';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { DetailItem } from 'app/shared/info-section/item';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import useValidator from 'hooks/validator';
import { useOrganizationService } from 'hooks/organization';
import { organizationValidationConfig } from 'app/shared/utils/form/validation/organization';
import { useNavigate } from 'react-router-dom';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import styles from '../organization.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addDataToStore } from 'store/actions/user-data';
import { ReactComponent as Icon } from 'assets/icons/logo-sm.svg';
import { industries } from 'app/data/industries';

const CONTACT_CLASSES = [
  { label: '0 - 5,000', value: '0:5000' },
  { label: '5,001 - 20,000', value: '5001:20000' },
  { label: '20,001 - 50,000', value: '20001:50000' },
  { label: '50,001 - 100,000', value: '50001:100000' },
  { label: '100,001 - 250,000', value: '100001:250000' },
  { label: '250,001 - 300,000', value: '250001:300000' },
  { label: '300,001 - 400,000', value: '300001:400000' },
  { label: '400,001 - 500,000', value: '400001:500000' },
  { label: '500,001 - 1,000,000', value: '500001:1000000' },
  { label: '1,000,000+', value: '1000000' }
];

export const CreateOrganization = () => {
  const user_data = useSelector((state) => state.user_data);
  const { email: user_email, memberships } = user_data;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createOrganization } = useOrganizationService();
  const { errors, form_is_valid, validateField } = useValidator(organizationValidationConfig);

  const [is_membership_active, setActiveMembership] = useState(false);
  const [form_data, setUserData] = useState({
    name: '',
    email: '',
    street: '',
    state: '',
    referral_code: '',
    country: {}
  });
  const [saving, setSaving] = useState(false);
  const [selected_industry, setSelectedIndustry] = useState(industries[0]);
  const [selected_subscriber_count, setSelectedSubscriberCount] = useState(CONTACT_CLASSES[0]);

  useEffect(() => {
    if (!memberships || !memberships.length) return;
    const has_active_membership = memberships.some((doc) => doc.status === 'active');
    setActiveMembership(has_active_membership);
  }, [memberships]);

  const submitForm = async () => {
    const data = {
      address: {
        street: form_data.street,
        state: form_data.state,
        country: form_data.country.value
      },
      emails: [form_data.email],
      industry: selected_industry.value,
      name: form_data.name,
      phone_number: form_data.phone_number,
      referral_code: form_data.referral_code,
      subscriber_count: selected_subscriber_count.value,
      user_email
    };

    setSaving(true);
    const response = await createOrganization({ data });
    dispatch(
      addDataToStore({
        ...user_data,
        tenant_id: response.id,
        token: response.token,
        memberships: response.memberships,
        logged_in_time: Date.now()
      })
    );
    setSaving(false);
    if (!response) {
      return;
    }

    toast.success('Organization created successfully.');
    navigate('/dashboard');
  };

  const handleChange = (field, value) => {
    validateField(field, value);
    setUserData((previous_values) => ({
      ...previous_values,
      [field]: value
    }));
  };

  const handleIndustryChange = (selection) => {
    setSelectedIndustry(selection);
    validateField('industry', selection.value);
  };

  const handleSubscriberCountChange = (selection) => {
    setSelectedSubscriberCount(selection);
    validateField('subscriber_count', selection.value);
  };

  const handleCancelModal = () => {
    navigate('/dashboard');
  };

  const handleLogout = () => {
    dispatch(addDataToStore({}));
    navigate('/login');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Icon />
        <div className={styles.siteName} aria-labelledby="Site Name">
          Go-Mailer<span className={styles.stub}>.</span>
        </div>
      </div>
      <BasicInfoSection title="Create Organisation">
        <GridRow num_of_columns={1}>
          <GridColumn num_of_columns={1}>
            <DetailItem title="Name">
              <SimpleInput
                input_id="name"
                value={form_data.name}
                onInput={(value) => handleChange('name', value)}
                error={errors.name}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Email Address">
              <SimpleInput
                input_id="email-address"
                value={form_data.email}
                onInput={(value) => handleChange('email', value)}
                error={errors.email}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={3}>
          <GridColumn>
            <DetailItem title="Phone Number">
              <SimpleInput
                input_id="phone-number"
                value={form_data.phone_number}
                onInput={(value) => handleChange('phone_number', value)}
                error={errors.phone_number}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Est. Number of Subscribers">
              <MultiSelect
                input_id="subscribers"
                options={CONTACT_CLASSES}
                value={form_data.subscriber_count}
                onChange={handleSubscriberCountChange}
                error={errors.subscriber_count}
                isSorted={false}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Industry">
              <MultiSelect
                input_id="industry"
                options={industries}
                value={selected_industry}
                onChange={handleIndustryChange}
                error={errors.industry}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Street address">
              <SimpleInput
                input_id="street-address"
                value={form_data.street}
                onInput={(value) => handleChange('street', value)}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <GridRow num_of_columns={3}>
          <GridColumn>
            <DetailItem title="State">
              <SimpleInput
                input_id="state"
                value={form_data.state}
                onInput={(value) => handleChange('state', value)}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Country">
              <MultiSelect
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.code
                }))}
                onChange={(value) => handleChange('country', value)}
                value={form_data.country}
                isMulti={false}
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="REFFERAL CODE">
              <SimpleInput
                input_id="referral_code"
                value={form_data.referral_code}
                onInput={(value) => handleChange('referral_code', value)}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <Spacer multiple={2} />
        <GridRow num_of_columns={2}>
          <GridColumn>
            <Button
              text="Cancel"
              onClick={() => (is_membership_active ? handleCancelModal() : handleLogout())}
              type="secondary"
            />
          </GridColumn>
          <GridColumn>
            <Button
              onClick={submitForm}
              text="Save"
              loading={saving}
              disabled={!form_is_valid || saving}
            />
          </GridColumn>
        </GridRow>
      </BasicInfoSection>
    </div>
  );
};
