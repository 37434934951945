import React, { useContext } from 'react';
import { TreeContext } from 'contexts/automation';
import { WidgetSelector } from '../../../components/config';
import { AutomationTriggerConfig } from '../../../components/config/trigger';
import { AutomationDelayConfig } from '../../../components/config/delay';
import { AutomationActionConfig } from '../../../components/config/action';

export const StepForm = () => {
  const { widget_to_create } = useContext(TreeContext);

  return (
    <>
      {!widget_to_create && <WidgetSelector />}
      {widget_to_create === 'delay' && <AutomationDelayConfig />}
      {(widget_to_create === 'trigger' || widget_to_create === 'exit') && (
        <AutomationTriggerConfig />
      )}
      {widget_to_create === 'action' && <AutomationActionConfig />}
    </>
  );
};
