import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './sliding.module.css';

const SlidingNav = ({ actions = null, nav_items, className }) => {
  const [items, setItems] = useState([]);
  const [active_item, setActiveItem] = useState('');

  useEffect(() => {
    if (!nav_items || !nav_items.length) return;
    if (!active_item) setActiveItem(() => nav_items[0].path);
    setItems(nav_items.filter((item) => !item?.hidden));
    handleNavigation(window.location.pathname);
  }, [nav_items]);

  const handleNavigation = (path) => {
    setActiveItem(() => path);
  };

  return (
    <>
      <div className={cx(styles.slidingNav, className)}>
        <div>
          {items.map((item) => (
            <span key={item.path}>
              <NavLink to={item.path} onClick={(e) => handleNavigation(item.path)}>
                <span
                  className={cx(styles.navItem, active_item === item.path && styles.activeItem)}
                >
                  {item.title || ''}
                </span>
              </NavLink>
            </span>
          ))}
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div className={styles.mobileActions}>{actions}</div>
    </>
  );
};

export default SlidingNav;
