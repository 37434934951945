import { useDispatch } from 'react-redux';
import { loadAudienceToStore } from 'store/actions/audience';
import { loadAutomationsToStore } from 'store/actions/automation';
import { loadCampaignsToStore } from 'store/actions/campaign';
import { loadContactsToStore } from 'store/actions/contact';
import { loadFlagsToStore } from 'store/actions/flag';
import { loadRolesToStore } from 'store/actions/role';
import { loadTransactionalsToStore } from 'store/actions/transactional';

export const useAppStore = () => {
  const dispatch = useDispatch();

  const clearResources = () => {
    dispatch(loadAudienceToStore([]));
    dispatch(loadAutomationsToStore([]));
    dispatch(loadCampaignsToStore([]));
    dispatch(loadContactsToStore([]));
    dispatch(loadFlagsToStore([]));
    dispatch(loadRolesToStore([]));
    dispatch(loadTransactionalsToStore([]));
  };

  return { clearResources };
};
