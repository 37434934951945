/** */
import React from 'react';
import './spinner.css';
import { Button } from 'app/shared/button';

const Spinner = ({ reload }) => {
  return (
    <>
      {reload ? (
        <div className="reload-container">
          <p>Error loading page</p>
          <Button text="Reload" onClick={() => window.location.reload()} />
        </div>
      ) : (
        <div className="gm-spinner-50">
          <div className="loadingio-spinner-dual-ring-mnt0rrug7d">
            <div className="ldio-413e85d3ydp">
              <div></div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Spinner;
