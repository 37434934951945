import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import React, { useEffect, useState } from 'react';
import styles from './item.module.css';

export const TutorialItem = ({ data = {} }) => {
  const [urls, setUrls] = useState({});
  const [show_video, setShowVideo] = useState(false);

  useEffect(() => {
    if (!data.urls) return;

    setUrls(data.urls);
  }, [data.url]);

  const handleRead = () => {
    window.open(urls?.blog, '_blank');
  };

  return (
    <div className={styles.wrapper}>
      <h5>{data.title}</h5>
      <div className={styles.thumbnail}>
        <img alt={data.title} src={urls.thumbnail} />
      </div>
      <Spacer multiple={2} />
      <GridRow num_of_columns={2}>
        <GridColumn>
          <Button text="Read article" type="secondary" onClick={handleRead} />
        </GridColumn>
        <GridColumn>
          <Button text="Watch Video" onClick={() => setShowVideo(true)} />
        </GridColumn>
      </GridRow>
      <GmModal
        title={data.title}
        show_title
        show_modal={show_video}
        onClose={() => setShowVideo(false)}
      >
        <iframe
          width="100%"
          height="500px"
          src={show_video ? urls.video : ''}
          title={data.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullscreen
        ></iframe>
      </GmModal>
    </div>
  );
};
