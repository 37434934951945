/** */

import {
  ADD_MANY_STEPS_TO_STORE,
  ADD_ONE_STEP_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  REMOVE_MANY_STEPS_FROM_STORE,
  REMOVE_ONE_STEP_FROM_STORE,
  UPDATE_ONE_STEP_IN_STORE
} from '../actions/step';

export default (state = {}, action) => {
  const { type, payload } = action;
  let previous_state;
  let parents = {};
  if (type === ADD_ONE_STEP_TO_STORE) {
    parents = payload.parents.reduce((sac, parent_id) => {
      if (!state[parent_id]) return sac;
      return {
        ...sac,
        [parent_id]: {
          ...state[parent_id],
          children: [...(state[parent_id].children || []), payload.id]
        }
      };
    }, {});
  }

  switch (type) {
    case ADD_MANY_STEPS_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (steps, step) => ({
            ...steps,
            [step.id]: step
          }),
          {}
        )
      };

    case REMOVE_MANY_STEPS_FROM_STORE:
      previous_state = { ...state };
      payload.forEach((id) => delete previous_state[id]);
      return {
        ...previous_state
      };

    case REMOVE_ONE_STEP_FROM_STORE:
      previous_state = { ...state };
      delete previous_state[payload];
      return {
        ...previous_state
      };

    case ADD_ONE_STEP_TO_STORE:
      return {
        ...state,
        ...parents,
        [payload.id]: payload
      };

    case UPDATE_ONE_STEP_IN_STORE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      };

    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (steps, step) => ({
            ...steps,
            [step.id]: step
          }),
          {}
        )
      };

    default:
      return state;
  }
};
