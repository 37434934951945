import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { Icon } from 'assets/icons';
import React from 'react';

export const BasicAudienceInfo = ({ audience_data, onEditClick }) => {
  return (
    <BasicInfoSection title="Basic Details" icon={<Icon name="edit" onClick={onEditClick} />}>
      <DetailItem title="Name">{audience_data.name}</DetailItem>
      <DetailItem title="Description">{audience_data.description || 'N/A'}</DetailItem>
      <DetailItem title="Is Test Audience">
        {audience_data.is_test_audience ? 'Yes' : 'No'}
      </DetailItem>
    </BasicInfoSection>
  );
};
