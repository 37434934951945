import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ListCampaign from './list/list';
import { CampaignViewPage } from './views';

const CampaignModule = () => {
  return (
    <Routes>
      <Route path=":id/*" element={<CampaignViewPage />} />
      <Route path="/*" element={<ListCampaign />} />
    </Routes>
  );
};

export default CampaignModule;
