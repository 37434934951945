import { useSelector } from 'react-redux';
import * as EffectService from 'services/effect';

export const useEffectsQuery = () => {
  const { token } = useSelector((store) => store.user_data);
  return async (options = {}) => {
    const { error, payload } = await EffectService.read({ ...options, token });
    if (error) {
      return { error, payload: null };
    }

    return { error: null, payload };
  };
};

export const useEffectsService = () => {
  const { token } = useSelector((store) => store.user_data);

  return {
    fetchEffects: async (options = {}) => {
      const { error, payload } = await EffectService.read({ ...options, token });
      if (error) {
        return { effects: [], size: 0, error };
      }

      const {
        data: effects,
        meta: { size }
      } = payload;
      return { effects, size, error: null };
    }
  };
};
