import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryStatCard } from 'app/shared/cards';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useContactService } from 'hooks/contact';
import { useWalletLogService } from 'hooks/walletlogs';
import { useMailActionService } from 'hooks/mailaction';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { convertToPercent, getMonthNameFromIndex } from 'app/shared/utils/general';
import { Spacer } from 'app/layouts/generic';
import { TimeSeriesChart, processDateRange } from 'app/shared/charts/timeseries';
import { DistributionChart } from 'app/shared/charts/distribution';
import { ReportTable } from 'app/shared/datatable/simple';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';
import { getMonthTimestamps } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';
import { useMailingReportService } from 'hooks/mailing/report';

export const CampaignsDashboardSection = ({ date_range = [] }) => {
  const start = new Date(date_range[0]).getTime();
  const end = new Date(date_range[1]).getTime() + 86400000 - 1;
  const { fetchCampaigns } = useCampaignService();
  const { fetchWalletLogs } = useWalletLogService();
  const { fetchMailActions } = useMailActionService();
  const { fetchReports } = useMailingReportService();
  const { fetchDistributionReports } = useContactService();
  const { isMobile } = useScreenSize();
  const { balance: wallet_balance } = useSelector((state) => state.wallet);

  const [basic_stats, setBasicStats] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [selected_group, setSelectedGroup] = useState(0);
  const [is_loading_click_distribution, setIsLoadingClickDistribution] = useState(true);
  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);
  const [is_loading_open_distribution, setIsLoadingOpenDistribution] = useState(true);
  const [is_loading_monthly_report, setIsLoadingMonthlyReport] = useState(true);
  const [click_rate_distribution, setClickDistribution] = useState([]);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [open_rate_distribution, setOpenDistribution] = useState([]);
  const [monthly_stat, setMonthlyStat] = useState([]);
  const [campaign_count, setCampaignCount] = useState({
    all_time: 0,
    avg_spent: 0
  });

  const chart_key_filters = [
    {
      label: 'Sends',
      value: 'sends',
      default: true,
      color: 'var(--blue-primary)'
    },
    {
      label: 'Bounces',
      value: 'bounces',
      color: 'var(--orange-primary)'
    },
    {
      label: 'Opens',
      value: 'opens',
      default: true,
      color: 'var(--neutral-dark-5)'
    }
  ];

  const report_columns = [
    {
      title: 'Month',
      key: 'month'
    },
    {
      title: 'Campaigns Sent',
      key: 'total_campaigns'
    },
    {
      title: 'Emails Sent',
      key: 'total_sent'
    },
    {
      title: 'Total Opens',
      key: 'total_opens'
    },
    {
      title: 'Total Clicks',
      key: 'total_clicks'
    },
    {
      title: 'Total Bounces',
      key: 'total_bounces'
    },
    {
      title: 'Total Cost',
      key: 'total_cost'
    }
  ];

  useEffect(() => {
    fetchCampaigns({
      query_string: `status=processed&time_stamp=${start}~${end}&return_only=id&bool=-is_test_campaign`
    }).then(({ size, error }) => {
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }

      setCampaignCount((curr_count) => ({
        ...curr_count,
        all_time: size
      }));
    });

    fetchReports({ query_string: `day=${start}~${end}&resource_type=campaign` }).then(
      ({ logs }) => {
        const stats = { total_opens: 0, total_sent: 0, total_click: 0 };
        for (const log of logs) {
          const {
            stats: { sent, opened, clicked_link }
          } = log;
          stats.total_click += clicked_link || 0;
          stats.total_opens += opened || 0;
          stats.total_sent += sent || 0;
        }

        setBasicStats({
          emails_sent: stats.total_sent,
          opens: stats.total_opens,
          avg_open_rate: convertToPercent(stats.total_opens, stats.total_sent),
          link_clicks: stats.total_click,
          click_rate: convertToPercent(stats.total_click, stats.total_sent)
        });
      }
    );

    handleEmailPerformanceDateRangeChange();
  }, [start, end, date_range, selected_group]);

  useEffect(() => {
    fetchCampaigns({
      query_string: 'return_only=name,id'
    }).then(({ campaigns, error }) => {
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }
      const default_group = { label: '- All campaigns', value: 0 };
      const names = [default_group];

      campaigns.forEach((record) => {
        if (!record.name) return;
        names.push({
          label: record.name,
          value: record.id
        });
      });
      setCampaigns(() => names);
    });
  }, []);

  const handleEmailPerformanceDateRangeChange = async () => {
    const ranges = processDateRange(date_range[0], date_range[1]);
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setEmailPerformanceData(() => day_map);

    setIsLoadingEmailPerformanceData(true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      fetchReports({
        query_string: `day=${start}~${end}&resource_type=campaign${id_query}`
      }).then(({ logs }) => {
        const stats = { bounces: 0, sends: 0, opens: 0 };
        for (const log of logs) {
          const { bounced, opened, sent } = log.stats || {};
          stats.bounces += bounced || 0;
          stats.opens += opened || 0;
          stats.sends += sent || 0;
        }
        setEmailPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: { ...curr_data[label], ...stats }
        }));
      });
    }
    setIsLoadingEmailPerformanceData(false);
  };

  const handleOpenRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingOpenDistribution(() => true);

    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;
    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_id&resource_type=campaign&action=opened${id_query}`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions.map((action) => action.recipient_id);

    if (!contact_ids.length) {
      setIsLoadingOpenDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join() }
    });

    setIsLoadingOpenDistribution(() => false);
    setOpenDistribution(() => distribution);
  };

  const handleClickRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingClickDistribution(() => true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=campaign&action=clicked_link${id_query}`
    });
    if (error) return toast.error(error);

    const contact_emails = mailactions.map((action) => action.recipient_email);
    if (!contact_emails.length) {
      setIsLoadingClickDistribution(() => false);
      return;
    }
    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_emails.join(), type: 'email' }
    });

    setIsLoadingClickDistribution(() => false);
    setClickDistribution(() => distribution);
  };

  const handleGroupFilterChange = (group) => {
    setSelectedGroup(() => group);
  };

  const handleMonthlyReport = async (group) => {
    const { value: year } = group;
    const monthTimeStamps = getMonthTimestamps(year);
    if (!monthTimeStamps?.length) return;

    try {
      setIsLoadingMonthlyReport(true);
      const monthly_report = [];

      for (const { start, end } of monthTimeStamps) {
        const [{ size: total_campaigns }, { logs }, { walletlogs }] = await Promise.all([
          fetchCampaigns({
            query_string: `time_stamp=${start}~${end}&status=processed&bool=-is_test_campaign&count=1`
          }),
          fetchReports({ query_string: `day=${start}~${end}&resource_type=campaign` }),
          fetchWalletLogs({
            query_string: `time_stamp=${start}~${end}&resource_type=campaign&kind=charge&return_only=amount`
          })
        ]);

        const stats = logs.reduce((sac, log) => {
          const {
            stats: { sent, bounced, opened, clicked_link }
          } = log;
          return {
            total_sent: (sac.total_sent || 0) + (sent || 0),
            total_bounces: (sac.total_bounces || 0) + (bounced || 0),
            total_opens: (sac.total_opens || 0) + (opened || 0),
            total_clicks: (sac.total_clicks || 0) + (clicked_link || 0)
          };
        }, {});

        monthly_report.push({
          month: getMonthNameFromIndex(monthly_report.length),
          total_bounces: toReadableNumber(stats.total_bounces),
          total_clicks: toReadableNumber(stats.total_clicks),
          total_opens: toReadableNumber(stats.total_opens),
          total_sent: toReadableNumber(stats.total_sent),
          total_campaigns: toReadableNumber(total_campaigns),
          total_cost: toReadableNumber(walletlogs.reduce((s, log) => s + log.amount, 0))
        });
      }

      setMonthlyStat(monthly_report);
    } catch (error) {
      console.error('Error fetching mail data:', error);
    } finally {
      setIsLoadingMonthlyReport(false);
    }
  };

  const statDetails = [
    {
      main_stat: {
        label: 'Available credits',
        color: 'var(--neutral-light)',
        bg: 'var(--blue-primary)',
        label_color: 'var(--neutral-light)',
        value: wallet_balance
      },
      bottom_stat: {
        label: 'Charge rate:',
        value: 1
      }
    },
    {
      main_stat: { label: 'Total Campaigns', value: campaign_count.all_time },
      bottom_stat: { label: 'Emails sent', value: basic_stats.emails_sent }
    },
    {
      main_stat: { label: 'Total emails Opens', value: basic_stats.opens },
      bottom_stat: { label: 'Avg. open rate', value: basic_stats.avg_open_rate }
    },
    {
      main_stat: {
        label: 'Total link clicks',
        color: 'var(--blue-primary)',
        value: basic_stats.link_clicks
      },
      bottom_stat: {
        label: 'Avg. click rate (%)',
        value: basic_stats.click_rate
      }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard
            key={index}
            main_stat={stat.main_stat}
            bottom_stat={stat.bottom_stat}
            wrapperStyle={{ marginRight: '15px' }}
          />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />

      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                showDatePicker={false}
                data={Object.values(email_performance_data)}
                filter_data={campaigns}
                key_filters={chart_key_filters}
                graph_title="Email Performance"
                is_loading_data={is_loading_email_performance_data}
                onDateRangeChange={handleEmailPerformanceDateRangeChange}
                onGroupChange={handleGroupFilterChange}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}
      <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
        <GridColumn span={2}>
          <DistributionChart
            title="Open rate distribution"
            data={open_rate_distribution}
            is_loading={is_loading_open_distribution}
            onChange={handleOpenRateDistribution}
          />
        </GridColumn>
        <GridColumn span={2}>
          <DistributionChart
            data={click_rate_distribution}
            is_loading={is_loading_click_distribution}
            title="Click rate distribution"
            onChange={handleClickRateDistribution}
          />
        </GridColumn>
      </GridRow>
      {!isMobile && (
        <>
          <Spacer multiple={4} />
          <GridRow num_of_columns={1}>
            <ReportTable
              title="Month on Month Report"
              columns={report_columns}
              data={monthly_stat}
              onChange={handleMonthlyReport}
              is_loading={is_loading_monthly_report}
            />
          </GridRow>
        </>
      )}
    </>
  );
};
