import { useSelector } from 'react-redux';
import * as MailActionService from 'services/mail-actions';

export const useMailActionService = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchMailActions = async (options = {}) => {
    const { error, payload } = await MailActionService.read({ ...options, token });
    if (error) {
      return { mailactions: [], size: 0, error: 'Unable to fetch mail actions at this time.' };
    }
    const { data: mailactions, meta } = payload;
    return { mailactions, size: meta ? meta.size : mailactions.length, error: null };
  };

  return { fetchMailActions };
};
