import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import styles from './contacts.module.css';

const INTERNAL_FIELDS = ['firstname', 'lastname', 'date_of_birth', 'email', 'gender'].map(
  (field) => ({
    label: field,
    value: field
  })
);

export const Column = ({ id, data, onChange = (f) => f, onRemove }) => {
  const INTERNAL_FIELD_OPTIONS = INTERNAL_FIELDS;
  const [field_name, setFieldName] = useState('');
  const [internal_field, setInternalField] = useState(INTERNAL_FIELD_OPTIONS[0]);

  useEffect(() => {
    if (!data || !Object.keys(data).length) return;
    setFieldName(Object.values(data)[0]);
    setInternalField(INTERNAL_FIELD_OPTIONS.find((field) => field.value === Object.keys(data)[0]));
  }, [data]);

  useEffect(() => {
    onChange({
      key: id,
      field_name,
      internal_field
    });
  }, [field_name, internal_field]);

  return (
    <GridRow num_of_columns={2}>
      <GridColumn>
        <DetailItem title="Header name">
          <SimpleInput input_id="field_name" value={field_name} onInput={(e) => setFieldName(e)} />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <div className={styles.fieldMap}>
          <DetailItem title="Map to">
            <MultiSelect
              options={INTERNAL_FIELD_OPTIONS}
              onChange={setInternalField}
              value={internal_field}
            />
          </DetailItem>
          <span className={styles.trashIcon}>
            <Icon name="trash" onClick={() => onRemove(id)} />
          </span>
        </div>
      </GridColumn>
    </GridRow>
  );
};
