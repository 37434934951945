import { useSelector } from 'react-redux';
import * as JourneyService from 'services/automation/journey';

export const useAutomationJourneyService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchJourneys = async (options = {}) => {
    const { error, payload } = await JourneyService.read({ ...options, token });
    if (error) {
      return { journeys: [], size: 0, error };
    }

    const {
      data: journeys,
      meta: { size }
    } = payload;
    return { journeys, size, error: null };
  };

  const searchJourneys = async (keys, keyword, options = {}) => {
    const { error, payload } = await JourneyService.search(keys, keyword, { token, ...options });
    if (error) {
      return { journeys: [], size: 0 };
    }

    const {
      data: journeys,
      meta: { size }
    } = payload;
    return { journeys, size };
  };

  return {
    fetchJourneys,
    searchJourneys
  };
};
