import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { set_process } from 'store/actions/process';
import { isEmailValid } from 'app/shared/utils/input';

import GmModal from 'app/shared/modal/modal';
import { MultiSelect } from 'app/shared/select';
import EmailVerificationForm from 'app/shared/verify-email/verify-email';
import { SimpleInput } from 'app/shared/input';
import styles from './sidebar.module.css';
import { Checkbox } from 'app/shared/input/checkbox';
import { isGeneralEmail } from 'app/shared/utils/general';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { DetailItem } from 'app/shared/info-section/item';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailboxService } from 'hooks/users/mailbox';

export const SidebarForm = ({ transactional, mailbox, setMailbox, onChange, errors }) => {
  const dispatch = useDispatch();
  const { email_verification } = useSelector((state) => state.processes);
  const { initiateVerification } = useMailboxService();

  const [is_verifying, setIsVerifying] = useState(false);
  const [show_verification_modal, setShowVerificationModal] = useState(false);
  const [sender_eamil_is_verified, setSenderEmailVerificationStatus] = useState(true);
  const [show_generic_email_modal, setShowGenericEmailModal] = useState(false);
  const [verify_generic_email, setVerifyGenericEmail] = useState(false);

  useEffect(() => {
    if (!email_verification) return;
    setSenderEmailVerificationStatus(true);
    mailbox.emails.push(transactional.sender_email);
    dispatch(set_process('email_verification', false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_verification, dispatch]);

  useEffect(() => {
    if (!email_verification) return;

    setSenderEmailVerificationStatus(true);
    mailbox.emails.push(transactional.sender_email);
    dispatch(set_process('email_verification', false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_verification, dispatch]);

  useEffect(() => {
    if (!transactional.sender_email) setSenderEmailVerificationStatus(true);
    processSenderEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactional, mailbox]);

  useEffect(() => {
    if (verify_generic_email && transactional.sender_email) {
      handleEmailVerification();
    }
  }, [verify_generic_email]);

  const handleEmailVerification = async () => {
    try {
      setIsVerifying(true);
      if (isGeneralEmail(transactional.sender_email) && !verify_generic_email) {
        setShowGenericEmailModal(true);
        return;
      }

      const { mailbox } = await initiateVerification({
        data: { email: transactional.sender_email }
      });

      if (!mailbox) return;

      setMailbox(mailbox);
      setShowVerificationModal(true);
    } finally {
      setIsVerifying(false);
      setVerifyGenericEmail(false);
    }
  };

  const handleGenericEmailVerification = async (permitted) => {
    if (permitted) {
      setVerifyGenericEmail(true);
    }
    setShowGenericEmailModal(false);
  };

  const processSenderEmail = (e) => {
    if (!isEmailValid(transactional.sender_email)) return;

    if (!mailbox.emails.includes(transactional.sender_email)) {
      setSenderEmailVerificationStatus(false);
      return;
    }

    setSenderEmailVerificationStatus(true);
  };

  const handleItemSelection = () => {
    const retry_email = transactional?.retry_email;
    onChange('retry_email', !retry_email);
  };

  return (
    <div className={styles.container}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Title" required>
            <SimpleInput
              id="transactional_title"
              type="text"
              value={transactional.name}
              onInput={(e) => onChange('name', e)}
              error={errors.name}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Code">
            <SimpleInput
              id="transactional_code"
              type="text"
              value={transactional.code}
              onInput={(e) => onChange('code', e)}
              error={errors.code}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Subject" required>
            <SimpleInput
              id="transactional_subject"
              type="text"
              value={transactional.subject}
              onInput={(e) => onChange('subject', e)}
              error={errors.subject}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Sender's name" required>
            <SimpleInput
              id="sender_name"
              type="text"
              value={transactional.sender_name}
              onInput={(e) => onChange('sender_name', e)}
              error={errors.sender_name}
            />
          </DetailItem>
        </GridColumn>

        <GridColumn>
          <DetailItem title="Sender's email address" required>
            <MultiSelect
              options={(mailbox.emails || []).map((email) => ({
                label: email,
                value: email
              }))}
              onChange={(v) => onChange('sender_email', v.value)}
              value={{ value: transactional.sender_email, label: transactional.sender_email }}
              isCreatable
              error={errors.sender_email}
            />
          </DetailItem>
        </GridColumn>

        {!sender_eamil_is_verified && (
          <GridColumn>
            <div className="text-right text-blue-primary">
              {is_verifying ? (
                <div>
                  {' '}
                  <i>Verifying...</i>
                </div>
              ) : (
                <p>
                  <b className="px-1 text-danger is-clickable" onClick={handleEmailVerification}>
                    Click to verify email address.
                  </b>
                </p>
              )}
            </div>
          </GridColumn>
        )}

        <GridColumn>
          <Checkbox
            checked={transactional?.retry_email}
            input_id="retry_email"
            label="Retry email"
            onClick={handleItemSelection}
          />
        </GridColumn>
      </GridRow>

      <GmModal
        title="Verify e-mail address"
        show_title={true}
        show_modal={show_verification_modal}
        onClose={() => setShowVerificationModal(false)}
      >
        <EmailVerificationForm
          email={transactional.sender_email}
          mailbox={mailbox}
          onClose={() => setShowVerificationModal(false)}
        />
      </GmModal>
      <ConfirmationDialog
        title="Verify generic email domain"
        message="You are about to verify a mailbox on a generic domain. Please note that your emails may not be sent."
        callback={handleGenericEmailVerification}
        is_open={show_generic_email_modal}
      />
    </div>
  );
};
