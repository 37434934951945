import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as SMSService from 'services/messaging/sms';

export const useSMSService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createSMS = async (options = {}) => {
    const { error, payload } = await SMSService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Unable to create SMS at this time. Please try again.');
      return { message: null };
    }

    toast.success('SMS created successfully.');
    return { message: payload };
  };

  const fetchSMSs = async (options = {}) => {
    const { error, payload } = await SMSService.read({ ...options, token });
    if (error) {
      return {
        messages: [],
        size: 0,
        error: 'Unable to fetch SMS messages at this time.'
      };
    }

    const {
      data: messages,
      meta: { size }
    } = payload;
    return { messages, size, error: null };
  };

  const searchSMS = async (keys, keyword, options = {}) => {
    const { error, payload } = await SMSService.search(keys, keyword, { ...options, token });
    if (error) {
      return { error, messages: [], size: 0 };
    }

    const {
      data: messages,
      meta: { size }
    } = payload;
    return { error, messages, size };
  };

  const updateSMS = async (id, options = {}) => {
    const { error, payload } = await SMSService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return { message: null };
    }

    toast.success('SMS updated successfully.');
    return { message: payload };
  };

  return { fetchSMSs, createSMS, searchSMS, updateSMS };
};
