import React from 'react';

import Skeleton from 'react-loading-skeleton';

export const SimpleTableSkeleton = ({ rows = 4, columns = 7 }) => {
  return (
    <>
      {Array.from({ length: rows }, (_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: columns }, (_, colIndex) => (
            <td key={colIndex}>
              <Skeleton width={'100%'} height={20} style={{ zIndex: 0 }} />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default SimpleTableSkeleton;
