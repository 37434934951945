import { isEmailValid } from 'app/shared/utils/input';

export const UserInvitationSchema = {
  email: {
    required: true,
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: 'Invalid Email address' };
      if (!value || !isEmailValid(value)) return result;

      result.is_valid = true;
      result.message = '';
      return result;
    },
    customErrorMessage: {
      pattern: 'Invalid email address format'
    }
  },
  role: {
    required: true
  }
};
