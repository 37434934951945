import { toast } from 'react-toastify';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { setPageTitle } from 'store/actions/header';
import {
  addManyEventToStore,
  addEventSearchResultsToStore,
  removeOneEventFromStore
} from 'store/actions/event';
import styles from '../event.module.css';
import cx from 'classnames';
import { PermissionsContext } from 'contexts/permissions';
import { useEventsService } from 'hooks/automation/event';
import { useNavigate } from 'react-router-dom';

export const AutomationEvents = ({ handleEdit = () => {} }) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const event_in_store = useSelector((state) => state.events);
  const { permissions } = useContext(PermissionsContext);

  const { fetchEvents, removeEvent, searchEvents } = useEventsService();

  const [events, setEvents] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_event_to_delete_modal, setShowEventToDeleteModal] = useState(false);
  const [event_to_delete, setEventToDelete] = useState({});

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Event', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEvents(Object.values(event_in_store));
  }, [event_in_store]);

  const config = {
    actions: {
      single: () => [
        {
          label: 'Edit',
          value: 'edit',
          hidden: !permissions['automation_event:update']
        },
        {
          label: 'Delete',
          value: 'delete',
          hidden: !permissions['automation_event:delete']
        }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        searchable: true,
        isTitle: true
      },
      {
        title: 'Code',
        searchable: true,
        key: 'code',
        isTagline: true
      },
      {
        title: 'Type',
        key: 'type',
        formatter: (value) => {
          switch (value) {
            case 'system':
              return <span className={styles.blueBadge}>System</span>;
            default:
              return <span className={styles.infoBadge}>Custom</span>;
          }
        },
        isBadge: true
      }
    ],
    is_search_mode,
    items: events.sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'code,name',
    search_text: ''
  };

  const parseEvents = (data) => {
    const parsed_events = data?.map((event) => {
      const [code] = event.code.split(':');
      const new_element = {
        ...event,
        code
      };
      return new_element;
    });
    return parsed_events;
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;
    if (type === 'single') {
      if (name === 'edit') {
        if (data.type === 'system') {
          return toast.error('You cannot edit a system event');
        }

        handleEdit(data);
        return;
      }
      if (name === 'delete') {
        setEventToDelete(data);
        setShowEventToDeleteModal(true);
      }
    }
  };

  const handleDataRequest = async (page = 1, population = 50) => {
    try {
      setLoading(true);
      const { events } = await fetchEvents({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });
      const parsed_events = parseEvents(events);
      dispatch(addManyEventToStore(parsed_events));
    } catch (e) {
      dispatch(addManyEventToStore([]));
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { events } = await searchEvents(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=${population}`
      });

      const parsed_events = parseEvents(events);
      if (page === 0) return dispatch(addEventSearchResultsToStore(parsed_events));
      dispatch(addManyEventToStore(parsed_events));
    } catch (e) {
      dispatch(addManyEventToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSingleDeletion = async (permitted) => {
    if (permitted) {
      if (event_to_delete.in_use) {
        return toast.error('You cannot delete an event in use');
      }

      if (event_to_delete.type === 'system') {
        return toast.error('You cannot delete a system event');
      }

      const result = await removeEvent(event_to_delete.id);
      if (result) {
        toast.success('Event deleted successfully');
        dispatch(removeOneEventFromStore(event_to_delete.id));
      }
    }
    setShowEventToDeleteModal(false);
  };

  const handleItemClick = (data) => {
    navigateTo(`/automations/events/${data.code}`);
  };

  return (
    <div className={cx(styles.wrapper, 'dt-content-wrapper')}>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader={false}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: config.items?.length
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          request_complete={!loading}
          onDataRequest={handleDataRequest}
          loadingSearch={() => {}}
          onSearchRequest={handleSearchRequest}
          showHeader={false}
        />
      )}
      <ConfirmationDialog
        title="Delete Event"
        message="Are you sure you want to delete this event?"
        callback={handleSingleDeletion}
        is_open={show_event_to_delete_modal}
      />
    </div>
  );
};
