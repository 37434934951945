import { TimePicker } from '@arco-design/web-react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import React, { useEffect, useState } from 'react';
import styles from './response.module.css';

const INTERVAL_TYPES = [
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Day of week', value: 'weekday' }
];

const WEEKDAYS = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 }
];

const CONFIG_CODE = 'delay';

export const NeuronDelayConfig = ({ data = {}, onChange = () => {} }) => {
  const [interval, setInterval] = useState(0);
  const [interval_type, setIntervalType] = useState(INTERVAL_TYPES[0]);
  const [selected_weekday, setSelectedWeekday] = useState(WEEKDAYS[0]);
  const [time_range, setTimeRange] = useState(['00:00:00', '00:00:00']);

  //
  useEffect(() => {
    if (!data || !data.rule) return;

    const { rule } = data;
    setInterval(rule.interval);
    setIntervalType(
      () => INTERVAL_TYPES.find((i_type) => i_type.value === rule.interval_type) || {}
    );
    setSelectedWeekday(() => WEEKDAYS.find((weekday) => weekday.value === rule.weekday) || {});
    setTimeRange(() => rule.time_range);
  }, [data]);

  useEffect(() => {
    const rule = {
      delay: computeDelay(interval_type.value, interval),
      interval,
      interval_type: interval_type.value,
      time_range,
      timezone: new Date().getTimezoneOffset(),
      type: CONFIG_CODE,
      weekday: selected_weekday.value
    };

    onChange(rule);
  }, [interval, interval_type?.value, selected_weekday?.value, time_range]);

  const computeDelay = (interval_type, interval) => {
    return interval_type === 'hour' ? interval : 24 * interval;
  };

  return (
    <div>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Interval type">
            <MultiSelect
              options={INTERVAL_TYPES}
              onChange={setIntervalType}
              value={interval_type}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {interval_type.value === 'weekday' ? (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Day of the week">
              <MultiSelect
                options={WEEKDAYS}
                onChange={setSelectedWeekday}
                value={selected_weekday}
                isSorted={false}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      ) : (
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Interval">
              <SimpleInput onInput={setInterval} value={interval} />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
      <GridRow num_of_columns={2}>
        <GridColumn span={2}>
          <DetailItem title="Preferred Time Range">
            <TimePicker.RangePicker
              className={styles.timepicker}
              disabledSeconds
              onChange={setTimeRange}
              value={time_range}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
    </div>
  );
};
