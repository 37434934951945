import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

/** */
import GuestArea from './guest/guest';
import MemberArea from './member/member';
import { logout } from 'store/actions/user-data';

/** */

import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { UploadProvider } from 'contexts/uploads';

const getLoginStatus = createSelector(
  (state) => state.user_data,
  (user_data) => {
    const { _id, token } = user_data;
    return _id && token;
  }
);

function App() {
  const dispatch = useDispatch();
  const userIsLoggedIn = useSelector(getLoginStatus);
  const { remember_me, logged_in_time } = useSelector((state) => state.user_data);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!remember_me) {
      const expiration_time = 24 * 60 * 60 * 1000;
      const elapsed_time = Date.now() - parseInt(logged_in_time, 10);
      if (elapsed_time >= expiration_time) {
        handleLogout();
      } else {
        const remainingTime = expiration_time - elapsed_time;
        const timeoutId = setTimeout(handleLogout, remainingTime);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [remember_me]);

  return (
    <div className="app-wrapper">
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              userIsLoggedIn ? (
                <UploadProvider>
                  <MemberArea />{' '}
                </UploadProvider>
              ) : (
                <GuestArea />
              )
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
