import cx from 'classnames';
import React from 'react';
import styles from './bar.module.css';

export const ProgressBar = ({ value, bar_style = {} }) => {
  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.bar)} style={{ width: `${value}%`, ...bar_style }}></div>
    </div>
  );
};
