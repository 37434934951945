/** */
export const REMOVE_WALLET = 'remove wallet from store';
export const ADD_WALLET = 'add wallet to store';
export const UPDATE_WALLET = 'update a wallet in store.';

/** */

export const removeWallet = (payload) => ({
  type: REMOVE_WALLET,
  payload
});
export const addWallet = (payload) => ({
  type: ADD_WALLET,
  payload
});

export const updateWallet = (payload) => ({
  type: UPDATE_WALLET,
  payload
});
