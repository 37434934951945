import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import * as AudienceService from 'services/audience';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';

const AddContactToAudience = (props) => {
  const { selected_contacts, fadeOut, handleBulkUnselection } = props;
  const { token } = useSelector((state) => state.user_data);

  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected_audience, setSelectedAudience] = useState(0);

  useEffect(() => {
    AudienceService.read({ token, query_string: 'is_archived=0&return_only=name,id' }).then(
      (response) => {
        const {
          error,
          payload: { data }
        } = response;
        if (error) return;

        setAudiences(data);
      }
    );
  }, [token]);

  const submit = async () => {
    if (!selected_audience.value) {
      toast.error('Please select an audience.');
      return;
    }

    setLoading(true);
    const { error } = await AudienceService.addContact(selected_audience.value, {
      data: {
        contacts: selected_contacts.map((contact) => contact.id)
      },
      token
    });
    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.success('Contact(s) added to audience.');
    fadeOut();
    handleBulkUnselection();
  };

  return (
    <div>
      <MultiSelect
        options={audiences.map((list) => ({
          label: list.name,
          value: list.id
        }))}
        onChange={setSelectedAudience}
        value={selected_audience}
        placeholder="Select audience"
        isMulti={false}
      />
      <Button onClick={submit} className="w-100" text="Save" loading={loading} />
    </div>
  );
};

export default AddContactToAudience;
