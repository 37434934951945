import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { formatDateForDisplay } from 'app/shared/utils/date';
import styles from './list.module.css';
import { useUserService } from 'hooks/iam/user';
import { useMembershipService } from 'hooks/iam/membership';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import { capitalizeText } from 'app/shared/utils/general';
import { useNavigate } from 'react-router-dom';
import { Button } from 'app/shared/button';
import { setPageTitle } from 'store/actions/header';
import { toast } from 'react-toastify';

export const InvitationListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { acceptInvitation, fetchOrganizations } = useUserService();
  const { updateMembership } = useMembershipService();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { id: user_id, email: user_email, tenant_id } = useSelector((state) => state.user_data);

  const [is_search_mode, setSearchMode] = useState(false);
  const [is_updating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberships, setMemberships] = useState({});
  const [membership_to_update, setMembershipToUpdate] = useState({});
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [confirmation_title, setConfirmationTitle] = useState('');
  const [total_memberships, setTotalMemberships] = useState(0);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Users', path: '/users/invitations' },
        { title: 'Invitations', path: '.' }
      ])
    );
  }, []);

  const config = {
    actions: {
      single: (membership) => [
        { label: 'Accept', value: 'accept', hidden: membership.status === 'active' },
        { label: 'Decline', value: 'decline', hidden: membership.status === 'active' },
        { label: 'Leave', value: 'leave', hidden: membership.status !== 'active' }
      ]
    },
    css: {},
    fields: [
      {
        title: 'Company name',
        key: 'tenant',
        isTitle: true,
        formatter: (value) => (value ? value.name : 'N/A')
      },
      {
        title: 'Status',
        key: 'status',
        formatter: (value) => {
          let badge_class = styles.grey;
          let status = 'pending';
          switch (value) {
            case 'active':
              badge_class = styles.green;
              status = 'accepted';
              break;
            case 'invited':
              badge_class = styles.orange;
              break;
          }
          return <span className={cx(styles.badge, badge_class)}>{status}</span>;
        }
      },
      {
        title: 'Role',
        key: 'role',
        isTitle: true,
        formatter: (value) => (value ? value.name : 'N/A')
      },
      {
        title: 'Invitation date',
        key: 'created_on',
        isMetadata: true,
        formatter: (value) => formatDateForDisplay(value)
      }
    ],
    is_search_mode,
    items: Object.values(memberships).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    let new_status = '';
    if (type === 'single') {
      switch (name) {
        case 'accept':
          new_status = 'active';
          setConfirmationTitle(() => 'accept Invitation');
          break;
        case 'decline':
          new_status = 'declined';
          setConfirmationTitle(() => 'decline Invitation');
          break;
        case 'leave':
          new_status = 'left';
          if (data.user_id === data.tenant?.creator_id || data.tenant_id === tenant_id) {
            return toast.error(`Cannot leave current or own organisation.`);
          }

          setConfirmationTitle(() => 'leave organisation');
          break;
      }

      setMembershipToUpdate({ ...data, new_status });
      setShowConfirmation(() => true);
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    const {
      organizations: memberships,
      size,
      error
    } = await fetchOrganizations({
      query_string: `page=${page}&population=${population}&status=active,invited`
    });
    if (error) return toast.error(error);
    setMemberships((curr_memberships) => ({
      ...curr_memberships,
      ...memberships.reduce((s, membership) => ({ ...s, [membership.id]: membership }), {})
    }));
    setTotalMemberships(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  const updateMembershipStatus = async (permitted) => {
    if (permitted) {
      const { id, new_status } = membership_to_update;
      setIsUpdating(() => true);
      const { membership } =
        new_status === 'active'
          ? await acceptInvitation({
              data: { user_email, tenant_id: membership_to_update.tenant_id, user_id }
            })
          : await updateMembership(id, { data: { user_id, status: new_status } });
      if (membership && new_status !== 'active') {
        const existing_memberships = memberships;
        delete existing_memberships[id];
        setMemberships(() => ({ ...existing_memberships }));
        toast.success('Membership accepted');
      } else {
        setMemberships((curr_memberships) => ({
          ...curr_memberships,
          [id]: { ...curr_memberships[id], status: new_status }
        }));
        toast.success('Membership updated.');
      }
      setIsUpdating(() => false);
    }

    setMembershipToUpdate(() => ({}));
    setShowConfirmation(() => false);
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Organization" onClick={() => navigate('/organizations')} />
    </>
  );

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            action={handleDatatableAction}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            action={handleDatatableAction}
            config={{
              ...config,
              total_count: total_memberships
            }}
            checkbox
            loading_data={loading}
            table_actions={table_actions}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
          />
        )}
      </GridColumn>

      <ConfirmationDialog
        title={capitalizeText(confirmation_title)}
        message={`Are you sure you want to ${confirmation_title}?`}
        callback={updateMembershipStatus}
        is_open={show_confirmation}
        is_loading={is_updating}
      />
    </GridRow>
  );
};
