import { useSelector } from 'react-redux';
import * as CurrencyService from 'services/currency';

export const useCurrencyService = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchCurrencies = async (options = {}) => {
    const { error, payload } = await CurrencyService.read({ ...options, token });
    if (error) {
      return { currencies: null, size: 0, error };
    }
    const { data: currencies, meta } = payload;
    return { currencies, size: meta ? meta.size : currencies.length, error: null };
  };

  return { fetchCurrencies };
};
