import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailActionService } from 'hooks/mailaction';
import { useSelector } from 'react-redux';
import { useMailLogService } from 'hooks/maillog';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useTransactionalService } from 'hooks/transactional';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDatatable from 'app/shared/datatable/web/datatable';
import { useContactService } from 'hooks/contact';
import { Badge } from 'app/shared/badge';
import { toast } from 'react-toastify';

export const Maillogs = ({ email }) => {
  const { id: contact_id } = useParams();
  const { is_mobile_view } = useSelector((state) => state.metadata);

  const { fetchCampaigns } = useCampaignService();
  const { fetchContact } = useContactService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchMailActions } = useMailActionService();
  const { fetchTransactionals } = useTransactionalService();

  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState({});
  const [total_logs, setTotalLogs] = useState(0);

  const config = {
    actions: {
      single: () => []
    },
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'resource',
        isTitle: true,
        formatter: (value) => value.name || value.title || 'N/A'
      },
      {
        title: 'Subject',
        key: 'resource',
        isTagline: true,
        formatter: (value) => value.subject || 'N/A'
      },
      {
        title: 'Email type',
        key: 'class',
        formatter: (value) => <Badge text={value} type={value === 'campaign' ? 'blue' : 'orange'} />
      },
      {
        title: 'Status',
        key: 'resource',
        isBadge: true,
        formatter: (value) =>
          value.is_opened ? <Badge text="opened" type="green" /> : <Badge text="unopened" />
      }
    ],
    is_search_mode,
    items: Object.values(logs).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'name',
    search_text: ''
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    let contact_email = email;
    if (!contact_email) {
      const contact = await fetchContact(contact_id);
      contact_email = contact.email;
    }

    const { maillogs, size, error } = await fetchMailLogs({
      query_string: `recipient=${encodeURIComponent(
        contact_email
      )}&page=${page}&population=${population}`
    });
    if (error) {
      toast.error(error);
      return;
    }
    const campaign_ids = Object.keys(
      maillogs
        .filter((log) => log.class === 'campaign')
        .reduce((sac, log) => ({ ...sac, [log.resource_id]: 1 }), {})
    );

    const transactional_ids = Object.keys(
      maillogs
        .filter((log) => log.class === 'transactional')
        .reduce((sac, log) => ({ ...sac, [log.resource_id]: 1 }), {})
    );

    const [
      { mailactions: campaign_actions },
      { mailactions: transactional_actions },
      { campaigns },
      { transactionals }
    ] = await Promise.all([
      fetchMailActions({
        query_string: `resource_id=${campaign_ids.join()}&recipient_id=${contact_id}&resource_type=campaign&action=opened`
      }),
      fetchMailActions({
        query_string: `resource_id=${transactional_ids.join()}&recipient_email=${contact_email}&resource_type=transactional&action=opened`
      }),
      fetchCampaigns({ query_string: `id=${campaign_ids.join()}` }),
      fetchTransactionals({ query_string: `id=${transactional_ids.join()}` })
    ]).catch((e) => toast.error('Unable to fetch logs at this time.'));

    const opened_campaigns_by_id = campaign_actions.reduce(
      (s, action) => ({ ...s, [action.resource_id]: 1 }),
      {}
    );

    const opened_transactionals_by_id = transactional_actions.reduce(
      (s, action) => ({ ...s, [action.resource_id]: 1 }),
      {}
    );

    const campaigns_by_id = campaigns.reduce(
      (s, c) => ({ ...s, [c.id]: { ...c, is_opened: !!opened_campaigns_by_id[c.id] } }),
      {}
    );
    const transactionals_by_id = transactionals.reduce(
      (s, t) => ({ ...s, [t.id]: { ...t, is_opened: !!opened_transactionals_by_id[t.id] } }),
      {}
    );

    const parsed_logs = maillogs.reduce(
      (sac, log) => ({
        ...sac,
        [log.id]: {
          ...log,
          resource:
            (log.class === 'campaign'
              ? campaigns_by_id[log.resource_id]
              : transactionals_by_id[log.resource_id]) || {}
        }
      }),
      {}
    );

    setLogs((curr_logs) => ({ ...curr_logs, ...parsed_logs }));
    setTotalLogs(() => size);
    setLoading(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {};

  return (
    <GridRow>
      <GridColumn span={4}>
        {is_mobile_view ? (
          <MobileDatatable
            config={config}
            onListModeChange={setSearchMode}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader
          />
        ) : (
          <WebDatatable
            config={{
              ...config,
              num_of_rows: 15,
              total_count: total_logs
            }}
            checkbox
            loading_data={loading}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            showHeader={false}
          />
        )}
      </GridColumn>
    </GridRow>
  );
};
