import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import React, { useEffect, useState } from 'react';
import { DKIMConfigurationDetails } from './dkim';
import { DKIMConfigurationEditor } from './dkim/edit';
import { toast } from 'react-toastify';
import { setPageTitle } from 'store/actions/header';
import { useDispatch } from 'react-redux';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import Spinner from 'app/shared/spinners/spinner-25/spinner-25';

export const EmailSettings = () => {
  const dispatch = useDispatch();
  const { fetchConfiguration, updateConfiguration } = useMailingConfigService();

  const [config, setConfig] = useState({});
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Email', path: '/settings/email' }
      ])
    );

    fetchConfig();
  }, []);

  const fetchConfig = () => {
    setLoading(true);
    fetchConfiguration()
      .then(({ configurations: [config], error }) => {
        if (error) toast.error(error);
        setConfig(config);
        setRecords(config?.dns || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = async (cfg = null) => {
    if (!cfg) return;
    const records_to_keep = records.filter(
      (record) => record.domain.toLowerCase() !== cfg.domain.toLowerCase()
    );

    const data = { ...config, dns: records_to_keep };
    const result = await updateConfiguration(config.id, { data });
    if (result) {
      fetchConfig();
    }
  };

  return (
    <GridRow>
      <GridColumn />
      <GridColumn span={2}>
        <BasicInfoSection title="DNS configuration">
          <DKIMConfigurationEditor onSave={fetchConfig} />
          {loading ? (
            <Spinner />
          ) : (
            <>
              {records.map((config) => (
                <DKIMConfigurationDetails
                  key={config.domain}
                  email_config={config}
                  onDelete={handleDelete}
                />
              ))}
            </>
          )}
        </BasicInfoSection>
      </GridColumn>
      <GridColumn />
    </GridRow>
  );
};
