import React from 'react';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { formatDateForDisplay } from 'app/shared/utils/date';

export const TransactionalDetails = ({ transactional, audiences }) => {
  return (
    <BasicInfoSection title="Basic Details">
      <DetailItem title="Name">
        <p>{transactional.name}</p>
      </DetailItem>
      <DetailItem title="subject">
        <p>{transactional.subject}</p>
      </DetailItem>
      <DetailItem title="code">
        <p>{transactional.code}</p>
      </DetailItem>
      <DetailItem title="sender name">
        <p>{transactional.sender_name}</p>
      </DetailItem>
      <DetailItem title="sender email">
        <p>{transactional.sender_email}</p>
      </DetailItem>
      <DetailItem title="Creation date">
        <p>{formatDateForDisplay(transactional.created_on)}</p>
      </DetailItem>
    </BasicInfoSection>
  );
};
