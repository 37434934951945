import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'app/shared/form-alert';
import { Button } from 'app/shared/button';
import { IconInput } from 'app/shared/input';
import styles from '../guest.module.css';
import { ReactComponent as Icon } from 'assets/icons/auth/ResetPasswordIcon.svg';
import { generateUniqueId } from 'app/shared/utils/general';
import cx from 'classnames';
import { useGuestService } from 'hooks/guest';

const PasswordReset = () => {
  const { id } = useParams();
  const { resetPassword } = useGuestService();

  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({ password: '' });
  const [is_loading, setIsLoading] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    password: ''
  });
  const history = useNavigate();

  const validatePassword = (value) => {
    let errorMessage = '';
    if (!value.trim()) {
      errorMessage = 'Password is required.';
    } else if (value.length < 8) {
      errorMessage = 'Password must be at least eight characters long.';
    }
    setErrors({ password: errorMessage });
  };

  const handlePasswordChange = (value) => {
    setFormData({ password: value });
    validatePassword(value);
  };

  const submitForm = () => {
    if (disableSubmitButton) {
      validatePassword(form_data.password);
      return;
    }

    const data = {
      recovery_id: id,
      password: form_data.password
    };

    setIsLoading(true);
    resetPassword({ data })
      .then((response) => {
        const { error } = response;

        if (error) {
          setAlert({ message: error, id: generateUniqueId() });
          return;
        }

        history('/password/success');
      })
      .finally(() => setIsLoading(false));
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  const isFormDisabled = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    const isFieldsIncomplete = Object.values(values).some((value) => !value.trim());
    return hasErrors || isFieldsIncomplete;
  };

  const disableSubmitButton = isFormDisabled(errors, { password: form_data.password });

  return (
    <div className={styles.authWrapper}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      <div>
        <h1>Let's get you back in</h1>
        <p>Choose a new password</p>
        <form>
          <Alert {...alert} />
          <IconInput
            placeholder="Password"
            icon_name={show_password ? 'hide' : 'show'}
            type={show_password ? 'text' : 'password'}
            icon_position="right"
            onInput={handlePasswordChange}
            onIconClick={toggleShowPassword}
            value={form_data.password}
            wrapperClassName={cx(styles.formInput, styles.inputMargin)}
            error={errors.password}
          />
          <Button
            className={styles.formBtn}
            onClick={submitForm}
            text="Reset Password"
            loading={is_loading}
          />
          <div className={styles.formText} onClick={() => history('/login')}>
            Back to <b>Login</b>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
