import { usePermissionService } from 'hooks/iam/permission';
import { useRoleService } from 'hooks/iam/role';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const PermissionsContext = React.createContext({
  is_loading: true,
  permissions: {},
  permission_error: false
});

export const PermissionsProvider = ({ children }) => {
  const { fetchPermissions } = usePermissionService();
  const { fetchRole } = useRoleService();

  const { tenant_id, memberships } = useSelector((store) => store.user_data);

  const [is_loading, setIsLoading] = useState(false);
  const [permission_error, setPermissionError] = useState(false);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const active_membership = (memberships || []).find(
      (membership) => membership.tenant_id === tenant_id
    );
    if (!active_membership) return;

    const { role_id } = active_membership;
    setIsLoading(true);
    const perms = {};
    fetchRole(role_id).then(async ({ role, error }) => {
      if (error) setPermissionError(true);
      const { permissions } = role;
      if (Number(role_id) === 1) {
        const { permissions, error } = await fetchPermissions();
        if (error) return toast.error(error);

        permissions.forEach((permission) => {
          const { resource, actions } = permission;
          (actions || []).forEach((action) => {
            perms[`${resource}:${action}`] = true;
          });
        });
      } else {
        permissions.forEach((permission) => {
          const { resource, actions } = permission;
          (actions || []).forEach((action) => {
            const { name, is_permitted } = action;
            perms[`${resource}:${name}`] = is_permitted;
          });
        });
      }
      setPermissions(() => perms);
      setIsLoading(false);
    });
  }, [tenant_id, memberships]);

  const value = {
    permission_error,
    is_loading,
    permissions
  };
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
