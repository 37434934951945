import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { PrimaryStatCard } from 'app/shared/cards';
import { getMonthTimeRange } from 'app/shared/utils/date';
import { useContactService } from 'hooks/contact';
import { useMailActionService } from 'hooks/mailaction';
import { useMailLogService } from 'hooks/maillog';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ContactViewStatBar = () => {
  const { id } = useParams();
  const { start, end } = getMonthTimeRange();

  const { fetchContact } = useContactService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchMailActions } = useMailActionService();

  const [total_campaigns, setTotalCampaigns] = useState({ all_time: 0, month: 0 });
  const [total_transactionals, setTotalTransactionals] = useState({ all_time: 0, month: 0 });
  const [total_email_opens, setTotalEmailOpens] = useState({ all_time: 0, month: 0 });

  useEffect(() => {
    Promise.all([
      fetchMailLogs({ query_string: `class=campaign&recipient_id=${id}&count=1` }),
      fetchMailLogs({
        query_string: `class=campaign&recipient_id=${id}&time_stamp=${start}~${end}&=count=1`
      })
    ]).then((responses) => {
      const [{ size: all_time_campaign }, { size: month_campaign }] = responses;
      setTotalCampaigns({
        all_time: all_time_campaign,
        month: month_campaign
      });

      fetchContact(id).then(async ({ email }) => {
        if (!email) return;

        const [
          { size: all_time_transactional },
          { size: month_transactional },
          { size: all_time_opens },
          { size: month_opens }
        ] = await Promise.all([
          fetchMailLogs({ query_string: `class=transactional&recipient=${email}&count=1` }),
          fetchMailLogs({
            query_string: `class=transactional&recipient=${email}&time_stamp=${start}~${end}&=count=1`
          }),
          fetchMailActions({ query_string: `recipient_email=${email}&count=1` }),
          fetchMailActions({
            query_string: `recipient_email=${email}&time_stamp=${start}~${end}&=count=1`
          })
        ]);

        setTotalTransactionals({
          all_time: all_time_transactional,
          month: month_transactional
        });
        setTotalEmailOpens({
          all_time: all_time_opens,
          month: month_opens
        });
      });
    });
  }, [id]);

  return (
    <SlidingStatBar>
      <PrimaryStatCard
        main_stat={{
          label: 'Campaigns',
          value: total_campaigns.all_time,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: total_campaigns.month }}
      />
      <PrimaryStatCard
        main_stat={{
          label: 'Transactionals',
          value: total_transactionals.all_time,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: total_transactionals.month }}
      />
      <PrimaryStatCard
        main_stat={{
          label: 'Emails opened',
          value: total_email_opens.all_time,
          color: 'var(--orange-primary)'
        }}
        bottom_stat={{ label: 'This month', value: total_email_opens.month }}
      />
    </SlidingStatBar>
  );
};
