import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { addOneEventToStore, updateEventInStore } from 'store/actions/event';
import { createEventValidationConfig } from 'app/shared/utils/form/validation/event';

import styles from '../event.module.css';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { useEventsService } from 'hooks/automation/event';
import { GridColumn, GridRow } from 'app/layouts/grid';
import useScreenSize from 'hooks/size';
import { PermissionsContext } from 'contexts/permissions';

export const EventForm = ({ data }) => {
  const dispatch = useDispatch();
  const { isTablet } = useScreenSize();
  const { permissions } = useContext(PermissionsContext);

  const [loading, setLoading] = useState(false);
  const [form_data, setFormData] = useState({
    name: '',
    code: '',
    resource_class: '',
    in_use: false
  });
  const { errors, validateField, form_is_valid } = useValidator(createEventValidationConfig);
  const { createEvent, updateEvent } = useEventsService();

  useEffect(() => {
    if (!Object.keys(data).length) return;
    setFormData(() => data);
  }, [data]);

  const clearForm = () => {
    setFormData({});
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const data = {
        ...form_data,
        resource_class: 'custom'
      };

      if (!form_data.id) {
        const { event } = await createEvent({ data });
        if (!event) return;

        toast.success('Event created successfully.');
        dispatch(addOneEventToStore({ ...event, code: form_data.code }));
        clearForm();
      } else {
        const { event } = await updateEvent(form_data.id, { data });
        if (!event) return;
        toast.success('Event updated successfully.');

        const values = { ...data };
        dispatch(updateEventInStore(values));
        clearForm();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event;
    const code_in_use = form_data.id && data.in_use;

    if (name === 'name') {
      const lowercaseValue = value.toLowerCase().replace(/ /g, '_');
      setFormData((prevData) => ({
        ...prevData,
        name: value,
        code: code_in_use || form_data.id ? prevData.code : lowercaseValue
      }));
      validateField(name, value);
      validateField('code', lowercaseValue);
    } else {
      let processedValue = value;
      if (name === 'code' && code_in_use) return;
      if (name === 'code') {
        processedValue = value.toLowerCase().replace(/ /g, '_');
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: processedValue
      }));

      validateField(name, processedValue);
    }
  };

  return (
    <BasicInfoSection title={!form_data.id ? 'Create custom event' : 'Update custom event'}>
      <GridRow num_of_columns={isTablet ? 2 : 1}>
        <GridColumn>
          <DetailItem title="Name">
            <SimpleInput
              input_id="name"
              name="name"
              value={form_data.name}
              onInput={handleChange}
              error={errors.name}
              required
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Code">
            <SimpleInput
              input_id="code"
              name="code"
              value={form_data.code}
              onInput={handleChange}
              error={errors.code}
              required
            />
          </DetailItem>
        </GridColumn>
        {isTablet && <GridColumn />}
        <GridColumn>
          <div className={cx('grid mt-2', form_data.id ? styles.action : '')}>
            {form_data.id && <Button type="secondary" onClick={clearForm} text="Clear" />}
            <Button
              onClick={submitForm}
              text="Save"
              className="float-right"
              loading={loading}
              disabled={
                !form_is_valid || loading || !form_data.id
                  ? !permissions['automation_event:create']
                  : !permissions['automation_event:update']
              }
            />
          </div>
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};
