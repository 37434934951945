import { useSelector } from 'react-redux';
import * as LinksService from 'services/link';

export const useLinksService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchLinks = async (options = {}) => {
    const { error, payload } = await LinksService.read({ ...options, token });
    if (error) {
      return { links: [], size: 0, error: 'Unable to fetch mail actions at this time.' };
    }

    const {
      data: links,
      meta: { size }
    } = payload;
    return { links, size, error: null };
  };

  return { fetchLinks };
};
