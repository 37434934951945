/** */

import { ADD_WALLET, REMOVE_WALLET, UPDATE_WALLET } from '../actions/wallet';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REMOVE_WALLET:
      return {};

    case ADD_WALLET:
      return payload;

    case UPDATE_WALLET:
      return { ...state, ...payload };
    default:
      return state;
  }
};
