import { isEmailValid } from '../../input';

export const organizationValidationConfig = {
  name: {
    required: true,
    pattern: /^[a-zA-Z0-9\s\-.,']+$/u,
    customErrorMessage: {
      pattern: 'Invalid name format. Only letters are allowed.'
    }
  },
  email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Email Address';
      } else result.is_valid = true;

      return result;
    }
  },
  phone_number: {
    required: true,
    pattern: /\d{11,13}/,
    customErrorMessage: 'Invalid phone number'
  }
};
