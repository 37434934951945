/** */

import {
  ADD_MANY_FLAG_TO_STORE,
  ADD_ONE_FLAG_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_FLAGS_TO_STORE,
  REMOVE_MANY_FLAG_FROM_STORE,
  REMOVE_ONE_FLAG_FROM_STORE,
  UPDATE_ONE_FLAG_IN_STORE
} from '../actions/flag';

export default (state = {}, action) => {
  const { type, payload } = action;
  let previous_state;
  switch (type) {
    case ADD_MANY_FLAG_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (events, event) => ({
            ...events,
            [event.id]: event
          }),
          {}
        )
      };

    case REMOVE_MANY_FLAG_FROM_STORE:
      previous_state = { ...state };
      payload.forEach((id) => delete previous_state[id]);
      return {
        ...previous_state
      };

    case REMOVE_ONE_FLAG_FROM_STORE:
      previous_state = { ...state };
      delete previous_state[payload];
      return {
        ...previous_state
      };

    case ADD_ONE_FLAG_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };

    case UPDATE_ONE_FLAG_IN_STORE:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      };

    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (flags, flag) => ({
            ...flags,
            [flag.id]: flag
          }),
          {}
        )
      };

    case LOAD_FLAGS_TO_STORE:
      return {
        ...payload.reduce(
          (flags, flag) => ({
            ...flags,
            [flag.id]: flag
          }),
          {}
        )
      };

    default:
      return state;
  }
};
