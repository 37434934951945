import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { TransactionalDetails } from './details';
import { useTransactionalService } from 'hooks/transactional';
import styles from './preview.module.css';
import useScreenSize from 'hooks/size';

export const TransactionalPreview = () => {
  const { id } = useParams();
  const { fetchTransactionalById } = useTransactionalService();
  const { isDesktop } = useScreenSize();

  const [transactional, setTransactional] = useState({});
  const [campaign_body, setTransactionalBody] = useState('');

  useEffect(() => {
    fetchTransactionalById(id).then(({ transactional }) => {
      setTransactional(transactional);
      setTransactionalBody(transactional.body);
    });
  }, []);

  return (
    <>
      {!isDesktop && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <div className={cx(styles.iframeWrapper, 'shadow-sm transactional-view my-md-1')}>
                <iframe
                  className={styles.iframe}
                  title="Transactional body"
                  srcDoc={campaign_body}
                  style={{
                    width: '100%',
                    minHeight: '75vh',
                    padding: '15px',
                    background: 'var(--neutral-light)',
                    border: '1px solid var(--neutral-dark-5)',
                    borderRadius: '8px'
                  }}
                ></iframe>
              </div>
            </GridColumn>
          </GridRow>

          <GridRow>
            <GridColumn span={4}>
              <TransactionalDetails transactional={transactional} />
            </GridColumn>
          </GridRow>
        </>
      )}
      {isDesktop && (
        <>
          <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
            <GridColumn span={3}>
              <div className={cx(styles.iframeWrapper, 'shadow-sm transactional-view my-md-1')}>
                <iframe
                  className={styles.iframe}
                  title="Transactional body"
                  srcDoc={campaign_body}
                  style={{
                    width: '100%',
                    minHeight: '75vh',
                    padding: '15px',
                    background: 'var(--neutral-light)',
                    border: '1px solid var(--neutral-dark-5)',
                    borderRadius: '8px'
                  }}
                ></iframe>
              </div>
            </GridColumn>
            <GridColumn span={1}>
              <TransactionalDetails transactional={transactional} />
            </GridColumn>
          </GridRow>
        </>
      )}
    </>
  );
};
