import React from 'react';

import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import styles from './create.module.css';
import { Icon } from 'assets/icons';

export const CreateButton = ({ actions, handleUserAction }) => {
  return (
    <ContextMenu
      actions={actions}
      callback={handleUserAction}
      text={
        <div className={styles.createButton}>
          <Icon name="add_circle" />
        </div>
      }
    />
  );
};
