import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as PaymentService from 'services/payment';

export const usePaymentService = () => {
  const { token } = useSelector((store) => store.user_data);

  const verifyPayment = async (options = {}) => {
    const { error, payload } = await PaymentService.verify({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const createPayment = async (options = {}) => {
    const { error, payload } = await PaymentService.create({ ...options, token });
    if (error) {
      toast.error('Could not initialize payment.');
      return null;
    }

    return payload;
  };

  return { verifyPayment, createPayment };
};
