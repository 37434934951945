export const neuronValidator = {
  title: {
    required: true
  },
  event: {
    required: true
  },
  source: {
    required: true
  }
};
