export const createEventValidationConfig = {
  name: {
    required: true,
    pattern: /^[a-zA-Z\s]+$/,
    customErrorMessage: {
      pattern: 'Invalid name format. Only letters and spaces are allowed.'
    }
  },
  code: {
    required: true,
    pattern: /^[a-zA-Z_]*$/,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (value.startsWith('_')) {
        result.message = 'Code must not start with an underscore.';
      } else result.is_valid = true;

      return result;
    },
    customErrorMessage: {
      pattern: 'Only combination of alphabet, and underscore is allowed'
    }
  }
};
