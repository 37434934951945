import React from 'react';
import { Link } from 'react-router-dom';
import { truncateText } from '../utils/general';

import './breadcrumbs.css';
import useScreenSize from 'hooks/size';

const BreadcrumbSeparator = ({ children }) => (
  <span className="crumb-separator" style={{ userSelect: 'none' }}>
    {children}
  </span>
);

const Breadcrumbs = ({ crumbs }) => {
  const { isMobile } = useScreenSize();
  const lastCrumbIndex = crumbs.length - 1;
  const breadcrumbs = Array.isArray(crumbs) ? crumbs : [];

  const crumbs_with_separators = breadcrumbs.reduce((acc, crumb, index) => {
    const notLast = index < lastCrumbIndex;
    if (notLast) {
      acc.push(crumb, {
        title: <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>,
        path: ''
      });
    } else {
      acc.push({ crumb, title: truncateText(crumb.title, 15) });
    }
    return acc;
  }, []);

  return (
    <>
      {!isMobile ? (
        crumbs_with_separators.map(({ title, path }, index) => {
          const isTwoBreadcrumbs = breadcrumbs.length === 2;
          const isFirstBreadcrumb = index === 0 && isTwoBreadcrumbs;
          const textColor = isFirstBreadcrumb ? '#c4c4c4' : '#003B71';

          if (index + 1 === breadcrumbs.length) {
            return (
              <span key={index} style={{ color: textColor }}>
                {title}
              </span>
            );
          } else {
            return (
              <Link key={index} to={path} className="crumb-link" style={{ color: textColor }}>
                {title}
              </Link>
            );
          }
        })
      ) : (
        <span style={{ color: '#000' }}>{breadcrumbs[0].title}</span>
      )}
    </>
  );
};

export default Breadcrumbs;
