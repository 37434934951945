import { GridColumn, GridRow } from 'app/layouts/grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { EventForm } from './form';
import { AutomationEvents } from './list';
import { Spacer } from 'app/layouts/generic';
import useScreenSize from 'hooks/size';

export const EventModule = () => {
  const { isDesktop } = useScreenSize();
  const dispatch = useDispatch();

  const [event_to_update, setEventToUpdate] = useState({});

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Automations', path: '/automations/events' },
        { title: 'Events', path: '.' }
      ])
    );
  }, []);

  return (
    <GridRow
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn span={3}>
        {!isDesktop && <Spacer multiple={4} />}
        <AutomationEvents handleEdit={setEventToUpdate} />
      </GridColumn>
      <GridColumn>
        <EventForm data={event_to_update} />
      </GridColumn>
    </GridRow>
  );
};
