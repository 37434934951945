import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as PermissionService from 'services/iam/permission';

export const usePermissionService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createPermission = async (options = {}) => {
    const { error, payload: permission } = await PermissionService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create permission at this time');
      return { permission: {} };
    }

    toast.success('Permission created successfully.');
    return { permission };
  };

  const fetchPermission = async (id, options = {}) => {
    const { error, payload } = await PermissionService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch permission at this time.');
      return { permission: {} };
    }

    return { permission: payload };
  };

  const fetchPermissions = async (options = {}) => {
    const { error, payload } = await PermissionService.read({ ...options, token });
    if (error) {
      return { permissions: [], size: 0, error: 'Unable to fetch permissions at this time.' };
    }

    const {
      data: permissions,
      meta: { size }
    } = payload;
    return { permissions, size, error: null };
  };

  const searchPermissions = async (keys, keyword, options = {}) => {
    const { error, payload } = await PermissionService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch permissions at this time.');
      return { permissions: [], size: 0 };
    }

    const {
      data: permissions,
      meta: { size }
    } = payload;
    return { permissions, size };
  };

  const updatePermission = async (id, options = {}) => {
    const { error, payload: permission } = await PermissionService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update permission at this time');
      return { permission: {} };
    }

    toast.success('Permission updated successfully.');
    return { permission };
  };

  return {
    createPermission,
    fetchPermission,
    fetchPermissions,
    searchPermissions,
    updatePermission
  };
};
