import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as AudienceService from 'services/audience';

import { addOneAudienceToStore } from 'store/actions/audience';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';

const CloneMailingItem = ({ closeModal, line_item }) => {
  const dispatch = useDispatch();
  const { token, id } = useSelector((state) => state.user_data);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (line_item) {
      setName(line_item.name + ' copy');
    }
  }, [line_item]);

  const clearForm = () => {
    setName('');
  };

  const submit = async () => {
    const data = {
      name,
      tenant_id: id
    };

    if (!name) {
      toast.error('please fill all fields.');
      return;
    }

    setLoading(true);
    const { error, payload } = await AudienceService.clone(line_item.id, {
      data,
      token
    });
    if (error) {
      toast.error(error);
      return;
    }

    setLoading(false);
    toast.success('Cloned Successfully.');
    dispatch(addOneAudienceToStore(payload));
    clearForm();
    closeModal();
  };

  return (
    <div>
      <SimpleInput placeholder="Audience name" value={name} onInput={(value) => setName(value)} />

      <Button text="Clone" onClick={submit} className="float-right w-25" loading={loading} />
    </div>
  );
};

export default CloneMailingItem;
