export const industries = [
  { value: 'accounting', label: 'Accounting' },
  { value: 'airlines_aviation', label: 'Airlines/Aviation' },
  { value: 'alternative_dispute_resolution', label: 'Alternative Dispute Resolution' },
  { value: 'alternative_medicine', label: 'Alternative Medicine' },
  { value: 'animation', label: 'Animation' },
  { value: 'apparel_and_fashion', label: 'Apparel And Fashion' },
  { value: 'architecture_and_planning', label: 'Architecture And Planning' },
  { value: 'arts_and_crafts', label: 'Arts And Crafts' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'aviation_and_aerospace', label: 'Aviation And Aerospace' },
  { value: 'banking', label: 'Banking' },
  { value: 'biotechnology', label: 'Biotechnology' },
  { value: 'broadcast_media', label: 'Broadcast Media' },
  { value: 'building_materials', label: 'Building Materials' },
  { value: 'business_supplies_and_equipment', label: 'Business Supplies And Equipment' },
  { value: 'capital_markets', label: 'Capital Markets' },
  { value: 'chemicals', label: 'Chemicals' },
  { value: 'civic_and_social_organization', label: 'Civic And Social Organization' },
  { value: 'civil_engineering', label: 'Civil Engineering' },
  { value: 'commercial_real_estate', label: 'Commercial Real Estate' },
  { value: 'computer_and_network_security', label: 'Computer And Network Security' },
  { value: 'computer_games', label: 'Computer Games' },
  { value: 'computer_hardware', label: 'Computer Hardware' },
  { value: 'computer_networking', label: 'Computer Networking' },
  { value: 'computer_software', label: 'Computer Software' },
  { value: 'construction', label: 'Construction' },
  { value: 'consumer_electronics', label: 'Consumer Electronics' },
  { value: 'consumer_goods', label: 'Consumer Goods' },
  { value: 'consumer_services', label: 'Consumer Services' },
  { value: 'cosmetics', label: 'Cosmetics' },
  { value: 'dairy', label: 'Dairy' },
  { value: 'defense_and_space', label: 'Defense And Space' },
  { value: 'design', label: 'Design' },
  { value: 'education_management', label: 'Education Management' },
  { value: 'elearning', label: 'E-Learning' },
  { value: 'electrical_manufacturing', label: 'Electrical And Electronic Manufacturing' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'environmental_services', label: 'Environmental Services' },
  { value: 'events_services', label: 'Events Services' },
  { value: 'executive_office', label: 'Executive Office' },
  { value: 'facilities_services', label: 'Facilities Services' },
  { value: 'farming', label: 'Farming' },
  { value: 'financial_services', label: 'Financial Services' },
  { value: 'fine_art', label: 'Fine Art' },
  { value: 'fishery', label: 'Fishery' },
  { value: 'food_and_beverages', label: 'Food And Beverages' },
  { value: 'food_production', label: 'Food Production' },
  { value: 'fundraising', label: 'Fundraising' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'gambling_and_casinos', label: 'Gambling And Casinos' },
  { value: 'glass_ceramics_concrete', label: 'Glass, Ceramics And Concrete' },
  { value: 'government_administration', label: 'Government Administration' },
  { value: 'government_relations', label: 'Government Relations' },
  { value: 'graphic_design', label: 'Graphic Design' },
  { value: 'health_wellness_fitness', label: 'Health, Wellness And Fitness' },
  { value: 'higher_education', label: 'Higher Education' },
  { value: 'hospital_and_health_care', label: 'Hospital And Health Care' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'human_resources', label: 'Human Resources' },
  { value: 'import_and_export', label: 'Import And Export' },
  { value: 'individual_and_family_services', label: 'Individual And Family Services' },
  { value: 'industrial_automation', label: 'Industrial Automation' },
  { value: 'information_services', label: 'Information Services' },
  { value: 'it_and_services', label: 'Information Technology And Services' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'international_affairs', label: 'International Affairs' },
  { value: 'international_trade_and_dev', label: 'International Trade And Development' },
  { value: 'internet', label: 'Internet' },
  { value: 'investment_banking_venture', label: 'Investment Banking/Venture' },
  { value: 'investment_management', label: 'Investment Management' },
  { value: 'judiciary', label: 'Judiciary' },
  { value: 'law_enforcement', label: 'Law Enforcement' },
  { value: 'law_practice', label: 'Law Practice' },
  { value: 'legal_services', label: 'Legal Services' },
  { value: 'legislative_office', label: 'Legislative Office' },
  { value: 'leisure_and_travel', label: 'Leisure And Travel' },
  { value: 'libraries', label: 'Libraries' },
  { value: 'logistics_and_supply_chain', label: 'Logistics And Supply Chain' },
  { value: 'luxury_goods_and_jewelry', label: 'Luxury Goods And Jewelry' },
  { value: 'machinery', label: 'Machinery' },
  { value: 'management_consulting', label: 'Management Consulting' },
  { value: 'maritime', label: 'Maritime' },
  { value: 'marketing_and_advertising', label: 'Marketing And Advertising' },
  { value: 'market_research', label: 'Market Research' },
  { value: 'mechanical_engineering', label: 'Mechanical Or Industrial Engineering' },
  { value: 'media_production', label: 'Media Production' },
  { value: 'medical_device', label: 'Medical Device' },
  { value: 'medical_practice', label: 'Medical Practice' },
  { value: 'mental_health_care', label: 'Mental Health Care' },
  { value: 'military', label: 'Military' },
  { value: 'mining_and_metals', label: 'Mining And Metals' },
  { value: 'motion_pictures_and_film', label: 'Motion Pictures And Film' },
  { value: 'museums_and_institutions', label: 'Museums And Institutions' },
  { value: 'music', label: 'Music' },
  { value: 'nanotechnology', label: 'Nanotechnology' },
  { value: 'newspapers', label: 'Newspapers' },
  { value: 'nonprofit_organization_mgmt', label: 'Non-Profit Organization Management' },
  { value: 'oil_and_energy', label: 'Oil And Energy' },
  { value: 'online_publishing', label: 'Online Publishing' },
  { value: 'outsourcing_offshoring', label: 'Outsourcing/Offshoring' },
  { value: 'package_freight_delivery', label: 'Package/Freight Delivery' },
  { value: 'packaging_and_containers', label: 'Packaging And Containers' },
  { value: 'paper_and_forest_products', label: 'Paper And Forest Products' },
  { value: 'performing_arts', label: 'Performing Arts' },
  { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'philanthropy', label: 'Philanthropy' },
  { value: 'photography', label: 'Photography' },
  { value: 'plastics', label: 'Plastics' },
  { value: 'political_organization', label: 'Political Organization' },
  { value: 'primary_secondary_education', label: 'Primary/Secondary Education' },
  { value: 'printing', label: 'Printing' },
  { value: 'professional_training', label: 'Professional Training' },
  { value: 'program_development', label: 'Program Development' },
  { value: 'public_policy', label: 'Public Policy' },
  { value: 'public_relations', label: 'Public Relations' },
  { value: 'public_safety', label: 'Public Safety' },
  { value: 'publishing', label: 'Publishing' },
  { value: 'railroad_manufacture', label: 'Railroad Manufacture' },
  { value: 'ranching', label: 'Ranching' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'recreational_services', label: 'Recreational Facilities And Services' },
  { value: 'religious_institutions', label: 'Religious Institutions' },
  { value: 'renewables_and_environment', label: 'Renewables And Environment' },
  { value: 'research', label: 'Research' },
  { value: 'restaurants', label: 'Restaurants' },
  { value: 'retail', label: 'Retail' },
  { value: 'security_and_investigations', label: 'Security And Investigations' },
  { value: 'security_systems_services', label: 'Security Systems Services' },
  { value: 'semiconductors', label: 'Semiconductors' },
  { value: 'shipbuilding', label: 'Shipbuilding' },
  { value: 'sporting_goods', label: 'Sporting Goods' },
  { value: 'sports', label: 'Sports' },
  { value: 'staffing_and_recruiting', label: 'Staffing And Recruiting' },
  { value: 'supermarkets', label: 'Supermarkets' },
  { value: 'telecommunications', label: 'Telecommunications' },
  { value: 'textiles', label: 'Textiles' },
  { value: 'think_tanks', label: 'Think Tanks' },
  { value: 'tobacco', label: 'Tobacco' },
  { value: 'translation_and_localization', label: 'Translation And Localization' },
  { value: 'transportation_trucking_railroad', label: 'Transportation/Trucking/Railroad' },
  { value: 'utilities', label: 'Utilities' },
  { value: 'venture_capital', label: 'Venture Capital' },
  { value: 'veterinary', label: 'Veterinary' },
  { value: 'warehousing', label: 'Warehousing' },
  { value: 'wholesale', label: 'Wholesale' },
  { value: 'wine_and_spirits', label: 'Wine And Spirits' },
  { value: 'wireless', label: 'Wireless' },
  { value: 'writing_and_editing', label: 'Writing And Editing' }
];
