import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as RoleService from 'services/iam/role';

export const useRoleService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createRole = async (options = {}) => {
    const { error, payload: role } = await RoleService.create({
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot create role at this time');
      return { role: {} };
    }

    toast.success('role created successfully.');
    return { role };
  };

  const fetchRole = async (id, options = {}) => {
    const { error, payload } = await RoleService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch role at this time.');
      return { role: {}, error };
    }

    return { role: payload, error: null };
  };

  const fetchRoles = async (options = {}) => {
    const { error, payload } = await RoleService.read({ ...options, token });
    if (error) {
      return { roles: [], size: 0, error: 'Unable to fetch roles at this time.' };
    }

    const {
      data: roles,
      meta: { size }
    } = payload;
    return { roles, size, error: null };
  };

  const searchRoles = async (keys, keyword, options = {}) => {
    const { error, payload } = await RoleService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch roles at this time.');
      return { roles: [], size: 0 };
    }

    const {
      data: roles,
      meta: { size }
    } = payload;
    return { roles, size };
  };

  const updateRole = async (id, options = {}) => {
    const { error, payload: role } = await RoleService.updateById(id, {
      ...options,
      token
    });

    if (error) {
      toast.error('Cannot update role at this time');
      return { role: {} };
    }

    toast.success('Role updated successfully.');
    return { role };
  };

  return { createRole, fetchRole, fetchRoles, searchRoles, updateRole };
};
