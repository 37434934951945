import { Spacer } from 'app/layouts/generic';
import { MultiSelect } from 'app/shared/select';
import React, { useEffect, useState } from 'react';
import styles from './table.module.css';
import SimpleTableSkeleton from 'app/shared/skeleton/simple-table';

const filters = [
  {
    label: '2024',
    value: '2024'
  },
  {
    label: '2023',
    value: '2023'
  },
  {
    label: '2022',
    value: '2022'
  }
];

export const ReportTable = ({
  title = '',
  data = [],
  columns = [],
  is_loading = true,
  onChange = () => {}
}) => {
  const [selected_filter, setSelectedFilter] = useState();

  const handleFilterChange = (selection = {}) => {
    onChange(selection);
    setSelectedFilter(() => selection);
  };

  useEffect(() => {
    handleFilterChange({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <header>
        <div className={styles.title}>{title}</div>
        <div className={styles.filters}>
          <MultiSelect options={filters} value={selected_filter} onChange={handleFilterChange} />
        </div>
      </header>
      <Spacer multiple={4} />
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {is_loading ? (
              <SimpleTableSkeleton />
            ) : !is_loading && !data?.length ? (
              <tr>
                <td colSpan={7}>
                  <section className={styles.noData}>No data available</section>
                </td>
              </tr>
            ) : (
              data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <td
                      key={`${column.key}-${rowIndex}-${colIndex}`}
                      style={column.style ? column.style(row[column.key]) : {}}
                    >
                      {`${row[column.key]?.toLocaleString() || 0}${column?.suffix || ''}`}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
