export const SMSMessageValidator = {
  title: {
    required: true
  },
  audiences: {
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: '' };
      if (options.type === 'campaign' && (!Array.isArray(value) || !value.length)) {
        result.message = 'Invalid value';
        return result;
      }

      result.is_valid = true;
      return result;
    }
  },
  message: {
    required: true
  },
  schedule: {
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: '' };
      if (options.type === 'campaign' && isNaN(Date.parse(value))) {
        result.message = 'Invalid date';
        return result;
      }

      result.is_valid = true;
      return result;
    }
  },
  sender_id: {
    required: true,
    minLength: 3
  },
  type: {
    required: true
  }
};
