import React from 'react';
import { WebPushConfiguration } from './push/web';
import { SMSConfiguration } from './sms';

export const IntegrationConfiguration = ({ module_data = {} }) => {
  switch (module_data.code) {
    case 'sms_termii':
      return <SMSConfiguration module_data={module_data} />;
    case 'web_push':
      return <WebPushConfiguration module_data={module_data} />;
    default:
      return <>IntegrationConfiguration</>;
  }
};
