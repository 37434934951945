import { isCampaignSendDateValid } from '../../date';
import { isHTMLBodyEmpty } from '../../general';
import { isEmailValid } from '../../input';

export const campaignValidationConfig = {
  name: {
    required: true,
    // eslint-disable-next-line no-useless-escape
    pattern: /[a-zA-Z\s-_\[\]]/,
    customErrorMessage: {
      pattern: 'Invalid name format. Only letters and spaces are allowed.'
    }
  },
  body: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!value || isHTMLBodyEmpty(value)) {
        result.message = 'Campaign body cannot be empty.';
      } else result.is_valid = true;

      return result;
    }
  },
  subject: {
    required: true
  },
  sender_name: {
    required: true
  },
  sender_email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Sender Email';
      } else result.is_valid = true;

      return result;
    }
  },
  schedule: {
    required: true,
    customValidator: (value, options = {}) => {
      const result = { is_valid: false, message: '' };
      if (options && options.is_test_campaign) result.is_valid = true;
      else if (!value || !value.date || !isCampaignSendDateValid(value.date)) {
        result.message = 'Invalid schedule date';
      } else result.is_valid = true;

      return result;
    }
  },
  mailing_lists: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!value || value.length === 0) {
        result.message = 'Select audience.';
      } else result.is_valid = true;

      return result;
    }
  }
};
