import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { countries } from 'app/data/countries';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { DetailItem } from 'app/shared/info-section/item';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { setPageTitle } from 'store/actions/header';
import { Icon } from 'assets/icons';
import { useTenantService } from 'hooks/iam/tenant';
import { Spacer } from 'app/layouts/generic';
import useValidator from 'hooks/validator';
import { organizationProfileValidationConfig } from 'app/shared/utils/form/validation/organization-profile';
import { PermissionsContext } from 'contexts/permissions';

const InfoDisplay = ({ data = {}, onEditClick = () => {} }) => {
  const { street, state, country } = data.address || {};
  const { permissions } = useContext(PermissionsContext);

  const [email_address, setEmailAddress] = useState('');

  useEffect(() => {
    if (!data.emails || !data.emails.length) return;
    setEmailAddress(data.emails.join(', '));
  }, [data.emails]);

  return (
    <BasicInfoSection
      title="Organisation Details"
      icon={permissions['tenant_config:update'] && <Icon name="edit" onClick={onEditClick} />}
    >
      <DetailItem title="Name">{data.name || 'N/A'}</DetailItem>
      <DetailItem title="Email Address">{email_address}</DetailItem>
      <DetailItem title="Street address">{street || 'N/A'}</DetailItem>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="State">{state || 'N/A'}</DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Country">{country || 'N/A'}</DetailItem>
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};

const UpdationForm = ({ data = {}, onSave = () => {} }) => {
  const { updateTenant } = useTenantService();
  const { permissions } = useContext(PermissionsContext);

  const [user_data, setUserData] = useState({
    name: '',
    email: '',
    street: '',
    state: '',
    country: {}
  });
  const [saving, setSaving] = useState(false);
  const { errors, form_is_valid, validateField } = useValidator(
    organizationProfileValidationConfig
  );

  const countries_by_id = countries.reduce((sac, country) => {
    return {
      ...sac,
      [country.code]: country.name
    };
  }, {});

  useEffect(() => {
    const { name, emails, address } = data;

    handleChange('name', name);
    handleChange('email', emails && emails.length > 0 ? emails[0] : '');

    if (address) {
      const { street, state, country } = address;
      handleChange('state', state);
      handleChange('street', street);
      handleChange('country', { value: country, label: countries_by_id[country] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submitForm = async () => {
    const { name, email, street, state, country } = user_data;

    const form_data = {
      name,
      emails: email ? [email] : [],
      address: { street, state, country: country.value }
    };

    setSaving(true);
    const response = await updateTenant(data.id, {
      data: form_data
    });
    setSaving(false);
    if (!response) {
      return;
    }

    toast.success('Organization updated successfully.');
    onSave({ ...data, ...form_data });
  };

  const handleChange = (field, value) => {
    validateField(field, value);
    setUserData((previous_values) => ({
      ...previous_values,
      [field]: value
    }));
  };

  return (
    <BasicInfoSection title="Organisation Details">
      <DetailItem title="Name">
        <SimpleInput
          input_id="name"
          value={user_data.name}
          onInput={(value) => handleChange('name', value)}
          error={errors.name}
        />
      </DetailItem>

      <DetailItem title="Email Address">
        <SimpleInput
          input_id="email-address"
          value={user_data.email}
          onInput={(value) => handleChange('email', value)}
          error={errors.email}
        />
      </DetailItem>
      <DetailItem title="Street address">
        <SimpleInput
          input_id="street-address"
          value={user_data.street}
          onInput={(value) => handleChange('street', value)}
        />
      </DetailItem>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="State">
            <SimpleInput
              input_id="state"
              value={user_data.state}
              onInput={(value) => handleChange('state', value)}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Country">
            <MultiSelect
              options={countries.map((country) => ({
                label: country.name,
                value: country.code
              }))}
              onChange={(value) => handleChange('country', value)}
              value={user_data.country}
              required
              isMulti={false}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <Spacer multiple={2} />
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button
            onClick={submitForm}
            text="Save"
            loading={saving}
            disabled={!form_is_valid || saving || !permissions['tenant_config:update']}
          />
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};

export const TenantProfileSetting = () => {
  const dispatch = useDispatch();
  const { tenant_id } = useSelector((state) => state.user_data);
  const { fetchTenant } = useTenantService();
  const [tenant_data, setTenantData] = useState({});
  const [mode, setMode] = useState('view');
  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Profile', path: '/settings/profile' }
      ])
    );
  }, []);

  useEffect(() => {
    fetchTenant(tenant_id).then(({ tenant }) => setTenantData(tenant));
  }, [mode]);

  return mode === 'view' ? (
    <InfoDisplay data={tenant_data} onEditClick={() => setMode('edit')} />
  ) : (
    <UpdationForm data={tenant_data} onSave={() => setMode(() => 'view')} />
  );
};
