/** */
import { urls } from './_url';
import { apiPut, apiGet } from './_http';

/** */
const base_url = `${urls.sales}/subscriptions`;

export const manage = async (id, action, options = {}) => {
  return await apiPut(`${base_url}/manage/${id}/${action}`, { ...options });
};

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const update = async (id, options = {}) => {
  return await apiPut(`${base_url}/${id}`, { ...options });
};
