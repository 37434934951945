/** */
export const READ_MANY_STEPS = 'read multiple STEP.';
export const ADD_MANY_STEPS_TO_STORE = 'add multiple STEP to store.';
export const REMOVE_ONE_STEP_FROM_STORE = 'remove one STEP from store';
export const REMOVE_MANY_STEPS_FROM_STORE = 'remove many STEP from store';
export const ADD_ONE_STEP_TO_STORE = 'add one STEP to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add STEP search results to store.';
export const UPDATE_ONE_STEP_IN_STORE = 'update an STEP in store.';

/** */
export const readManyStep = (payload) => ({
  type: READ_MANY_STEPS,
  payload
});

export const addManyStepsToStore = (payload) => ({
  type: ADD_MANY_STEPS_TO_STORE,
  payload
});

export const removeOneStepFromStore = (payload) => ({
  type: REMOVE_ONE_STEP_FROM_STORE,
  payload
});

export const removeManyStepsFromStore = (payload) => ({
  type: REMOVE_MANY_STEPS_FROM_STORE,
  payload
});

export const addOneStepToStore = (payload) => ({
  type: ADD_ONE_STEP_TO_STORE,
  payload
});

export const addStepSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const updateStepInStore = (payload) => ({
  type: UPDATE_ONE_STEP_IN_STORE,
  payload
});
