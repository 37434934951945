import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextEditor } from '../shared/text-editor';
import * as FileService from 'services/file';

export const MainForm = ({ value, onEditorChange }) => {
  const { token } = useSelector((state) => state.user_data);
  const auto_complete_options = [
    {
      value: '@firstname',
      text: '@firstname'
    },
    {
      value: '@email',
      text: '@email'
    },
    {
      value: '@lastname',
      text: '@lastname'
    }
  ];

  useEffect(() => {
    onEditorChange('body', value);
  }, []);

  const uploadImage = (blobInfo, success, failure, progress) => {
    const data = new FormData();
    data.append('image', blobInfo.blob());

    //
    FileService.uploadTinyMCEImage(success, failure, progress, { data, token });
  };

  return (
    <TextEditor
      default_value={value}
      onTextChange={(text) => onEditorChange('body', text)}
      onImageUpload={uploadImage}
      auto_complete_options={auto_complete_options}
      placeholder="Start creating..."
    />
  );
};
