import { isEmailValid } from '../../input';

export const profileValidationConfig = {
  email: {
    required: true,
    customValidator: (value) => {
      const result = { is_valid: false, message: '' };
      if (!isEmailValid(value)) {
        result.message = 'Invalid Email Address';
      } else result.is_valid = true;

      return result;
    }
  },
  firstname: {
    pattern: /^[a-zA-Z]*$/,
    customErrorMessage: {
      pattern: 'Only letters are allowed'
    }
  },
  lastname: {
    pattern: /^[a-zA-Z]*$/,
    customErrorMessage: {
      pattern: 'Only letters are allowed'
    }
  }
};
