import styles from './form.module.css';
import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DateTimePicker } from 'app/shared/datepickers';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { TextArea } from 'app/shared/input/textarea';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import { useMessagingPreferenceService } from 'hooks/messaging/preference';
import { useWebPushService } from 'hooks/messaging/push/web';
import { useAudienceService } from 'hooks/users/audience';
import useValidator from 'hooks/validator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { PushMessageValidator } from './validator';
import { useFilesService } from 'hooks/files';
import profile_icon from 'assets/images/profile_icon.png';
import { addOnePushMessageToStore } from 'store/actions/push';

const message_types = [
  { value: 'campaign', label: 'Campaign' },
  { value: 'transactional', label: 'Transactional' }
];

export const WebPushMessageForm = ({
  message_data = {},
  closeModal = () => {},
  onSave = () => {}
}) => {
  const dispatch = useDispatch();
  const { errors, form_is_valid, validateField } = useValidator(PushMessageValidator);
  const { fetchAudiences } = useAudienceService();
  const { uploadImage } = useFilesService();
  const { createPushMessage, updatePushMessage } = useWebPushService();
  const { fetchPreferences, setPreference } = useMessagingPreferenceService();

  const [audience_optionss, setAudienceOptions] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [pref, setPref] = useState({});
  const [schedule, setSchedule] = useState('');
  const [sender_id, setSenderId] = useState({});
  const [sender_id_options, setSenderIdOptions] = useState([]);
  const [sender_image, setSenderImage] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState({});

  useEffect(() => {
    if (!message_data || !Object.keys(message_data).length) return;
    setTitle(message_data.title);
    setMessage(message_data.message);
    setSenderId({ value: message_data.sender_id, label: message_data.sender_id });
    setSenderImage(message_data.sender_image);
    setType(message_types.find((option) => option.value === message_data.type) || {});
    if (message_data.type === 'campaign') {
      setSchedule(message_data.schedule.date);
    }
  }, [message_data]);

  useEffect(() => {
    fetchPreferences().then(({ preferences: [preference] }) => {
      const { push } = preference;
      const { sender_ids } = push || {};
      const parsed_sender_ids = (sender_ids || []).map((s) => ({ label: s, value: s }));
      setPref(preference);
      setSenderIdOptions(() => parsed_sender_ids);
    });

    fetchAudiences().then(({ audiences }) => {
      const audience_by_id = audiences.reduce((s, a) => ({ ...s, [a.id]: a }), {});
      setAudienceOptions(() => audiences.map((aud) => ({ value: aud.id, label: aud.name })));
      if (message_data && message_data.type === 'campaign') {
        setAudiences(() =>
          (message_data.audiences || []).map((aud) => ({
            value: aud,
            label: audience_by_id[aud]?.name
          }))
        );
      }
    });
  }, [message.id]);

  useEffect(() => {
    validateField('message', message);
    validateField('sender_id', sender_id.value);
    validateField('title', title);
    validateField('type', type.value);

    validateField('schedule', schedule, { type: type.value });
    validateField('audiences', audiences, { type: type.value });
  }, [title, message, schedule, sender_id, audiences, type]);

  const clearForm = () => {
    setTitle('');
    setMessage('');
    setSenderId({});
    setSenderImage('');
    setSchedule('');
    setType({});
    setAudiences([]);
  };

  const handleDeleteImage = () => {
    setSenderImage('');
  };

  const handleFileSelection = async (e) => {
    if (!e.target.files) return;
    const selected_file = e.target.files[0];

    if (e.target.files && selected_file.size <= 100 * 1024) {
      const formData = new FormData();
      formData.append('image', selected_file);

      const { location } = await uploadImage({
        data: formData,
        headers: {
          'Content-Type': 'application/form-data'
        }
      });
      setSenderImage(location);
    } else {
      toast.error('File size exceeds the limit of 100KB');
    }
  };

  const handleMessageChange = (val) => {
    if (val.length > 160) return;
    setMessage(val);
  };

  const submit = async () => {
    const data = {
      message,
      sender_id: sender_id.value,
      sender_image,
      title,
      audiences: audiences.map((audience) => audience.value),
      schedule: { date: schedule ? new Date(schedule) : new Date() },
      status: 'queued',
      type: type.value
    };

    const sender_ids = sender_id_options.map((s) => s.value);
    const pref_data = {
      ...pref,
      sms: { ...(pref.sms || {}), sender_ids: [...sender_ids, sender_id.value] },
      push: { ...(pref.push || {}), sender_ids: [...sender_ids, sender_id.value] }
    };

    setLoading(true);
    const { message: result } = message_data.id
      ? await updatePushMessage(message_data.id, { data })
      : await createPushMessage({ data });

    if (!sender_ids.includes(sender_id.value)) {
      await setPreference({ data: pref_data });
    }
    setLoading(false);

    if (!result) return;
    dispatch(addOnePushMessageToStore({ ...message_data, ...data, ...result }));
    onSave({ ...message_data, ...data, ...result });
    clearForm();
    closeModal();
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Title">
            <SimpleInput value={title} onInput={setTitle} error={errors.title} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={7}>
        <GridColumn>
          <DetailItem title="Sender Image">
            <div className={styles.senderImageContent}>
              <div className={styles.imageDisplay}>
                <img src={sender_image || profile_icon} />
              </div>
              <input
                id={message_data.id}
                type="file"
                accept="image/*"
                onChange={handleFileSelection}
                onClick={(e) => (e.target.value = null)}
                style={{
                  visibility: 'hidden',
                  width: '2px',
                  height: '2px',
                  display: 'inline-block'
                }}
              />
              {sender_image ? (
                <Icon name="trash" className={styles.red} onClick={handleDeleteImage} />
              ) : (
                <label htmlFor={message_data.id} className={styles.label}>
                  <Icon name="add" className={styles.blue} />
                </label>
              )}
            </div>
          </DetailItem>
        </GridColumn>
        <GridColumn span={3}>
          <DetailItem title="Sender ID">
            <MultiSelect
              value={sender_id}
              isCreatable
              onChange={setSenderId}
              options={sender_id_options}
              error={errors.sender_id}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn span={3}>
          <DetailItem title="Type">
            <MultiSelect
              value={type}
              onChange={setType}
              options={message_types}
              error={errors.type}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Message">
            <TextArea
              value={message}
              onInput={handleMessageChange}
              error={errors.message}
              show_counter
              character_count={message.length}
              character_limit={160}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {type.value === 'campaign' && (
        <GridRow num_of_columns={2}>
          <GridColumn>
            <DetailItem title="Audiences">
              <MultiSelect
                value={audiences}
                onChange={setAudiences}
                options={audience_optionss}
                error={errors.audiences}
                isMulti
              />
            </DetailItem>
          </GridColumn>
          <GridColumn>
            <DetailItem title="Schedule">
              <DateTimePicker
                showTime
                value={schedule}
                onChange={setSchedule}
                error={errors.schedule}
              />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
      <Spacer multiple={4} />
      <GridRow num_of_columns={2}>
        <GridColumn />
        <GridColumn>
          <Button text="Save" disabled={!form_is_valid || loading} onClick={submit} />
        </GridColumn>
      </GridRow>
    </>
  );
};
